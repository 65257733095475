import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import ErrorPage from '../pages/error';

import '../styles/theme.scss';
import LayoutComponent from '../components/Layout';
//import DocumentationLayoutComponent from '../documentation/DocumentationLayout';
import Login from '../pages/login';
import ResetPassword from '../pages/resetPassword';
import Tracking from '../pages/tracking/TrackingPublic';
import ForgotPassword from '../pages/forgotPassword';
// import Register from '../pages/register';
import { logoutUser } from '../actions/user';

const PrivateRoute = ({dispatch, component, ...rest }) => {
    if (!Login.isAuthenticated(localStorage.getItem('id_token'))) {
        dispatch(logoutUser());
        return (<Redirect to="/login"/>)
    } else {
        return ( // eslint-disable-line
            <Route {...rest} render={props => (React.createElement(component, props))}/>
        );
    }
};

class App extends React.PureComponent {
  render() {
    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact render={() => <Redirect to="/app/main"/>}/>
                    <Route path="/app/" exact render={() => <Redirect to="/app/main"/>}/>
                    <PrivateRoute path="/app" dispatch={this.props.dispatch} component={LayoutComponent}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/forgot-password" exact component={ResetPassword}/>
                    <Route path="/tracking" exact component={Tracking}/>
                    <Route path="/reset-password" exact component={ForgotPassword}/>
                    <Route path="/error" exact component={ErrorPage}/>
                </Switch>
            </BrowserRouter>
        </div>

    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
