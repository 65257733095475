import React, { Component } from 'react'
import PropTypes from 'prop-types';
import axios from 'axios'
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Label,
  FormFeedback,
  CardHeader,
} from 'reactstrap'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchUserByID, deleteUser, updateUser, updatePassword } from '../../actions/user'
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken'
import s from './Profile.module.scss'
import Validator from '../../utils/validator'
import { confirmAlert } from 'react-confirm-alert'; // Import
import { USER_ROLE, USER_STATUS } from '../../constants'
import photo from "../../images/avatar/avar1.jpeg";
import Select from "react-select";
class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        displayName: '',
        companyName: '',
        email: '',
        id: '',
        address: '',
        city: '',
        country: '',
        role: '',
        status: '',
        password: '',
        passwordNew: '',
        passwordAgain: '',
        errors: {},
        discount: ''
      },
      logo: '',
      logoUrl: photo,
      currUser: {},
      isShow: {
        password: false,
        passwordNew: false,
        passwordAgain: false,
      },
      listUsers: [],
      salerSelected: [],
      salerSelected2: [],
    }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitPasswordChanged = this.handleSubmitPasswordChanged.bind(this);
    const requiredFields = {
      method: 'isEmpty',
      validWhen: false,
      message: 'The name field is required.'
    }
    const sameField = (value, field, state) => (field.passwordNew === value)
    const userRules = [
      { field: 'displayName', ...requiredFields},
      { field: 'companyName', ...requiredFields},
      { field: 'email', ...requiredFields},
      { field: 'email',  method: 'isEmail', validWhen: true, message: 'The email must be a valid email address.'},
      { field: 'address', ...requiredFields},
      { field: 'city', ...requiredFields},
      { field: 'country', ...requiredFields},
      { field: 'discount', ...requiredFields},
    ]
    const passwordRules = [
      { field: 'password', ...requiredFields},
      { field: 'passwordNew', ...requiredFields},
      { field: 'passwordAgain',  method: sameField, validWhen: true, message: 'The password does not match the new password.'},
      { field: 'passwordAgain', ...requiredFields}
    ]
    this.validator = {
      user: new Validator(userRules),
      password: new Validator(passwordRules)
    }
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  async componentDidMount () {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    let { id } = currUser
    this.setState({ currUser })
    let { userId = id } = this.props.match.params
    const photo = `/kango_logos/${userId}-logo.png`
    const user =  await fetchUserByID(userId)
    this.setState({ user: {...user, errors: {}} })
    this.setState({ logoUrl: photo })
    let salerSelected = user?.saler ? user?.saler.split(",") : []
    if (salerSelected) {
      this.setState({salerSelected2: salerSelected})
      salerSelected = salerSelected.map(i => {
        return {
          email: i
        }
      })
      this.setState({salerSelected: salerSelected})
    }

    this.getListUsers()
  }
  handleUserInput = (e) => {
    const { name, value } = e.target;
    const { user } = this.state;
    user[name] = value;
    console.log(user)
    this.setState({...this.state, user});
  };
  async getListUsers() {
    const { data } = await axios.get(`/all-users`)
    if (data) {
      this.setState({listUsers: data})
      return
    }
  }
  handleChange(e) {
    this.setState({salerSelected: e})
  }

  changePasswordType = (type) => {
    const { isShow } = this.state
    switch (type) {
      case 'password':
        this.setState({isShow: { ...isShow, password: !isShow.password}});
        break
      case 'passwordAgain':
        this.setState({isShow: { ...isShow, passwordAgain: !isShow.passwordAgain}});
        break
      case 'passwordNew':
        this.setState({isShow: { ...isShow, passwordNew: !isShow.passwordNew}});
        break
      default:
        break
    }
  }
  handleSubmit = (e) => {
    const errors = this.validator.user.validate(this.state.user)
    this.setState({ user: {...this.state.user, errors } });

    if (Object.keys(errors).length > 0) return
    const { user, salerSelected } = this.state
    let saler = ''
    if (salerSelected && salerSelected.length) {
      saler = salerSelected.map (i => i.email)
      saler = saler.join(",")
    }

    const data = {...user, saler: saler}

    console.log(data)
    confirmAlert({
      title: 'Xác nhận Cập nhật',
      message: 'Bạn có chắc muốn cập nhật thông tin về Người dùng này?',
      buttons: [
        {
          label: 'Có',
          onClick: () => {
            this.props.dispatch(updateUser(data));
          }
        },
        {label: 'Không',onClick: () => { }}]
    });
  };
  handleSubmitPasswordChanged = (e) => {
    let errors = this.validator.password.validate(this.state.user)
    const errorsFields = Object.keys(errors)
    const { currUser } = this.state;
    this.setState({ user: {...this.state.user, errors } });
    if (Object.keys(errors).length > 0 && !(errorsFields.includes('password') && currUser.role === USER_ROLE.ADMIN)) return
    const { user } = this.state
    confirmAlert({
      title: 'Xác nhận Cập nhật',
      message: 'Bạn có chắc muốn cập nhật mật khẩu người dùng?',
      buttons: [
        {
          label: 'Có',
          onClick: () => {
            this.props.dispatch(updatePassword(user));
          }
        },
        {label: 'Không',onClick: () => { }}]
    });
  };
  onSubmit(e) {
    e.preventDefault()
  }

  onChangeImage(e) {
    this.setState({ logo: e.target.files[0] })
    this.setState({ logoUrl: URL.createObjectURL(e.target.files[0]) })
  }

  onUploadImage(e) {
    try {
      const data = new FormData()
      data.append('file', this.state.logo, this.state.user.id + '-logo.png')

      axios.post('/upload', data).then((res) => {
        this.setState({ logo: res.data })
        toast.success("Success! Tải lên logo thành công");
      })
    } catch (e) {
      console.log(e)
    }
  }


  onDeleteUser = () => {
    confirmAlert({
      title: 'Xác nhận Xoá',
      message: 'Bạn có chắc muốn xoá thông tin về Người dùng này?',
      buttons: [
        {
          label: 'Có',
          onClick: () => {
            this.props.dispatch(deleteUser(this.state.user.id));
            let path = `/app/user`;
            this.props.history.push(path);
          }
        },
        {label: 'Không',onClick: () => { }}]
    });

  };

  render() {
    const {user, currUser, logoUrl, isShow} = this.state;
    const userRole = currUser.role === USER_ROLE.ADMIN ? USER_ROLE : {DOCUMENT: USER_ROLE.DOCUMENT, CUSTOMER: USER_ROLE.CUSTOMER}
    const locationOption = {
      HCM: "HCM",
      HN: 'HN',
      ĐN: 'ĐN'
    }
    const priceArr = {
      F0: 'F0',
      F1: 'F1',
      F2: 'F2',
    }
    return (
      <div className={s.root}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Profile</BreadcrumbItem>
          { currUser.role === USER_ROLE.ADMIN && currUser.id !== user.id && user.role !== USER_ROLE.ADMIN &&
            (<Col className="justify-content-end d-flex">
              <Button size="lg" type="button" color="danger" onClick={this.onDeleteUser}><i className="fa fa-recycle"/> Xoá người dùng</Button>
            </Col>)
          }
        </Breadcrumb>
        <Row>
          <Col sm={12} md={6} className="mb-2">
            <Card style={{height: 'auto'}}>
              <CardHeader tag="h5">Personal Profile</CardHeader>
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="displayName">Display Name</Label>
                    <Input id="displayName" value={user.displayName} name="displayName" onChange={this.handleUserInput} invalid={user.errors && user.errors.displayName} />
                    <FormFeedback>{user.errors.displayName}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="companyName">Company Name</Label>
                    <Input id="companyName" value={user.companyName} name="companyName" onChange={(e) => this.handleUserInput(e)} invalid={user.errors && user.errors.companyName} />
                    <FormFeedback>{user.errors.companyName}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input id="email" value={user.email} name="email" onChange={(e) => this.handleUserInput(e)}  invalid={user.errors && user.errors.email} />
                    <FormFeedback>{user.errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="Address">Address</Label>
                    <Input id="Address" value={user.address} name="address" onChange={(e) => this.handleUserInput(e)}  invalid={user.errors && user.errors.address} />
                    <FormFeedback>{user.errors.address}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="City">City</Label>
                    <Input id="City" value={user.city} name="city" onChange={(e) => this.handleUserInput(e)}  invalid={user.errors && user.errors.city} />
                    <FormFeedback>{user.errors.city}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="Country">Country</Label>
                    <Input id="Country" value={user.country} name="country" onChange={(e) => this.handleUserInput(e)}  invalid={user.errors && user.errors.country} />
                    <FormFeedback>{user.errors.country}</FormFeedback>
                  </FormGroup>
                  { currUser.role === USER_ROLE.ADMIN && (
                    <>
                    <FormGroup>
                      <Label for="userStatus">Status</Label>
                      <Input name="status" id="userStatus" type="select" value={user.status} onChange={e => this.handleUserInput(e)}>
                        {Object.values(USER_STATUS).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                      </Input>
                    </FormGroup>
                    <FormGroup >
                      <Label for="userRole">Vai trò &nbsp; &nbsp;</Label>
                      <Input id="userRole" name="role" type="select" value={user.role} onChange={(e) => this.handleUserInput(e)} >
                        {Object.values(userRole).map((object, i) => <option className="text-uppercase" value={object} key={i}>{object === 'ops' ? 'FWD' : object}</option>)}
                      </Input>
                    </FormGroup>
                      <FormGroup>
                        <Label for="userRole">Đang quản lý Sales sau:</Label>
                        <Select
                            value={this.state.salerSelected || []}
                            isMulti
                            name="colors"
                            options={this.state.listUsers}
                            getOptionLabel ={(option)=>option.email}
                            getOptionValue ={(option)=>option.email}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => this.handleChange(e)}
                        />
                      </FormGroup>
                      <FormGroup >
                      <Label for="location">Khu Vực &nbsp; &nbsp;</Label>
                      <Input id="location" name="location" type="select" value={user.location} onChange={(e) => this.handleUserInput(e)} >
                        {Object.values(locationOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                      </Input>
                    </FormGroup>
                      <FormGroup>
                        <Label for="discount">Bảng giá RW</Label>
                        <Input value={user.discount} id="discount" name="discount" type="select" onChange={e => this.handleUserInput(e)} >
                          {Object.values(priceArr).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                        </Input>
                      </FormGroup>
                    </>
                  )}
                  <Button className="ml-auto d-block" color="primary" outline onClick={this.handleSubmit}>Save</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card style={{height: 'auto'}}>
              <CardHeader tag="h5">Logo</CardHeader>
              <CardBody>
                <Form className="row">
                  <FormGroup className="col-12 col-md-6">
                  <img className={s.logoPreview} src={logoUrl}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src=photo;
                    }}
                   alt="administrator"/>
                  </FormGroup>
                  <FormGroup className="col-12 col-md-6">
                    <Input onChange={e => this.onChangeImage(e)} name="file" type="file" />
                  </FormGroup>
                </Form>
                <Button className="ml-auto d-block" color="primary" outline onClick={e => this.onUploadImage(e)}>Save</Button>
              </CardBody>
            </Card>

            <Card className="mt-2" style={{height: 'auto'}}>
              <CardHeader tag="h5">Thay đổi mật khẩu</CardHeader>
              <CardBody>
                <Form>
                  {(currUser.role !== USER_ROLE.ADMIN) && (
                  <FormGroup className="position-relative">
                    <Label for="password">Password</Label>
                    <Input
                      type={isShow.password ? "text" : "password"}
                      id="password" value={user.password} name="password" onChange={(e) => this.handleUserInput(e)} invalid={user.errors && user.errors.password} />
                      <span className={s.passwordIcon} onClick={() => this.changePasswordType('password')}>
                        <i className={"fa fa-eye" + (isShow.password ? "-slash" : '')} />
                      </span>
                    <FormFeedback>{user.errors.password}</FormFeedback>
                  </FormGroup>
                  )}
                  <FormGroup className="position-relative">
                    <Label for="passwordNew">New Password</Label>
                    <Input
                      type={isShow.passwordNew ? "text" : "password"}
                      id="passwordNew" value={user.passwordNew} name="passwordNew" onChange={(e) => this.handleUserInput(e)} invalid={user.errors && user.errors.passwordNew} />
                      <span className={s.passwordIcon} onClick={() => this.changePasswordType('passwordNew')}>
                        <i className={"fa fa-eye" + (isShow.passwordNew ? "-slash" : '')} />
                      </span>
                    <FormFeedback>{user.errors.passwordNew}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label for="passwordAgain">New Password Again</Label>
                    <Input
                      type={isShow.passwordAgain ? "text" : "password"}
                      id="passwordAgain" value={user.passwordAgain} name="passwordAgain" onChange={(e) => this.handleUserInput(e)} invalid={user.errors && user.errors.passwordAgain} />
                      <span className={s.passwordIcon} onClick={() => this.changePasswordType('passwordAgain')}>
                        <i className={"fa fa-eye" + (isShow.passwordAgain ? "-slash" : '')} />
                      </span>
                    <FormFeedback>{user.errors.passwordAgain}</FormFeedback>
                  </FormGroup>
                  <Button className="ml-auto d-block" color="primary" outline onClick={this.handleSubmitPasswordChanged}>Save</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect()(Profile);
