import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody, CardFooter,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, PaginationItem, PaginationLink,
  Row,
  Table
} from "reactstrap";
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import {USER_ROLE} from "../../constants";
import ImageUploading from 'react-images-uploading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import moment from "moment";

class ManagePricing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      dataOps: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalToggle: false,
      modalEditToggle: false,
      modalToggleUpdatePricing: false,
      modalPricingInfo: {},
      currUser: {},
      fromDate: '',
      toDate: '',
      createPricing: {
        name: "",
        note: "",
      },
      editPricing: {
        name: "",
        note: "",
      },
      isEdit: false,
      loading: false,
      search: '',
      pricingType: "",
      images: [],
      imagesEdit: [],
      searchType: "owner",
      type: 'sale',
    }
    this.openEditModal = this.openEditModal.bind(this)
    this.onEditPricing = this.onEditPricing.bind(this)
    this.onCreatePricing = this.onCreatePricing.bind(this);
    this.onEditPricingChange = this.onEditPricingChange.bind(this);
    this.toggleUpdatePricing = this.toggleUpdatePricing.bind(this);
    this.onCreatePricingChange = this.onCreatePricingChange.bind(this);
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleDatetoSelect2 = this.handleDatetoSelect2.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.setImages = this.setImages.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    console.log(currUser)
    await this.setState({currUser, createPricing: {...this.state.createPricing, owner: currUser.displayName}})
    await this.getAllPricing()
    await this.getAllPricingOps()
  }

  async onCreatePricing(e) {
    try {
      let {createPricing} = this.state
      let fromDate = moment(this.state.fromDate).format('YYYY-MM-DD 00:00:00')
      let toDate = moment(this.state.toDate).format('YYYY-MM-DD 23:59:59')
      await axios.post('/create-money-pricing', {
        ...createPricing,
        fromDate: fromDate,
        toDate: toDate,
        type: this.state.type === 'ops' ? 'ops' : ''
      })
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle}));
      await this.getAllPricing()
      await this.getAllPricingOps()
      e.preventDefault();
    } catch (err) {
      let message = err.response.data.message || 'Đã có lỗi xảy ra!'
      toast.error(message);
    }
  }

  async onEditPricing(e) {
    try {
      const {editPricing} = this.state
      let fromDate = moment(this.state.fromDate).format('YYYY-MM-DD 00:00:00')
      let toDate = moment(this.state.toDate).format('YYYY-MM-DD 23:59:59')
      await axios.post(`/update-money-pricing/${editPricing.id}`, {
        ...editPricing,
        fromDate: fromDate,
        toDate: toDate,
      })
      this.setState((prevState) => ({modalEditToggle: !prevState.modalEditToggle, images: [], imagesEdit: []}));
      await this.getAllPricing()
      e.preventDefault();
    } catch (e) {

    }
  }
  handleDatefromSelect(date) {
    this.setState({fromDate: date});
  }
  handleDatetoSelect2(date) {
    this.setState({toDate: date});
  }


  toggle(type) {
    this.setState((prevState, props) => ({modalToggle: !prevState.modalToggle, type: type}));
  }

  openEditModal(obj) {
    this.setState({editPricing: obj, fromDate: new Date(obj.fromDate), toDate: new Date(obj.toDate)})
    this.setState((prevState, props) => ({modalEditToggle: !prevState.modalEditToggle}));
  }

  toggleUpdatePricing() {

  }

  onCreatePricingChange(e) {
    const {name, value} = e.target;
    let createPricingInfo = this.state.createPricing

    createPricingInfo[name] = value
    this.setState({createPricing: createPricingInfo})
  }

  onEditPricingChange(e) {
    const {name, value} = e.target;
    let editPricingInfo = this.state.editPricing
    editPricingInfo[name] = value
    this.setState({editPricing: editPricingInfo})
  }

  formatNumber(num) {
    return num
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  searchBy(val) {
    this.setState({pricingType: val})
  }
  searchByCode(val) {
    this.setState({searchType: val})
  }

  async getAllPricing(params = {}) {
    try {
      this.setState({loading: true})
      let url = `/get-all-money-pricing?type=sale`
      const res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async getAllPricingOps(params = {}) {
    try {
      this.setState({loading: true})
      let url = `/get-all-money-pricing?type=ops`
      const res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        this.setState({dataOps: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllPricing({page: pageNumber})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER | role === USER_ROLE.MANAGER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.MANAGER || role === USER_ROLE.CUSTOMER) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  handleInput = (e) => {
    const {value} = e.target
    this.setState({search: value})
  }
  onSearch = (e) => {
    this.getAllPricing({search: this.state.search})
  }

  onChange2 = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    this.setImages(imageList);
  };

  setImages(imageList) {
    this.setState({images: imageList})
  }

  onImageEditRemove = (index) => {
    let { imagesEdit } = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }

  showDateTime(obj) {
    if (!obj.fromDate || !obj.toDate) return ''
    return `${moment(obj.fromDate).subtract(7, 'hour').format('DD/MM/YYYY')} - ${moment(obj.toDate).subtract(7, 'hour').format('DD/MM/YYYY')}`
  }


  render() {
    const {data, dataOps, editPricing} = this.state
    const pricingType = {
      DIV: "Khách lẻ",
      ODD: "Khách sĩ",
    }
    const maxNumber = 12;
    return (
      <div>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active>Quản lý bảng giá</BreadcrumbItem>
        </Breadcrumb>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Danh sách bảng giá Cost SALE/Đại Lý</span>
            <Button type="button" className="butt  ml-auto" onClick={() => this.toggle('sale')}>+ Tạo mới</Button>
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Tên bảng giá</th>
                <th>Thời gian áp dụng bảng giá</th>
                <th>Note</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {data.list &&
                data.list.map((obj, i) => (
                  <tr key={i + 1}>
                    <td className={'text-center'}>{i + 1}</td>
                    <td>
                      <Link to={`/app/manage-pricing-detail/${obj.id}`}>{obj.name}</Link>
                    </td>
                    <td>
                      <div>
                        {this.showDateTime(obj)}
                      </div>
                    </td>
                    <td>{obj.note || ''}</td>
                    <td style={{width: '200px'}}>
                      <Button type="button" color="info" style={{marginTop: 0}}
                              onClick={(e) => this.openEditModal(obj)}>Cập nhật thời gian</Button>
                      <div className={'m-2'}></div>
                      <Link to={`/app/manage-pricing-detail/${obj.id}`}>
                        <Button type="button" color="warning" style={{marginTop: 0}}>Chi tiết bảng giá</Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Card style={{marginTop: 40}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Danh sách bảng giá Đại Lý (F0, F1, F2)</span>
            <Button type="button" className="butt  ml-auto" onClick={() => this.toggle('ops')}>+ Tạo mới</Button>
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Tên bảng giá</th>
                <th>Thời gian áp dụng bảng giá</th>
                <th>Note</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {dataOps.list &&
                dataOps.list.map((obj, i) => (
                  <tr key={i + 1}>
                    <td className={'text-center'}>{i + 1}</td>
                    <td>
                      <Link to={`/app/manage-pricing-ops-detail/${obj.id}`}>{obj.name}</Link>
                    </td>
                    <td>
                      <div>
                        {this.showDateTime(obj)}
                      </div>
                    </td>
                    <td>{obj.note || ''}</td>
                    <td style={{width: '200px'}}>
                      <Button type="button" color="info" style={{marginTop: 0}}
                              onClick={(e) => this.openEditModal(obj)}>Cập nhật thời gian</Button>
                      <div className={'m-2'}></div>
                      <Link to={`/app/manage-pricing-ops-detail/${obj.id}`}>
                        <Button type="button" color="warning" style={{marginTop: 0}}>Chi tiết bảng giá</Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <Row>
          {/* Toggle Form Edit Pricing */}
          <Modal isOpen={this.state.modalEditToggle} toggle={this.openEditModal}>
            <Form style={{minHeight: '90vh'}}>
              <ModalHeader toggle={this.openEditModal}>Cập nhật thời gian áp dụng bảng giá</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Tên bảng giá</Label>
                  <Input type="text" name="name" id="name" read-only value={editPricing.name} placeholder="Tên Pricing"
                         onChange={this.onEditPricingChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="fromDate">Từ Ngày</Label>
                  <DatePicker
                    id='fromDate'
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.fromDate}
                    onSelect={this.handleDatefromSelect}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="toDate">Đến ngày</Label>
                  <DatePicker
                    id='toDate'
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.toDate}
                    onSelect={this.handleDatetoSelect2}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="note">Note</Label>
                  <Input type="textarea" name="note" value={editPricing.note} id="note" placeholder="note"
                         onChange={this.onEditPricingChange}/>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditPricing(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>


          <Modal isOpen={this.state.modalToggle} toggle={this.toggle}>
            <Form>
              <ModalHeader toggle={this.toggle}>Thêm bảng giá {this.state.type === 'ops' ? 'Đại lý (F0,F1,F2)' : 'SALE/Đại Lý'}</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Tên bảng giá</Label>
                  <Input type="text" name="name" id="name" placeholder="Tên Pricing"
                         onChange={this.onCreatePricingChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="fromDate">Từ Ngày</Label>
                  <DatePicker
                    id='fromDate'
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.fromDate}
                    onSelect={this.handleDatefromSelect}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="toDate">Đến ngày</Label>
                  <DatePicker
                    id='toDate'
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.toDate}
                    onSelect={this.handleDatetoSelect2}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="note">Note</Label>
                  <Input type="textarea" name="note" id="note" placeholder="note"
                         onChange={this.onCreatePricingChange}/>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onCreatePricing(e)}>Create</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

        </Row>
      </div>
    )
  }
}

export default ManagePricing
