import React, { useState } from "react";
import {Button, Table} from "reactstrap";
import Icon from "../../../components/Icon/Icon";
import s from "./PickupListTable.module.scss";
import moment from 'moment';
function PickupListTable(props) {
  return (
    <div className={s.borderBox}>
      <Table responsive bordered hover className={s.tableHover}>
        <thead className="text-uppercase table-light font-weight-bold text-center">
          <tr>
            <th></th>
            <th>Pickup#</th>
            <th>Pickup Status</th>
            <th>Type</th>
            <th>Address</th>
            <th>DateTime</th>
            <th>GrossWeight</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {props.data?.length ? props.data.map((pickup, index) => (
            <PickupTableRow props={props}  key={index} index={index + 1} pickup={pickup} />
          )):(
            <tr>
              <td colSpan='100' className='text-center'>Danh sách trống.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default PickupListTable;

function PickupTableRow({ pickup, props }) {
  const [isOpen, setIsOpen] = useState(false);
  console.log(props)
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <tr>
        <td scope="row">
          <div onClick={toggle} className={s.pickupIcon}>
            {isOpen ? <Icon glyph="minus" /> : <Icon glyph="plus" />}
          </div>
        </td>
        <td>{pickup.packageId}</td>
        <td>{pickup.status === 'printed' ? <Icon glyph="minus" /> : <Icon glyph="tick" />}</td>
        <td>{pickup.type}</td>
        <td>{pickup.address}</td>
        <td>{formatDate(pickup.datetime)}</td>
        <td>{pickup.grossWeight}</td>
        <td>{
          <div>
            {
              pickup.status === 'printed' && (
                <Button
                  style={{marginRight: '5px'}}
                  onClick={(e) => props.updatePickup(pickup.id)}
                >
                  Finish
                </Button>
              )
            }
            <Button
              onClick={(e) => props.deletePickup(pickup.id)}
            >
              Delete
            </Button>
          </div>
        }</td>
      </tr>
      <tr className={`${s.hide} + ${isOpen ? s.isOpen : ""}`}>
        <td></td>
        <td colspan="6">
          <Table bordered className={s.tableHover}>
            <thead>
              <tr>
                <th>Order#</th>
                <th>Order Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{pickup.packageId}</td>
                <td>{pickup.status}</td>
              </tr>
            </tbody>
          </Table>
        </td>
      </tr>
    </>
  );
}

function formatDate(date) {
  return moment(parseInt(date)).format()
}
