import React, { Component } from 'react'
import {
  Row,
  Col,
  InputGroup,
  Input,
  Button,
  Form,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import logo from '../../images/hopdong/logo-kango-1.jpg';
import s from './Tracking.module.scss';
import axios from "axios";

class Tracking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      TrackingNumber: '',
    }
  }

  async doTrack() {
    var num, TRNum, src, iframe, number
    number = this.state.TrackingNumber
        TRNum = document.getElementById('TRNum')
    iframe = document.createElement('iframe')
    num = number
    num = num.replace(/\s+/g, '')
    if (num === '' || !/^[A-Za-z0-9-]{4,}$/.test(num)) {
      return false
    }
    document.getElementById("logo").style.display = "block";
    document.getElementById("info").style.display = "block";
    const logisCode = await this.getLogisticCode(num)
    if (!logisCode) return
    TRNum.innerHTML =
      '<a class="trFrameClose" onmouseover="this.style.backgroundColor=\'#BDBDBD\'" onmouseout="this.style.backgroundColor=\'#e3e3e3\'" style="position: absolute; right: 11px; top: 22px; width: 28px; height: 28px; line-height: 28px; background: rgb(227, 227, 227) none repeat scroll 0% 0%; color: rgb(33, 33, 33); text-align: center; font-family: Arial,Helvetica,sans-senif; z-index: 100; cursor: pointer; font-size: 20px; text-decoration: none; font-weight: 700;" onclick="TRNum.innerHTML=\'\'; document.getElementById(\'logo\').style.display = \'none\'; document.getElementById(\'info\').style.display = \'none\';">X</a>'
    src = '//ksnpost.vn/?tracknumber=' + logisCode
    iframe.src = src
    iframe.style.width = '100%'
    iframe.style.height = '800px'
    iframe.style.marginTop = '12px'
    iframe.style.border = '1px solid #ddd'
    iframe.style.borderRadius = '6px'
    iframe.scrolling = 'no'
    TRNum.style.position = 'relative'
    TRNum.style.minWidth = '220px'
    TRNum.style.maxWidth = '600px'
    TRNum.style.margin = 'auto'
    TRNum.style.zIndex = '2'
    TRNum.appendChild(iframe)
    return false
  }

  async componentDidMount () {
    const code = new URLSearchParams(this.props.location.search).get("code")
    /*if (code) {
      await this.setState({ TrackingNumber: code })
      this.doTrack()
    }*/
    if (code) {
      window.location.assign(`https://tracking.gopost.vn/?tpCode=${code}`);
    } else {
      window.open(`https://tracking.gopost.vn/`, '_blank');
    }
    this.props.history.push('/')

  }

  async getLogisticCode(code) {
    if (code) {
      const currentPackage = await axios.get(`/package-by-code/${code}`)
      if (currentPackage && currentPackage.status === 200
        && currentPackage.data && currentPackage.data.logisticCode) {
        return currentPackage.data.logisticCode
      }
    }
    return null
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Tracking</BreadcrumbItem>
        </Breadcrumb>
        <div style={{textAlign: 'center'}}>
          <div className={s.formWrap}>
            <Form onSubmit={(e) => false}
                  className={s.trackingForm}
            >
              <InputGroup>
                <Input
                  id="TrackingNumber"
                  name="TrackingNumber"
                  type="text"
                  placeholder="Enter your tracking number..."
                  value={this.state.TrackingNumber}
                  className={s.trackingInput}
                  onChange={(e) =>
                    this.setState({ TrackingNumber: e.target.value })
                  }
                />
                <Button onClick={(e) => this.doTrack()}>
                  <i className="fa fa-search"></i>
                  <span style={{marginLeft: '5px'}}>Tracking</span>
                </Button>
              </InputGroup>
              <input type="hidden" name="lang" value="en" />
            </Form>
          </div>
          <Row>
            <Col sm={12} style={{backgroundColor: '#ffffff'}}>
              <div id="TRNum">
              </div>
              <div className={s.loginWrap}>
                <img id="logo" src={logo} alt="LOGO" className={s.loginLogo}/>
              </div>
              <div className={s.loginWrap} style={{width: '250px', top: '80px', display: 'none', zIndex: '0'}} id="info">
                <p><h3><strong>KANGO EXPRESS</strong></h3></p>
                <p>Gặp bộ phận chăm sóc khách hàng của Công Ty chúng tôi để được hỗ trợ</p>
                <p>Tổng Đài: 1900 9475</p>
                <p>KANGO EXPRESS</p>
                <p>TRAO UY TÍN - NHẬN NIỀM TIN</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default Tracking
