import { USER_ROLE, USER_STATUS } from '../constants'

const getClassColorByStatus = (status) => {
    switch (true) {
        case status === USER_STATUS.ACTIVE || status === USER_ROLE.ACCOUNTANT :
            return 'bg-primary'
        case status === USER_STATUS.INACTIVE || status === USER_ROLE.DOCUMENT :
            return 'bg-secondary'
        case status === USER_STATUS.WAITING_FOR_APPOVE || status === USER_ROLE.ADMIN :
            return 'bg-success'
        case status === USER_ROLE.CUSTOMER :
            return 'bg-danger'
        default :
        return 'bg-warning' 
    }
}

export { getClassColorByStatus }
