import logo from '../../images/hopdong/logo-tp.png';
import barcode from '../../images/hopdong/barcode.png';
import React from 'react';
import { fetchUserByID } from '../../actions/user'
import { USER_ROLE, DOC_TYPE, PACK_TYPE } from '../../constants';
import s from './Packet.module.scss';
import {Table, Row, Col, Container} from 'reactstrap';
import { Textfit } from 'react-textfit';
import Barcode from 'react-jsbarcode'
import moment from "moment/moment";
import axios from "axios";

class PrintPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLogo: false,
      billCreator: {},
      logoUrl: logo
    }
  }

  async componentDidMount(){
    this.setState({billCreator: await fetchUserByID(this.props.billData.companyUserID)})
    try {
      const {data: user} = await axios.get(`/user-by-name/${this.props.billData.owner}`)
      console.log(user)
      this.setState({billupCreator: user})
      if (user?.role === 'ops') {
        this.setState({logoUrl: "/kango_logos/" + user.id + "-logo.png"})
      }
    } catch (error) {
      console.log(error)
    }
  }

  formatDate = (str) => {
    return moment(str).format('DD/MM/YYYY')
  }

  render() {
    let totalPacks = 0
    if (this.props.billData.packageType === PACK_TYPE) {
      var packs = JSON.parse(this.props.billData.packs)
      totalPacks = packs.reduce(function (a, b) {
        return a + parseInt(b['quantity'])
      }, 0)
    } else {
      totalPacks = this.props.billData.docCount
    }

    let barcodeValue = this.props.billData.referenceCode ? this.props.billData.referenceCode : this.props.billData.kgCode
    let barcodeArr = []
    console.log('this.props.billData ', this.props.billData)
    if (this.props.billData.hawbCode) {
      barcodeValue = this.props.billData.hawbCode
      barcodeValue = barcodeValue ? barcodeValue.replace(/\s/g, "") : '';

      // check barcodeValue has , and split to array
        if (barcodeValue.includes(',')) {
          barcodeArr = barcodeValue.split(',')
        }
    }
    barcodeValue = barcodeValue.replace(/\s/g, "");


    const shippingMark = (idx, total) => {
      return <div style={{padding:"1.2%", pageBreakAfter: (idx % 2 ===0) ? 'always' : 'none'}}>
      <Row>
        <Col xs="4" className="border d-flex align-items-center justify-content-center">
          <img alt="LOGO" src={this.state.logoUrl} style={{display:"block", "object-fit": "contain", maxHeight: "96px"}} className="img-fluid"/>
        </Col>
        <Col xs="4" className="border text-center">
          {
            barcodeValue ? (
                  <div className="border-bottom">
                    <Row className="flex-row justify-content-center text-center">
                      <Barcode renderer="image" style={{height: '90px', width: '90%'}} options={{displayValue: false}}  value={barcodeArr[idx-1] ? barcodeArr[idx-1]: barcodeValue}/>
                    </Row>
                    <h6 style={{margin: 0}}>HAWB#: {barcodeArr[idx-1] ? barcodeArr[idx-1]: barcodeValue}</h6>
                  </div>

            ) : ''
          }
          <Row className="d-flex justify-content-center text-center">
            <h3 style={{fontSize: '22px'}}><div>AWB#: {this.props.billData.referenceCode}</div> </h3>
          </Row>
        </Col>
        <Col xs="4" className="border text-center d-flex align-items-center justify-content-center">
          <div>
            <h4 className="text-danger"> <strong>Date: {this.formatDate(this.props.billData?.createdAt)}</strong></h4>
            <h4>Service: <strong>{this.props.billData.logisticService}</strong></h4>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="border text-center">
          <h1 style={{marginBottom: '-10px'}}><strong>{this.props.billData.kgCode}</strong></h1>
        </Col>
      </Row>

      <Row >
        <Col className="border text-center table-info"> <h2><strong>Origin</strong></h2></Col>
        <Col className="border text-center table-info"><h2><strong>Destination</strong></h2></Col>
      </Row>

      <Row>
        <Col className="border text-center"><h2><strong>Vietnam</strong></h2></Col>
        <Col className="border text-center">
          <h2><strong>{this.props.billData.receiverCountry}</strong></h2>
        </Col>
      </Row>

      <Row className='border'>
        <Col>
        <br/>
        <h3>
        <strong>1. From (Shipper)</strong> <br/>
        <strong>Company Name:</strong> {this.props.billData.senderCompanyName} <br/>
        <strong>Contact Name:</strong> &ensp;&nbsp;{this.props.billData.senderName} <br/>
        <strong>Phone/Fax No:</strong> &ensp;&nbsp;{this.props.billData.senderPhone}
        <br/>
        <br/>
        <strong>2. To (Receiver)</strong> <br/>


          <strong>Company Name:</strong> {this.props.billData.receiverCompanyName} <br/>
        <strong>Contact Name:</strong> &ensp;&nbsp;{this.props.billData.receiverName} <br/>
        <strong>Phone/Fax No:</strong> &ensp;&nbsp;{this.props.billData.receiverPhone} <br/>
        <strong>Address: </strong> &ensp;&nbsp;{[this.props.billData.receiverAddr1|| "", this.props.billData.receiverAddr2||null, this.props.billData.receiverAddr3||null, this.props.billData.receiverPostalCode||null].filter(function(el){return el!=null}).join()} <br/>

        </h3>
        </Col>
      </Row>

      <Row>
        <Col className='border text-center'> <h1><strong>{idx}/{total}</strong></h1> </Col>
      </Row>
      <br/>
    </div>
    }

    return (
      <div>
        {[...Array(totalPacks)].map((pkg, i) => shippingMark(i+1, totalPacks) )}
      </div>
    );
  }
}

export default PrintPackages;
