import React, { Component } from 'react'
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import jwt from 'jsonwebtoken'

import {
  PACKAGE_STATUS,
  USER_STATUS,
  PAGINATION_DEFAULT,
} from '../../constants'
import { fetchUsers } from '../../actions/user'
import { getClassColorByStatus } from '../../utils'

class Review extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {
        users: {
          ...PAGINATION_DEFAULT,
          status: USER_STATUS.WAITING_FOR_APPOVE,
        },
        packages: {
          ...PAGINATION_DEFAULT,
          status: PACKAGE_STATUS.WAITING_FOR_APPOVE,
        },
      },
    }
  }
  static propTypes = {
    users: PropTypes.any,
    totalUsers: PropTypes.number,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    users: [],
    totalUsers: 0,
    isFetching: false,
  }

  componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    this.setState({ currUser: currUser })
    this.props.dispatch(fetchUsers(this.state.params.users))
  }
  routeChange = (user) => {
    let path = `/app/users/${user.id}`
    this.props.history.push(path)
    return
  }
  onSearchUser(e, page) {
    const params = this.state.params.users
    params.page = page ? page : this.state.params.users.page
    this.props.dispatch(fetchUsers(params))
    e.preventDefault()
  }

  render() {
    let totalPageUsers = parseInt(
      this.props.totalUsers / this.state.params.users.limit,
    )
    if (this.props.totalUsers % this.state.params.users.limit > 0) {
      totalPageUsers++
    }

    let currentPageUsers = this.state.params.users.page
    let pageLimitUsers = 10
    let startUsers = 0
    let endUsers = pageLimitUsers
    if (totalPageUsers <= pageLimitUsers) {
      pageLimitUsers = totalPageUsers
    }
    if (currentPageUsers - 5 >= 0) {
      startUsers = currentPageUsers - 4
    }
    if (startUsers + pageLimitUsers >= totalPageUsers) {
      startUsers = totalPageUsers - pageLimitUsers
    }
    if (currentPageUsers + 5 >= pageLimitUsers) {
      endUsers = currentPageUsers + 6
      pageLimitUsers = endUsers
      if (totalPageUsers <= pageLimitUsers) {
        pageLimitUsers = totalPageUsers
      }
    }


    return (
      <div>
        <Row>
          {/* <Col sm={12} className="mb-2">
            <Card>
              <CardHeader tag="h5">Hoá đơn chờ duyệt</CardHeader>
              <CardBody>users</CardBody>
            </Card>
          </Col> */}
          <Col sm={12} className="mb-2">
            <Card className="bg-transparent">
              <CardHeader tag="h5">Người dùng chờ duyệt</CardHeader>
              <CardBody className="p-0">
                <Table responsive bordered hover className="mb-0 table-hover">
                  <thead>
                    <tr>
                      <th>NO.</th>
                      <th>Tên Người dùng</th>
                      <th>Tên Công ty</th>
                      <th>Email</th>
                      <th>Địa chỉ</th>
                      <th>Vai trò</th>
                      <th>Trạng thái</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.users?.length?
                      this.props.users.map((user, i) => (
                        <tr key={i + 1}>
                          <td>{i + 1}</td>
                          <td>{user.displayName}</td>
                          <td>{user.companyName}</td>
                          <td>{user.email}</td>
                          <td>{user.address}</td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <span
                              className={
                                'px-3 py-2 text-white text-uppercase ' +
                                getClassColorByStatus(user.role)
                              }
                            >
                              {user.role}
                            </span>
                          </td>
                          <td>
                            <Button
                              onClick={() => this.routeChange(user)}
                              color="info"
                              outline
                            >
                              Xem chi tiết
                            </Button>
                          </td>
                        </tr>
                      )): (
                        <tr>
                          <td colSpan='100' className='text-center'>Danh sách trống.</td>
                        </tr>
                      )}
                    {this.props.isFetching && (
                      <tr>
                        <td colSpan="100">Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
              { totalPageUsers > 2 && (
                <CardFooter>
                  <Pagination aria-label="Page navigation example">
                    {[...Array(pageLimitUsers)].map((_, i) => {
                      if (i >= startUsers && i < endUsers) {
                        return (
                          <PaginationItem active={i === currentPageUsers - 1} key={i}>
                            {' '}
                            <PaginationLink
                              onClick={(e) => this.onSearchUser(e, i + 1)}
                            >
                              {' '}
                              {i + 1}{' '}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                    })}
                  </Pagination>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.users.isCreating,
    users: state.users.users.rows,
    totalUsers: state.users.users.count,
  }
}
export default connect(mapStateToProps)(Review)
