import {
  FETCH_PACKAGES_REQUEST,FETCH_PACKAGES_SUCCESS,FETCH_PACKAGES_FAILURE, 
  CREATE_PACKAGE_REQUEST, CREATE_PACKAGE_SUCCESS, CREATE_PACKAGE_FAILURE,
  UPDATE_PACKAGE_REQUEST, UPDATE_PACKAGE_SUCCESS, UPDATE_PACKAGE_FAILURE,
  DELETE_PACKAGE_REQUEST, DELETE_PACKAGE_SUCCESS, DELETE_PACKAGE_FAILURE,
} from '../actions/package';

export default function packages(
  state = {
    isCreating: false,
    packages: {}
  },
  action,
) {
  switch (action.type) {

    /**** FETCH PACKAGES ****/
    case FETCH_PACKAGES_REQUEST:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        message: "",
      });
    case FETCH_PACKAGES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        packages: action.packages,
      });
    case FETCH_PACKAGES_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        message: action.message || 'get packages failed',
      });

      /**** CREATE PACKAGE ****/
    case CREATE_PACKAGE_REQUEST:
      return Object.assign({}, state, {
        type: CREATE_PACKAGE_REQUEST,
        isCreating: action.isCreating,
        message: "",
      });
    case CREATE_PACKAGE_SUCCESS:
      return {
        ...state,
        type: CREATE_PACKAGE_SUCCESS,
        // packages: {[...state.packages, action.package]},
        package: action.package,
        isCreating: action.isCreating,
      }
    case CREATE_PACKAGE_FAILURE:
      return Object.assign({}, state, {
        type: CREATE_PACKAGE_FAILURE,
        isCreating: action.isCreating,
        message: action.message || 'create package failed',
      });

      /**** UPDATE PACKAGE ****/
    case UPDATE_PACKAGE_REQUEST:
      return Object.assign({}, state, {
        type: UPDATE_PACKAGE_REQUEST,
        isFetching: action.isFetching,
        message: "",
      });
      
    case UPDATE_PACKAGE_SUCCESS:
      let updateList = state.packages;
      for (var i = 0; i < updateList.rows.length; i++) {
        if (updateList.rows[i].id === action.package.id) {
          updateList.rows[i] = action.package
        }
      }

      return {
        ...state,
        type: UPDATE_PACKAGE_SUCCESS,
        packages: updateList,
        isFetching: action.isFetching,
      }

    case UPDATE_PACKAGE_FAILURE:
      return Object.assign({}, state, {
        type: UPDATE_PACKAGE_FAILURE,
        isFetching: action.isFetching,
        message: action.message || 'update package failed',
      });

      /******* Delete Package */
    case DELETE_PACKAGE_REQUEST:
      return Object.assign({}, state, {
        isCreating: action.isCreating,
        message: null,
      });
    case DELETE_PACKAGE_SUCCESS:
      let packageList = state.packages;
      for (i = 0; i < packageList.rows.length; i++) {
        if (packageList.rows[i].id === action.id.id) {
          packageList.rows.splice(i, 1);
          packageList.count = packageList.count - 1 
          break
        }
      }
      
      return {
        ...state,
        isCreating: action.isCreating,
        packages: packageList,
      }
    case DELETE_PACKAGE_FAILURE:
      return Object.assign({}, state, {
        isCreating: action.isCreating,
        message: action.message || 'delete package failed',
      });
    default:
      return state;
  }
}
