import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  FormGroup,
  Input, Table,
  Row, Col, Modal, Form, ModalHeader, ModalBody, Label, ModalFooter
} from "reactstrap";
import jwt from "jsonwebtoken";
import {USER_ROLE} from "../../constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
const fileReader = new FileReader();
class EditManagePricingDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      modalToggle: false,
      modalEditToggle: false,
      modalToggleUpdatePricing: false,
      modalPricingInfo: {},
      currUser: {},
      fromDate: '',
      toDate: '',
      createPricing: {
        name: "",
        note: "",
      },

      isEdit: false,
      loading: false,
      search: '',
      pricingType: "",
      images: [],
      imagesEdit: [],
      searchType: "owner",
      moneyPricingDetail: {},
      keySelected: 'Sale',
      servicePricingItem: {},
      services: [],
      editPricing: {
        serviceName: this.props.match.params?.serviceName,
        priceType: this.props.match.params?.priceType,
        moneyPricingId: this.props.match.params?.moneyPricingId,
        editData: {},
        sortedArr: [],
        parsedString: '',
        type: 'sale'
      }
    }
    this.onCreatePricing = this.onCreatePricing.bind(this);
    this.onCreatePricingChange = this.onCreatePricingChange.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.onUpdateServicePricingJSON = this.onUpdateServicePricingJSON.bind(this);
    this.onUpdateServicePricing = this.onUpdateServicePricing.bind(this);
    this.getJSONData = this.getJSONData.bind(this);
    this.parsePriceData = this.parsePriceData.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({currUser, createPricing: {...this.state.createPricing, owner: currUser.displayName}})
    // await this.getAllService()
    await this.getAllServicePricing()
    await this.parsePriceData()
  }

  async getAllServicePricing(params = {}) {
    try {
      let { moneyPricingId } = this.props.match.params;
      this.setState({loading: true})
      let url = `/get-all-service-pricing?moneyPricingId=${moneyPricingId}`
      const res = await axios.get(url)
      if (res && res.data) {
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async onCreatePricing(e) {
    try {
      let {createPricing} = this.state
      await axios.post('/create-money-pricing', {
        ...createPricing,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      })
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle}));
      await this.getAllPricing()
      e.preventDefault();
    } catch (err) {
      let message = err.response.data.message || 'Đã có lỗi xảy ra!'
      toast.error(message);
    }
  }

  async onUpdateServicePricing(e) {
    try {
      let { editPricing } = this.state

      // parse editPricing.parsedString to JSON
      let parsedString = editPricing.parsedString
      let obj = {}
      let arr = parsedString.split('\n')
      arr.forEach(item => {
        let temp = item?.replace(/\s+/g, ' ').split(' ')
        obj[temp[0]] = temp[1]?.toString()?.replace(/\./g, "");
      })
      // id, priceType, serviceName
      await axios.post(`/update-service-pricing-by-condition/`, {
        moneyPricingId: editPricing. moneyPricingId,
        serviceName: editPricing.serviceName,
        priceType: editPricing.priceType.toUpperCase(),
        priceJSON: JSON.stringify(obj)
      })

      toast.success('Cập nhật thành công!')
      await this.getAllServicePricing()
      await this.parsePriceData()

      // setTimeout and redirect to detail page
      setTimeout(() => {
        let url = `/app/manage-pricing-detail`
        if (this.state.type === 'ops') {
          url = `/app/manage-pricing-ops-detail`
        }
        window.location.href = `${url}/${editPricing.moneyPricingId}?role=${editPricing.priceType.toLowerCase()}`

      }, 1000);
    } catch (err) {
      console.error(err)
      let message = err.response?.data?.message || 'Đã có lỗi xảy ra!'
      toast.error(message);
    }
  }

  onCreatePricingChange(e) {
    const {name, value} = e.target;
    let createPricingInfo = this.state.createPricing

    createPricingInfo[name] = value
    this.setState({createPricing: createPricingInfo})
  }

  onEditPricingChange(e) {
    const {name, value} = e.target;
    let editPricingInfo = this.state.editPricing
    editPricingInfo[name] = value
    this.setState({editPricing: editPricingInfo})
  }

  formatNumber(num) {
    if (num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    return 0
  }

  formatKey(key) {
    return parseFloat(key).toFixed(1)
  }


  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  searchBy(val) {
    this.setState({pricingType: val})
  }
  searchByCode(val) {
    this.setState({searchType: val})
  }

  async getAllPricing(params = {}) {
    try {
      this.setState({loading: true})
      let url = `/get-all-money-pricing?page=${params.page || 1}`
      const res = await axios.get(url)
      if (res && res.data) {
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllPricing({page: pageNumber})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER | role === USER_ROLE.MANAGER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.MANAGER || role === USER_ROLE.CUSTOMER) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  parsePriceData() {
    const { editPricing, data } = this.state
    const arr = data?.filter(item => item.priceType?.toLowerCase() === editPricing.priceType.toLowerCase())?.[0]
    let editData = arr?.arr?.filter(item2 => item2.serviceName === editPricing.serviceName)?.[0] || []
    let sortedArr = []
    let parsedString = ''
    if (editData?.priceJSON) {
      let obj = JSON.parse(editData.priceJSON)
      const sortedKeys = Object.keys(obj).sort((a, b) => parseFloat(a) - parseFloat(b));
      sortedKeys.forEach(key => {
        sortedArr.push({key, value: obj[key]})
        parsedString += `${key} ${obj[key]}\n`;
      });
    }
    this.setState({
      editPricing: {...editPricing, editData, sortedArr, parsedString},
      type: editData?.MoneyPricing?.type
    })
  }

  parsePriceData2() {
    const { editPricing } = this.state
    // parse editPricing.parsedString to JSON and update to editPricing.sortedArr
    let parsedString = editPricing.parsedString
    let obj = {}
    let arr = parsedString.split('\n')
    arr.forEach(item => {
      let temp = item?.replace(/\s+/g, ' ').split(' ')
      obj[temp[0]] = temp[1]?.toString()?.replace(/\./g, "");
    })
    let sortedArr = []
    const sortedKeys = Object.keys(obj).sort((a, b) => parseFloat(a) - parseFloat(b));
    sortedKeys.forEach(key => {
      sortedArr.push({key, value: obj[key]})
    })
    this.setState({
      editPricing: {...editPricing, sortedArr}
    })
  }


  getJSONData(item) {

  }

  onUpdateServicePricingJSON(e) {
    const {name, value} = e.target;
    const {editPricing} = this.state
    this.setState({editPricing: {...editPricing, parsedString: value}})

    // setTimeout and clear ưhen user stop typing
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.parsePriceData2()
    }, 1000);
  }

  render() {
    const { editPricing } = this.state
    return (
      <div>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/app/manage-pricing-detail/${this.props.match.params?.moneyPricingId}`}>Bảng giá</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Cập nhật giá cho dịch vụ</BreadcrumbItem>
        </Breadcrumb>
        <div className="card card-primary">
          <div className="card-header">Cập nhật giá cho dịch vụ: {editPricing.serviceName}  |  Giá {editPricing.priceType} </div>
          <div className="pt-2">
            <Row>
              <Col md="6">
                <Form className="p-3">
                    <FormGroup>
                      <Label for="note">Dán mã vào bên dưới</Label>
                      <Input type="textarea" style={{minHeight: '350px'}} name="note" rows={10} value={editPricing.parsedString} id="note" placeholder="0.5 900.000"
                             onChange={this.onUpdateServicePricingJSON}/>
                    </FormGroup>
                    <div>
                      <p>Chú ý: Cập nhật mã với định dạng <strong>[Số Kg] [Giá tiền]</strong></p>
                    </div>
                  <div>
                    <Button color="primary" onClick={(e) => this.onUpdateServicePricing(e)}>Cập nhật</Button>{' '}
                  </div>
                </Form>
              </Col>
              <Col md="6">
                <div className="card-header text-center">{editPricing.serviceName} | Giá {editPricing.priceType}</div>
                <Table bordered>
                  <thead>
                  <tr>
                    <th className={'text-center'}>Số Kg</th>
                    <th className={'text-center'}>Giá tiền
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    editPricing.sortedArr ? editPricing.sortedArr.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className={'text-center'}>Giá {this.formatKey(item.key)} kg</td>
                          <td className={'text-center'}>{this.formatNumber(item.value)}</td>
                        </tr>
                      )
                    }) : <tr><td colSpan={2} className={'text-center'}>Chưa có dữ liệu</td></tr>
                  }
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

export default EditManagePricingDetail
