import React from 'react'
import axios from 'axios'
import {Button, Form, FormGroup, Input} from "reactstrap";
import Alert from '@mui/material/Alert';
import s from './Wiseway.module.scss';
import jwt from "jsonwebtoken";

const fileReader = new FileReader();
const csv = require('csvtojson')

class LabelUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: '',
      data: [],
      logo: null,
      listLabel: [],
      loading: false,
      errorMes: '',
      currUser: {}
    }
    this.onUploadImage = this.onUploadImage.bind(this)
  }

  componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    this.setState({ currUser })
  }

  onChangeImage(e) {
    this.setState({logo: e.target.files[0]})
  }

  CSVstring_to_Array(data, delimiter = ',') {
    const titles = data.slice(0, data
      .indexOf('\n')).split(delimiter);
    const titleValues = data.slice(data
      .indexOf('\n') + 1).split('\n');
    const ansArray = titleValues.map(function (v) {
      const values = v.split(delimiter);
      const storeKeyValue = titles.reduce(
        function (obj, title, index) {
          obj[title] = values[index];
          return obj;
        }, {});

      return storeKeyValue;
    });

    return ansArray;
  };

  async onUploadImage(e) {
    const arr = []
    const self = this
    if (this.state.logo) {
      this.setState({loading: true, errorMes: ''})
      fileReader.readAsText(this.state.logo);
      fileReader.onload = async function (event) {
        const csvOutput = event.target.result;
        let arr = self.CSVstring_to_Array(csvOutput)
        const data = arr.map(i => {
          return {
            "CustomerOrderNumber": i.CustomerOderNumber,
            "ShippingMethodCode": "ECOWE",
            "PackageCount": parseInt(i.PackageCount),
            "Weight": parseFloat(i.Weight),
            "WeightUnits": "lbs",
            "LabelType": "PDF",
            "Receiver": {
              "CountryCode": "US",
              "FirstName": i.FirstName,
              "LastName": "",
              "Company": "",
              "Street": i.StreetAddress1,
              "City": i.City,
              "State": i.State,
              "Zip": i.Zip,
              "Phone": i.Phone || '',
              "Email": i.Email || ''
            },
            "Sender": {
              "CountryCode": i.CountryCodeSender || 'US',
              "FirstName": i.FirstNameSender || '',
              "LastName": "",
              "Company": i.CompanySender,
              "Street": i.AddressSender,
              "City": i.CitySender,
              "State": i.StateSender,
              "Zip": i.ZipSender,
              "Phone": i.PhoneSender,
              "Email": i.EmailSender
            },
            "Parcels": [
              {
                "EName": i.EName,
                "HSCode": i.HSCode || null,
                "Quantity": 2,
                "SKU": i.SKU || '',
                "UnitPrice": parseFloat(i.UnitPrice),
                "UnitWeight": parseFloat(i.UnitWeight),
                "CurrencyCode": i.CurrencyCode || 'USD'
              }
            ]
          }
        }).filter(a => a.CustomerOrderNumber)

        const res = await axios.post('/create-label-us', data)
        if (res && res.status === 200) {
          try {
            let arr = []
            let messText = res.data.Message + '\n'
            if (res.data && res.data.Item) {
              try {
                let uspsArr = []
                res.data.Item.forEach((i, index) => {
                  let name = i.OrderInfos ? i.OrderInfos[0]['CustomerOrderNumber'] : ''
                  arr.push({
                    url: i.Url,
                    name: name,
                    kgBill: data[index].CustomerOrderNumber,
                  })
                  const obj = data[index]
                  uspsArr.push({
                    packageId: obj.CustomerOrderNumber,
                    customerOrderNumber: arr[index].name,
                    weight: obj.Weight,
                    unit: obj.WeightUnits,
                    state: obj.Receiver.State,
                    postcode: obj.Receiver.Zip,
                    url: arr[index].url,
                    json_data: JSON.stringify({
                      ...obj,
                      ...arr[index]
                    }),
                    userId: self.state.currUser.id
                  })
                  let tmp = i.Remark || ''
                  messText += tmp + ','
                })
                if (res.data.Code == '0000' && res.data.Item && res.data.Item.length) {

                  await axios.post('/create-usps', uspsArr)
                }
              } catch (e) {
                console.error(e)
              }
            }

            self.setState({listLabel: arr, errorMes: messText})

          } catch (e) {
            console.log(e)
            self.setState({errorMes: e.message})
          }
        }
        self.setState({loading: false})
      };
    }


  }

  savePdf(labelContent) {
    const linkSource = `data:application/pdf;base64,${labelContent}`;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  render() {
    return (
      <>
        <div style={{textAlign: 'center'}}>
          <div className={s.formWrap}>
            <Form>
              <h3>Upload .csv file</h3> <em><a href="https://kangoexp.com/label-us-filemau.csv" download>File CSV
              Mẫu </a></em>
              <div>
                <FormGroup>
                  <Input onChange={e => this.onChangeImage(e)} name="file" type="file"/>
                </FormGroup>
                <Button color="primary" outline onClick={e => this.onUploadImage(e)} disabled={this.state.loading}>
                  {!this.state.loading ? 'Create Label' : 'Loading...'}
                </Button>
                {
                  this.state.errorMes && (
                    <Alert style={{marginTop: 50}} severity="info">{this.state.errorMes}</Alert>
                  )
                }
              </div>
            </Form>
          </div>
          <div className={s.list}>
            {
              this.state.listLabel && this.state.listLabel.length ? (
                <div>
                  <h3>US label PDF list: </h3>
                  <ul>
                    {this.state.listLabel.map(function (item, i) {
                      return <li><a href={item.url} target="_blank" rel="noopener noreferrer"> {item.kgBill} </a></li>;
                    })}
                  </ul>
                </div>
              ) : null
            }
          </div>
        </div>
      </>
    )
  }
}

export default LabelUs
