import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
  Table
} from "reactstrap";
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import PrintMawb from "./PrintMawb";
import ReactToPrint from "react-to-print";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select'
import {PACKAGE_ACTION, USER_ROLE} from "../../constants";
import COUNTRIES from './country.json'
import {Link} from 'react-router-dom'
import {scanPackage} from "../../actions/barcode";
import Rounding from "../../utils/rounding";
import fileDownload from "js-file-download";

let timerId;

class MawbExportDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalPrintPackagesToggle: false,
      modalToggle: false,
      modalEditToggle: false,
      modalToggleUpdateMawb: false,
      modalMawbInfo: {},
      currUser: {},
      listCustomer: [],
      selectedCustomer: {
        id: '',
        name: '',
        khCode: '',
        phone: '',
        address: ''
      },
      createMawb: {
        note: "",
        branch: "HCM"
      },
      editMawbPackage: {
        logistics: "AUSPOST",
        service: "VN - AU",
        citycode: 'SYD'
      },
      isEdit: false,
      printId: '',
      printData: '',
      loading: false,
      date: new Date(),
      search: '',
      qStatus: '',
      searchBy: 'mawbId',
      location: '',
      locationOption: {
        HCM: "HCM",
        HN: 'HN',
        ĐN: 'ĐN'
      },
      locationOption2: {
        HCM: "HCM",
        HN: 'HN',
        ĐN: 'ĐN'
      },
      templatePrint: [
        'Parcel',
        'Clearance',
        'CRRIC',
      ],
      mawbCode: this.props.match.params?.mawbCode || null,
      items: [],
      mawbInfo: {},
    }
    this.onDeleteMawb = this.onDeleteMawb.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
    this.oneditMawbPackage = this.oneditMawbPackage.bind(this)
    this.onCreateMawb = this.onCreateMawb.bind(this);
    this.oneditMawbPackageChange = this.oneditMawbPackageChange.bind(this);
    this.toggleUpdateMawb = this.toggleUpdateMawb.bind(this);
    this.onCreateMawbChange = this.onCreateMawbChange.bind(this);
    this.onSelectedCustomer = this.onSelectedCustomer.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSelectedEditCustomer = this.onSelectedEditCustomer.bind(this);
    this.onClickPrintMawb = this.onClickPrintMawb.bind(this);
    this.printPackagesToggle = this.printPackagesToggle.bind(this);
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggle = this.toggle.bind(this);
    this.searchStatus = this.searchStatus.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.getItemWeight = this.getItemWeight.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({currUser, createMawb: {...this.state.createMawb, owner: currUser.displayName}})
    await this.getPackagesByMawbCode()
    await this.getMawbInfo()
  }

  async getPackagesByMawbCode(params = {}) {
    try {
      let {mawbCode} = this.props.match.params;
      const res = await axios.get(`/get-mawb-packages/${mawbCode}`,)
      if (res && res.data) {
        this.setState({items: res.data})
      }
    } catch (e) {
      console.error(e)
    }
  }
  getMawbInfo = async () => {
    try {
      const {mawbCode} = this.props.match.params;
      const res = await axios.get(`/get-mawb/${mawbCode}`)
      if (res && res.data) {
        this.setState({mawbInfo: res.data})
      }
    } catch (e) {
      console.error(e)
    }
  }

  async onCreateMawb(e) {
    e.preventDefault();
    try {
      const {createMawb} = this.state
      let message = ''
      if (!createMawb.mawbCode) {
        message = 'nhập mawbCode'
      }
      if (message) {
        toast.error('Vui lòng ' + message)
        return
      }
      await axios.post('/create-mawb', {...createMawb, date: this.state.date})
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle}));
      await this.getAllMawb()
    } catch (err) {
      toast.error('Đã có lỗi xảy ra ', err.error);
      return
    }
  }

  async oneditMawbPackage(e) {
    const {editMawbPackage} = this.state
    let message = '', createMawb = editMawbPackage
    if (!createMawb.mawbCode) {
      message = 'nhập mawbCode'
    }
    if (message) {
      toast.error('Vui lòng ' + message)
      return
    }
    try {
      await axios.post(`/update-mawb-package/${editMawbPackage.id}`, {...editMawbPackage, date: this.state.date})
      this.setState((prevState) => ({modalEditToggle: !prevState.modalEditToggle}));
      await this.getAllMawb()
      e.preventDefault();
    } catch (err) {
      toast.error('Đã có lỗi xảy ra');
      return
    }

  }

  toggle() {
    this.setState((prevState, props) => ({modalToggle: !prevState.modalToggle}));
  }

  openEditModal(obj) {
    this.setState({editMawbPackage: obj, date: moment(obj.date).subtract(7, 'h')})
    this.setState((prevState, props) => ({modalEditToggle: !prevState.modalEditToggle}));

    //const currentCustomer = this.state.listCustomer.filter(i => i.id === obj.id)?[0] : ''
    //this.setState({selectedCustomer: currentCustomer})

  }

  toggleUpdateMawb() {

  }

  onCreateMawbChange(e) {
    const {name, value} = e.target;
    let createMawbInfo = this.state.createMawb
    createMawbInfo[name] = value
    this.setState({createMawb: createMawbInfo})
  }

  oneditMawbPackageChange(e) {
    const {name, value} = e.target;
    let editMawbPackageInfo = this.state.editMawbPackage
    editMawbPackageInfo[name] = value
    this.setState({editMawbPackage: editMawbPackageInfo})
  }

  formatNumber(num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }

  async onDeleteMawb(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this Mawb?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const res = await axios.get(`/delete-mawb-package/${id}`)
              console.log(res)
              await self.getPackagesByMawbCode()
            },
          },
          {
            label: "No",
            onClick: () => {
            },
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }
  onSelectedCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const {id} = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let createMawbInfo = this.state.createMawb
        createMawbInfo['customerName'] = temp.name
        createMawbInfo['customerId'] = temp.id
        createMawbInfo['customerAddress'] = temp.address
        createMawbInfo['customerPhone'] = temp.phone
        this.setState({selectedCustomer: temp, createMawb: createMawbInfo})

      }
    }

  }

  handleInputChange(e) {
    const inputValue = e;
    // Clear any existing timer
    clearTimeout(timerId);
    // Set a new timer to delay the API call
    timerId = setTimeout(() => {
      console.log(inputValue);
      this.getListCustomer({search: inputValue});
    }, 500);
  }

  onSelectedEditCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const {id} = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let editMawbPackageInfo = this.state.editMawbPackage
        editMawbPackageInfo['customerName'] = temp.name
        editMawbPackageInfo['customerId'] = temp.id
        editMawbPackageInfo['customerAddress'] = temp.address
        editMawbPackageInfo['customerPhone'] = temp.phone
        this.setState({selectedCustomer: temp, editMawbPackage: editMawbPackageInfo})

      }
    }
  }

  async getAllMawb(params = {}) {
    try {
      return;
      const {searchBy, search = '', data} = this.state
      this.setState({loading: true})
      let res
      params.page = params.page || data?.page
      let url = `/get-all-mawb?page=${params.page || 1}&search=${search}&status=${this.state.qStatus}`
      if (searchBy === 'owner') {
        url += `&searchBy=${searchBy}`
      }


      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      if (this.state.location) {
        url += `&location=${this.state.location}`
      }

      if (this.state.currUser.role === USER_ROLE.MANAGER && this.state.currUser.location) {
        url += `&location=${this.state.currUser.location}`
        const options = {
          [this.state.currUser.location]: this.state.currUser.location
        }
        console.log(options)
        this.setState({locationOption: options})
        this.forceUpdate()
      }
      res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async getListCustomer(params = {search: ''}) {
    try {
      let url = `/get-all-customer?page=${params.page || 1}&size=10&search=${params.search}`
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT
        || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.CUSTOMER) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      const res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        const list = res.data.list.map(i => {
          return {
            ...i,
            value: i.id,
            label: `KHTP${i.id} (${i.billupCount || 0})`
          }
        })
        this.setState({listCustomer: list || []})
      }
    } catch (e) {
      console.error(e)
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllMawb({page: pageNumber})
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }));
  }

  onClickPrintMawb(obj) {
    this.setState({printData: this.state.items, mawbInfo: this.state.mawbInfo})
    this.printPackagesToggle();
  }

  handleDatetoSelect(date) {
    console.log(date)
    this.setState({date})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  formatDate = (str) => {
    return moment(str).format('DD/MM/YYYY HH:mm')
  }
  handleInput = (e) => {
    const {value} = e.target
    this.setState({barcode: value})
  }

  async getItem() {
    try {
      const {barcode, mawbCode} = this.state
      if (!barcode) {
        toast.error('Nhập mã vận đơn - hawbCode')
        return
      }
      if (barcode && barcode.length < 8) {
        toast.error('Mã vận đơn không hợp lệ')
        return
      }
      const data = await this.upsertPackage(barcode, mawbCode)
      if (data) {
        this.setState({items: data})
        await this.getPackagesByMawbCode()
      }
      this.setState({barcode: ''})
      let a = document.getElementById("kgInput")
      document.getElementById("kgInput").focus();
    } catch
      (e) {
      console.error(e)
    }
  }

  async upsertPackage(barcode, mawbCode) {
    try {
      const res = await axios.post(`/create-mawb-package`, {
        mawbCode: mawbCode,
        hawbCode: barcode,
      })
      if (res && res.data) {
        // this.setState({items: res.data})
        await this.getPackagesByMawbCode()
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || 'Đã có lỗi xảy ra')
    }
  }

  onSearch = (e) => {
    this.getAllMawb({search: this.state.search})
  }

  searchStatus(val) {
    console.log(val)
    this.setState({qStatus: val})
  }

  searchBy(val) {
    this.setState({searchBy: val})
  }

  searchBylocation(val) {
    this.setState({location: val})
  }

  getItemWeight = (element) => {
    let totalWeight = 0
    const pkgHawb = element.Package.hawbCode
    let hawbIndex = 0
    if (pkgHawb) {
      const arr = pkgHawb?.trim().split(',')
      hawbIndex = arr.findIndex(i => i.trim() == element.hawbCode.trim());
    }
    let packs = JSON.parse(element.Package.packs) || []
    packs.forEach((packInfo, i) => {
      if (packInfo.weight && i === hawbIndex) {
        totalWeight =
          totalWeight + Rounding(packInfo.weight * packInfo.quantity)
      }
    })
    return totalWeight
  }

  downloadPackagesList = async () => {
    try {
      const {mawbCode} = this.state
      axios
        .get(`/download-packages-list/${mawbCode}`, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(
            res.data,
            `MAWB_${mawbCode}_PACKAGES_LIST${new Date().toJSON().slice(0, 10)}.xlsx`,
          )
          this.setState({ disableDownloadButton: false })
        })
        .catch(() => {
          this.setState({ disableDownloadButton: false })
        })
    } catch (e) {
      console.error(e)
    }
  }

  downloadClearance = async () => {
    try {
      const {mawbCode} = this.state
      axios
        .get(`/download-clearance/${mawbCode}`, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(
            res.data,
            `MAWB_${mawbCode}_CLEARANCE${new Date().toJSON().slice(0, 10)}.xlsx`,
          )
          this.setState({ disableDownloadButton: false })
        })
        .catch(() => {
          this.setState({ disableDownloadButton: false })
        })
    } catch (e) {
      console.error(e)
    }
  }

  downloadParcel = async () => {
    try {
      const {mawbCode} = this.state
      axios
        .get(`/download-parcel/${mawbCode}`, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(
            res.data,
            `MAWB_${mawbCode}_PARCEL${new Date().toJSON().slice(0, 10)}.xlsx`,
          )
          this.setState({ disableDownloadButton: false })
        })
        .catch(() => {
          this.setState({ disableDownloadButton: false })
        })
    } catch (e) {
      console.error(e)
    }
  }

  downloadBooking = async () => {
    try {
      const {mawbCode} = this.state
      axios
        .get(`/download-booking/${mawbCode}`, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(
            res.data,
            `MAWB_${mawbCode}_BOOKING${new Date().toJSON().slice(0, 10)}.xlsx`,
          )
          this.setState({ disableDownloadButton: false })
        })
        .catch(() => {
          this.setState({ disableDownloadButton: false })
        })
    } catch (e) {
      console.error(e)
    }
  }

  downloadFihong = async () => {
    try {
      const {mawbCode} = this.state
      axios
        .get(`/download-fihong/${mawbCode}`, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(
            res.data,
            `MAWB_${mawbCode}_FIHONG${new Date().toJSON().slice(0, 10)}.xlsx`,
          )
          this.setState({ disableDownloadButton: false })
        })
        .catch(() => {
          this.setState({ disableDownloadButton: false })
        })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const {editMawbPackage, barcode, items = []} = this.state
    const paymentTypeOption = {
      CASH: "Tiền mặt",
      BANK: "Chuyển khoản",
      ALL: "Tiền mặt & Chuyển khoản",
    }
    const statusTypeOption = {
      PROGRESS: "Chưa nhận",
      PROCESSING: "Đang nhận",
      SUCCESS: "Đã nhận",
      //DONE: 'Hoàn thành chứng từ'
    }

    const printMawb = () => {
      return `@media print {
        @page {
          size: A6 portrait;
          margin: 5mm;
          margin-top: 5mm;
        }  }
        `;
    };

    let totalWeight = parseFloat(0)

    try {

      // eslint-disable-next-line no-unused-expressions
      items &&
      items.forEach((element) => {
        const pkgHawb = element.Package.hawbCode
        let hawbIndex = 0
        if (pkgHawb) {
          const arr = pkgHawb?.trim().split(',')
          hawbIndex = arr.findIndex(i => i.trim() == element.hawbCode.trim());
        }
        let packs = JSON.parse(element.Package.packs) || []
        packs.forEach((packInfo, i) => {
          if (packInfo.weight && i === hawbIndex) {
            totalWeight =
              totalWeight + Rounding(packInfo.weight * packInfo.quantity)
          }
        })
      })
    } catch (e) {
      console.error(e)
    }


    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active>Quản lý hàng xuất</BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col sm={6} className='ml-auto'>
            <div className="mb-3">
              <Card>
                <CardHeader tag="h5">Nhập kiện hàng cần xuất</CardHeader>
                <CardBody>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      this.getItem()
                    }}
                  >
                    <InputGroup>
                      <Input
                        id="kgInput"
                        autoFocus
                        name="kgInput"
                        value={barcode}
                        onChange={this.handleInput}
                      />
                      <Button type="submit" className="ml-3">Enter</Button>
                    </InputGroup>
                  </form>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col sm={6} className='mr-auto'>
            <div className="d-flex  flex-column justify-content-start">
              <div className='mb-3'>
                          <span>
                          <strong>Total Pcs:</strong> {items.length}
                        </span>
                <span className="mx-3">
                          <strong>Total Weight (kg):</strong> {totalWeight}
                        </span>
              </div>
              {
                // check agency of mawbInfo
                this.state.mawbInfo?.agency !== 'FIHONG' ? (
                <div className='d-flex flex-column text-blue'>
                  <a href="#" className='mb-1' onClick={this.downloadPackagesList}>Download Packages List</a>
                  <a href="#" className='mb-1' onClick={this.downloadClearance}>Download Custom Clearance</a>
                  <a href="#" className='mb-1'onClick={this.downloadParcel}>Download Dedicated Small Parcel</a>
                  <a href="#" className='mb-1'onClick={this.downloadBooking}>Download Booking</a>
                </div>
                ) : (
                  <div className='d-flex flex-column text-blue'>
                    <a href="#" className='mb-1' onClick={this.downloadFihong}>Download FIHONG upload template</a>
                  </div>
                )
              }

            </div>
          </Col>
        </Row>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>MawbCode: {this.state.mawbCode}</span>
            <button className='ml-auto btn btn-primary' onClick={(e) => this.onClickPrintMawb()}>Print Packages
            </button>
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table hover responsive size="" striped>
              <thead className="text-center">
              <tr>
                <th>#</th>
                <th>TP Bill</th>
                <th>HawbCode</th>
                <th>Contact</th>
                <th>Address</th>
                <th>Weight</th>
                <th>Bag Weight</th>
                <th>Logistics</th>
                <th>Sent Date</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody className="text-center">
              {items?.map((obj, i) => (
                <tr key={i}>
                  <td className="align-middle">{i + 1}</td>
                  <td className="align-middle">{obj.Package.kgCode || ''}</td>
                  <td className="align-middle">
                    {obj.hawbCode || ''}
                  </td>
                  <td className="align-middle">
                    {' '}
                    {obj.Package.receiverCompanyName} <br/>
                    {obj.Package.receiverName} <br/>
                    {obj.Package.receiverEmail}
                  </td>
                  <td className="align-middle">
                    {' '}
                    {obj.Package.receiverCity +
                      ' / ' +
                      obj.Package.receiverCountry +
                      ' / ' +
                      obj.Package.receiverPostalCode}{' '}
                    <br/> {obj.Package.receiverAddr1} <br/>{' '}
                    {obj.Package.receiverAddr2} <br/> {obj.Package.receiverAddr3}{' '}
                  </td>
                  <td className="align-middle">{this.getItemWeight(obj)}</td>
                  <td className="align-middle">{obj.weight || '_'}</td>
                  <td className="align-middle">{obj.logistics}</td>
                  <td className="align-middle">
                    {new Date(obj.Package.createdAt).toLocaleDateString()}{' '}
                  </td>
                  <td className="align-middle">
                    <Button style={{marginRight: '5px', marginBottom: '5px'}} type="button" color="info"
                            onClick={(e) => this.openEditModal(obj)}>Edit</Button>

                    <Button style={{marginBottom: '5px'}} type="button" color="danger"
                            onClick={(e) => this.onDeleteMawb(obj.packageCode)}>Remove</Button>
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <Row>
          <Modal
            isOpen={this.state.modalPrintPackagesToggle}
            scrollable
            centered
            size='lg'
            fullscreen="true |xxl-down'"
            toggle={this.printPackagesToggle}
          >
            <ModalHeader toggle={this.printPackagesToggle}>
              Print Mawb Packages
            </ModalHeader>
            <ModalBody>
              <style> {printMawb()} </style>
              <div>
                <PrintMawb
                  billData={this.state.printData}
                  mawbInfo={this.state.mawbInfo}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button href='#'> Print this out!</Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

        </Row>

        <Modal isOpen={this.state.modalEditToggle} toggle={this.openEditModal}>
          <Form>
            <ModalHeader toggle={this.openEditModal}>Edit Packages</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="content">City Code</Label>
                <Input type="text" name="citycode" value={editMawbPackage.citycode} id="citycode"
                       placeholder="SYD / HCM..." onChange={this.oneditMawbPackageChange}/>
              </FormGroup>
              <FormGroup>
                <Label for="content">Service</Label>
                <Input type="text" name="service" value={editMawbPackage.service} id="service"
                       placeholder="VN-AU..." onChange={this.oneditMawbPackageChange}/>
              </FormGroup>
              <FormGroup>
                <Label for="content">Logistics</Label>
                <Input type="text" name="logistics" value={editMawbPackage.logistics} id="logistics"
                       placeholder="AUPOST / TOLL / ..." onChange={this.oneditMawbPackageChange}/>
              </FormGroup>
              <FormGroup>
                <Label for="content">Total Bag Weight</Label>
                <Input type="text" name="weight" value={editMawbPackage.weight} id="weight"
                       placeholder="" onChange={this.oneditMawbPackageChange}/>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.oneditMawbPackage(e)}>Update</Button>{' '}
              <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>

      </div>
    )
  }
}

export default MawbExportDetail
