import React from 'react'
import axios from 'axios'
import {
  Button, Card,
  CardBody,
  CardHeader,
  Table, Modal,
  ModalFooter, Form,
  ModalHeader, ModalBody,
  FormGroup, Label, Input
} from "reactstrap";
import jwt from "jsonwebtoken";
import {confirmAlert} from "react-confirm-alert";
import moment from 'moment';
class mawbPackageDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      modalToggle: false,
      modalToggleUpdate: false,
      modalDebitInfo: {},
      currUser: {},
      createDebit: {
        date: "",
        checkpointStatus: "",
        details: "",
        subStatus: "",
        statusDescription: "",
      },
      updateDebit: {},
    }
    this.toggle = this.toggle.bind(this);
    this.toggleUpdate = this.toggleUpdate.bind(this);
    this.onDeleteDebit = this.onDeleteDebit.bind(this);
    this.onUpdateTrackingChange = this.onUpdateTrackingChange.bind(this);
    this.onCreateTrackingChange = this.onCreateTrackingChange.bind(this);
  }
  toggle() {
    this.setState((prevState, props) => ({ modalToggle: !prevState.modalToggle }));
  }
  toggleUpdate() {
    this.setState((prevState, props) => ({ modalToggleUpdate: !prevState.modalToggleUpdate }));
  }
  async setUpdateItem (item) {
    item.date = moment(item.date).format('YYYY-MM-DDTHH:mm')
    await this.setState({ updateDebit: item});
    this.toggleUpdate()
  }
  async onDeleteDebit(id, flagCheck, obj) {
    try {
      const { kgCode } = this.props.match.params

      //const url = !flagCheck ? `/tracking/${id}` : `/tracking/${id}/managed/${kgCode}`
      const url = `/tracking/${id}/managed/${kgCode}`
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this ?",
        buttons: [
          {
            label: "Yes",
            onClick: async() => {
              await axios.delete(url)
              await self.getMawbs()
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({ currUser, createDebit: {...this.state.createDebit, companyName: currUser.companyName} })
    await this.getMawbs()
  }

  async getMawbs() {
    try {
    const { kgCode } = this.props.match.params
    const res = await axios.get(`/tracking/${kgCode}`)
      if (res && res.data) {
        this.setState({data: res.data})
      }
    } catch (e) {
      console.error(e)
    }
  }

  onCreateTrackingChange(e) {
    const { name, value } = e.target;
    let createDebitInfo = this.state.createDebit
    createDebitInfo[name] = value
    this.setState({ createDebit: createDebitInfo })
  }

  async onCreateTracking(e) {
    const { createDebit } = this.state
    const { kgCode } = this.props.match.params
    await axios.post('/tracking', {...createDebit, kgCode, mawbCode: null})
    this.setState((prevState) => ({ modalToggle: !prevState.modalToggle }));
    await this.getMawbs()
    e.preventDefault();
  }

  onUpdateTrackingChange(e) {
    const { name, value } = e.target;
    let updateDebitInfo = this.state.updateDebit
    updateDebitInfo[name] = value
    this.setState({ updateDebit: updateDebitInfo })
  }

  async onUpdateTracking(e) {
    const { updateDebit } = this.state
    await axios.put(`/tracking/${updateDebit.id}`, {...updateDebit})
    await this.getMawbs()
    this.toggleUpdate()
    e.preventDefault();
  }

  render() {
    const { data, updateDebit } = this.state
    const { kgCode } = this.props.match.params

    return (
      <div>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between">
            <p className="h5">TP Code: { kgCode }</p>
            <Button type="button" className="butt" onClick={this.toggle}>+ Thêm chi tiết</Button>
          </CardHeader>
          <CardBody>
            <Table responsive className="mb-0 table-hover">
              <thead>
              <tr>
                <th>date</th>
                <th>checkpointStatus</th>
                <th>details</th>
                {/*<th>subStatus</th>
                <th>statusDescription</th>*/}
              </tr>
              </thead>
              <tbody>
              {data &&
              data.map((obj, i) => (
                <tr key={i + 1}>
                  <td>{moment(obj.date).subtract(7, 'hours').format('YYYY-MM-DD HH:mm')}{' '}</td>
                  <td>{obj.checkpointStatus}</td>
                  <td>{obj.details}</td>
                  {/*<td>{obj.subStatus}</td>
                  <td>{obj.statusDescription}</td>*/}
                  <td><Button type="button" color="danger" onClick={(e) => this.onDeleteDebit(obj.id, obj.kgCode, obj)}>Delete</Button>
                  <Button type="button" onClick={(e) => this.setUpdateItem(obj)}>Edit</Button></td>
                   {/*{ obj.actionFlag &&
                    (<>
                      <td><Button type="button" color="danger" onClick={(e) => this.onDeleteDebit(obj.id, null)}>Delete</Button></td>
                      <td><Button type="button" onClick={(e) => this.setUpdateItem(obj)}>Edit</Button></td>
                    </>)
                    } */}
                </tr>
              ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.modalToggle} toggle={this.toggle} >
            <Form>
              <ModalHeader toggle={this.toggle}>Thêm Chi tiết</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="date">Thời gian</Label>
                  <Input type="datetime-local" name="date" id="date" onChange={this.onCreateTrackingChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="checkpointStatus">Checkpoint Status</Label>
                  <Input name="checkpointStatus" id="checkpointStatus" onChange={this.onCreateTrackingChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="details">Details</Label>
                  <Input name="details" id="details" onChange={this.onCreateTrackingChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="subStatus">Sub Status</Label>
                  <Input name="subStatus" id="subStatus" onChange={this.onCreateTrackingChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="statusDescription">Status Description</Label>
                  <Input name="statusDescription" id="statusDescription" onChange={this.onCreateTrackingChange} />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onCreateTracking(e)} >Create</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={this.state.modalToggleUpdate} toggle={this.toggleUpdate} >
            <Form>
              <ModalHeader toggle={this.toggleUpdate}>Chỉnh sửa</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="date">Thời gian</Label>
                  <Input type="datetime-local" value={updateDebit.date} name="date" id="date" onChange={this.onUpdateTrackingChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="checkpointStatus">Checkpoint Status</Label>
                  <Input name="checkpointStatus" value={updateDebit.checkpointStatus} id="checkpointStatus" onChange={this.onUpdateTrackingChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="details">Details</Label>
                  <Input name="details" value={updateDebit.details} id="details" onChange={this.onUpdateTrackingChange} />
                </FormGroup>
                {/*<FormGroup>
                  <Label for="subStatus">Sub Status</Label>
                  <Input name="subStatus" value={updateDebit.subStatus} id="subStatus" onChange={this.onUpdateTrackingChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="statusDescription">Status Description</Label>
                  <Input name="statusDescription" value={updateDebit.statusDescription} id="statusDescription" onChange={this.onUpdateTrackingChange} />
                </FormGroup>*/}
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onUpdateTracking(e)} >Save</Button>{' '}
                <Button color="secondary" onClick={this.toggleUpdate}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>
      </div>
    )
  }
}
export default mawbPackageDetails
