import jwt from 'jsonwebtoken'

export default function grantPermission (requestedRoles, token = null, isServer = false, path = null) {
  token = !isServer ? localStorage.getItem('id_token') : token
  if (!requestedRoles || !token) return true;
  const { role: CurrentRole, companyName, email } = jwt.decode(token)
  if (companyName === 'TIN PHAT EXPRESS CO.,LTD') return true
  //if (email === 'customerservicetinphat@gmail.com' && path?.includes('/app/tracking')) return true
  return requestedRoles.includes(CurrentRole)
}

export function grantPermissionServer (roles, id_token) {
  if (!grantPermission(roles, id_token, true)) throw new Error('Unauthorized ! Bạn không có quyền thực hiện hành động này x1')
}
