import React from 'react'

const Unauthorized = () => {
  return (
    <div>
      <h1>Không được phép</h1>
      <p> Bạn không có quyền truy cập trang này !</p>
    </div>
  )
}

export default Unauthorized
