import components from './components.svg';
import dashboard from './dashboard.svg';
import notifications from './notifications.svg';
import typography from './typography.svg';
import tables from './tables.svg';
import logo from './kango-logo.svg';
import settings from './settings.svg';
import notification from './notification.svg';
import mail from './mail.svg';
import user from './user.svg';
import plus from './plus.svg';
import minus from './minus.svg';
import tracking from './tracking.svg';
import barcode from './barcode.svg';
import menu from './menu.svg';
import close from './close.svg';
import tick from './tick.png';
import print from './barcode.png';

import ThongBaoImg from './menu/thong-bao.png';
import HoaDonImg from './menu/hoa-don.png';
import KienHangImg from './menu/kien-hang.png';
import BarCodeImg from './menu/barcode.png';
import NhapHangImg from './menu/nhap-hang.png';
import XuatHangImg from './menu/xuat-hang.png';
import TrackingImg from './menu/tracking.png';
import NguoiDungImg from './menu/nguoi-dung.png';
import ChoDuyetImg from './menu/cho-duyet.png';
import ThuChiImg from './menu/thu-chi.png';
import DebitImg from './menu/debit.png';
import LabelUsImg from './menu/label-us.png';
import UspsImg from './menu/usps.png';

export default {
  components,
  dashboard,
  notifications,
  typography,
  tables,
  logo,
  settings,
  notification,
  mail,
  user,
  plus,
  minus,
  tracking,
  barcode,
  menu,
  close,
  tick,
  print,
  ThongBaoImg,
  HoaDonImg,
  KienHangImg,
  BarCodeImg,
  NhapHangImg,
  XuatHangImg,
  TrackingImg,
  NguoiDungImg,
  ChoDuyetImg,
  ThuChiImg,
  DebitImg,
  LabelUsImg,
  UspsImg
};
