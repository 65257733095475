import { combineReducers } from 'redux';
import auth from './auth';
import runtime from './runtime';
import navigation from './navigation';
import users from './users';
import packages from './packages';
import dashboard from './dashboard';
import barcode from './barcode';

export default combineReducers({
  auth,
  runtime,
  navigation,
  users,
  packages,
  dashboard,
  barcode,
});
