import React from 'react'
import {connect} from 'react-redux'
import {
  fetchPackage,
  removePackage,
  resetPackage,
  scanPackage,
} from '../../actions/barcode'
import axios from 'axios'
import fileDownload from 'js-file-download'
import {PACK_TYPE, PACKAGE_ACTION, USER_ROLE} from '../../constants'
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment'
import _ from 'lodash'
import {
  Row,
  Col,
  Table,
  InputGroup,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  Breadcrumb,
  BreadcrumbItem, Modal, Form, ModalHeader, ModalBody, FormGroup, Label, ModalFooter,
} from 'reactstrap'
import Rounding from '../../utils/rounding'
import ImageUploading from "react-images-uploading";
import s from "../bill/Bill.module.scss";
import {confirmAlert} from "react-confirm-alert";
import ReactHtmlParser from "react-html-parser";
// import QrCodeScanner from '../../components/QrCodeScanner';
import BarcodeScanner from '../../components/BarcodeScanner';
import {Link} from "react-router-dom";
import jwt from "jsonwebtoken";
class Import extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currUser: {},
      barcode: '',
      modalToggle: false,
      modalPrintPackagesToggle: false,
      printId: '',
      printData: '',
      modalInvToggle: false,
      disableDownloadButton: false,
      printInvData: '',
      BRANCH: ['HCM', 'HN', 'DAD'],
      selectedBranch: 'HCM',
      modalEditToggle: false,
      modalToggleUpdateCustomer: false,
      modalPackageInfo: {},
      editPackage: {
        hawbCode: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        info: '',
      },
      packList: [
        {
          quantity: "",
          type: ""
        },
      ],
      extraCharge: [],
      currentHawb: '',
      listHawb: [],
      scanned: false,
      currentPackage: {},
      scannedPackage: [],



      // Quagga
      devices: [],
      scanning: false,
      status: "Stoped",
      results: [],
      deviceId: null,
      standards: [
        "code_128_reader",
        "ean_reader",
        "ean_8_reader",
        "code_39_reader",
        "code_39_vin_reader",
        "codabar_reader",
        "upc_reader",
        "upc_e_reader",
        "i2of5_reader",
        "2of5_reader",
        "code_93_reader"
      ],
      standardId: "code_128_reader"
    }
    this.handleInput = this.handleInput.bind(this)
    this.onSelectedBranch = this.onSelectedBranch.bind(this)
    this.onEditCustomer = this.onEditCustomer.bind(this)
    this.onEditPackageChange = this.onEditPackageChange.bind(this);
    this.addPackage = this.addPackage.bind(this);
    this.onRemoveRecord = this.onRemoveRecord.bind(this);
    this.onPackInfoChange = this.onPackInfoChange.bind(this);
    this.openEditModal = this.openEditModal.bind(this);

    this._scan = this._scan.bind(this);
    this._standard = this._standard.bind(this);
  }



  handleSuccess(result) {
    console.log(result)
    if (this.state.scanned) {
      return
    }
    setTimeout(() => {
      this.setState({scanned: false})
    }, 5000)
    this.setState({barcode: result.codeResult.code})
    this.getItem()
    this.setState({scanned: true})
  }

  handleError(error) {
    // console.error(`Error: ${error}`);
  }

  handleInput = (e) => {
    const {value} = e.target
    this.setState({barcode: value})
  }

  onPackInfoChange = (e, index) => {
    // validate here
    const {name, value} = e.target;
    const list = [...this.state.packList];
    list[index][name] = value;


    this.setState((prevState) => ({
      packList: list,
    }));
  };

  addPackage = () => {
    this.setState((prevState) => ({
      packList: [
        ...prevState.packList,
        {
          quantity: "",
          type: ""
        },
      ],
    }));
  };

  onRemoveRecord = (index) => {
    const list = [...this.state.packList];
    list.splice(index, 1);
    this.setState({packList: list});
  };

  async getItem() {
    const {barcode, selectedBranch} = this.state
    this.setState({scannedPackage: [...this.state.scannedPackage, barcode]})
    const b = await this.props.dispatch(scanPackage(barcode, PACKAGE_ACTION.RETURN, '', selectedBranch))
    if (b) {
      // await this.openEditModal(b, barcode)
      await this.inputHawbTracking(barcode, PACKAGE_ACTION.RETURN, selectedBranch)
    }
    this.setState({barcode: ''})
    const {message} = this.props
    if (!!message) {
      // alert('Mã kiện không có trong hệ thống!')
      toast.error('Mã kiện không có trong hệ thống!');
      return;
    }

    let a = document.getElementById("kgInput")
    document.getElementById("kgInput").focus();
  }

  async inputHawbTracking(code, action, branch) {
    try {
      if (code !== this.state.currentHawb) {
        await axios.post(`/create-hawbtracking`, {
          hawbCode: code?.trim(),
          action: action,
          branch: branch,
          owner: this.state.currUser?.displayName,
        })
        this.setState({currentHawb: code})
      }
    } catch (e) {
      console.log(e)
    }
  }

  getHawbCode = (pkg) => {
    let hawbCode = ''
    if (pkg.importJSON && pkg.hawbCode) {
      let importJSON = JSON.parse(pkg.importJSON)
      let hawbArr = pkg.hawbCode.trim().split(',')
      hawbArr = hawbArr.map((hawb) => hawb.trim())
      hawbArr.forEach((hawb, index) => {
        if (importJSON[hawb]) {
          hawbCode += hawb + ' - ' + moment(importJSON[hawb]).format('DD/MM/YYYY hh:mm') + ' <br/>'
        }
      })
    }
    return hawbCode
  }
  getHawbCodeArr = (pkg) => {
    let hawbCode = []
    if (pkg.importJSON && pkg.hawbCode) {
      let importJSON = JSON.parse(pkg.importJSON)
      let hawbArr = pkg.hawbCode.trim().split(',')
      hawbArr = hawbArr.map((hawb) => hawb.trim())
      hawbArr.forEach((hawb, index) => {
        if (importJSON[hawb]) {
          if (this.state.scannedPackage.indexOf(hawb) !== -1) {
            hawbCode.push(hawb)
          }
        }
      })
    }
    return hawbCode
  }

  async onEditCustomer(e) {
    try {
      const {editPackage} = this.state
      await axios.post(`/update-hawbinfo/${editPackage.hawbCode}`, {
        packageCode: editPackage.kgCode,
        length: editPackage.length,
        width: editPackage.width,
        height: editPackage.height,
        weight: editPackage.weight,
        hawbCode: editPackage.hawbCode,
        packList: JSON.stringify(this.state.packList)
      })

      await this.calculatePackage(editPackage)
      this.setState((prevState) => ({modalEditToggle: !prevState.modalEditToggle, images: [], imagesEdit: [], editPackage: {
          hawbCode: '',
          length: '',
          width: '',
          height: '',
          weight: '',
          info: '',
        }, packList: [{quantity: "", type: ""}],
        currentPackage: {},
      }));
      // await this.getAllCustomer()
      e.preventDefault();
    } catch (e) {

    }
  }

  async getAllService(params = {}) {
    try {
      let url = `/get-all-service-name`
      const res = await axios.get(url)
      if (res && res.data) {
        this.setState({listServices: res.data})
      }
    } catch (e) {
      console.error(e)
    }
  }

  async calculatePackage(editPackage) {
    try {
      const { currentPackage } = this.state
      if (currentPackage !== {}) {
        const currentService = this.state.listServices.find((e) => e.name === currentPackage.logisticService);
        let ratioConstant = 5000;
        if (currentService) {
          ratioConstant = currentService.convertNumber;
        }

        let { data: allHawbInfo } = await axios.get(`/get-hawbinfo-by-kgCode/${editPackage.kgCode}`)
        allHawbInfo = allHawbInfo.filter((e) => e.hawbCode?.trim() !== e.packageCode?.trim())

        let reweight = 0, phuthuPrice = 0
        if (allHawbInfo.length > 0) {
          for (let index = 0; index < allHawbInfo.length; index++) {
            const quantity = allHawbInfo[index]['quantity'] || 1
            const convertRatio = Rounding((allHawbInfo[index]["length"] * allHawbInfo[index]["width"] * allHawbInfo[index]["height"]) / ratioConstant)
            const chargedWeight = allHawbInfo[index]['weight'] > convertRatio
              ? Rounding(parseFloat(allHawbInfo[index]['weight']).toFixed(1))
              : Rounding(convertRatio * quantity)
            reweight += parseFloat(chargedWeight)
            const list = JSON.parse(allHawbInfo[index].packList)
            if (list.length > 0) {
              list.forEach((item) => {
                const extraCharge = item.type ? this.state.extraCharge.find((e) => e.name === item.type) : null
                if (extraCharge && item.quantity && extraCharge?.price) {
                  phuthuPrice += parseFloat(item.quantity) * parseFloat(extraCharge.price)
                }
              })
            }
          }
          // check reweight, if >21 auto roundup to 1kg
          if (reweight > 21) {
            reweight = Math.ceil(reweight)
          } else {
            reweight =  Math.round(reweight * 2) / 2;
          }
          try {
            // call api put update package
            await axios.put(`/packages/${this.state.currentPackage?.id}`, {
              paymentNum: reweight,
              phuthuPrice: phuthuPrice
            })
          } catch (e) {
            console.log(e)
          }
        }
      }
    } catch (e) {
      console.log(e)
    }

  }

  onEditPackageChange(e) {
    const {name, value} = e.target;
    let editPackageInfo = this.state.editPackage
    editPackageInfo[name] = value
    this.setState({editPackage: editPackageInfo})
  }

  toggle() {
    this.setState((prevState, props) => ({modalToggle: !prevState.modalToggle}));
  }

  async openEditModal(obj, hawb) {
    console.log(obj, hawb)
    if (hawb) {
      await this.getHawbInfo(hawb)
      this.setState((prevState, props) => ({
        modalEditToggle: !prevState.modalEditToggle,
        editPackage: {...this.state.editPackage, hawbList: obj.hawbCode, kgCode: obj.kgCode, hawbCode: hawb},
        currentPackage: obj,
      }));
    } else {
      this.setState((prevState, props) => ({
        modalEditToggle: !prevState.modalEditToggle,
        editPackage: {kgCode: obj.kgCode},
        packList: [{quantity: "", type: ""}],
        currentPackage: obj,
      }));
    }

  }

  getHawbInfo = async (hawb) => {
    try {
      const {data} = await axios.get(`/get-hawbinfo/${hawb}`)
      if (data) {
        this.setState({editPackage: data, packList: JSON.parse(data.packList)})
      }
    } catch (e) {
      console.log(e)
    }
  }

  getParcel = (pkg) => {
    try {
      let arr = []
      arr = pkg.hawbList?.split(',') || []
      // trim all element in array
      arr = arr?.map((item) => item?.trim())
      let index = arr.indexOf(pkg.hawbCode.trim())
      return `${index + 1}/${arr.length}`
    } catch (e) {
      return ''
    }
  }

  toggleUpdateCustomer() {

  }

  async exportData() {
    await this.setState({disableDownloadButton: true})
    const params = this.props.items.map((i) => i.kgCode)
    axios
      .get('/download', {
        params: {code: params},
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({disableDownloadButton: false})
      })
      .catch(() => {
        this.setState({disableDownloadButton: false})
      })
    return
  }

  async componentDidMount() {
    navigator.mediaDevices
      .enumerateDevices({ video: true, audio: true })
      .then((devices) => {
        const deviceList = devices
          .filter((device) => device.kind === "videoinput")
          .map((device) => {
            return { deviceId: device.deviceId, label: device.label };
          });
        const backCamera = deviceList.find((device) => device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('rear') || device.label.toLowerCase().includes('camera mặt sau'));
        const selectedDevice = backCamera || deviceList[0];
        this.setState({
          devices: deviceList
        });

        setTimeout(() => {
          this.setState({ deviceId: selectedDevice?.deviceId, scanning: true, status: "Scanning" })
        }, 2000)
      });

    // get current User
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({currUser: currUser});

    if (currUser && currUser.location) {
      let branch = currUser.location
      if (branch === 'ĐN') {
        branch = 'DAD'
      }
      if (branch == 'null' || branch == 'NULL') {
        branch = 'HCM'
      }
      this.setState({selectedBranch: branch || 'HCM'})
    }

    this.props.dispatch(resetPackage())
    this.getAllService()
    this.getExtraCharge()
  }

  _scan = (event) => {
    const deviceId = event.target.value;
    if (deviceId && deviceId !== "test" && this.state.standardId) {
      this.setState({ deviceId, scanning: true, status: "Scanning" }, () =>
        setTimeout(3000)
      );
    }
  };

  // reset out camera when leave page
  componentWillUnmount() {
    this.setState({ scanning: false, status: "Stoped", deviceId: null, results: [] });
  }

  _standard = (event) => {
    if (
      this.state.deviceId &&
      this.state.deviceId !== "test" &&
      this.state.standardId
    ) {
      const standardId = event.target.value;
      this.setState({ standardId, scanning: true });
    }
  };

  _onDetected = (result) => {
    this.setState({ results: [result] });
    this.handleSuccess(result);
  };

  getExtraCharge = async () => {
    try {
      const {data} = await axios.get(`/get-all-extracharge`)
      if (data) {
        this.setState({extraCharge: data?.list || []})
      }
    } catch (e) {
      console.log(e)
    }
  }

  removeItem(code) {
    this.props.dispatch(removePackage(code))
  }

  resetItems() {
    this.props.dispatch(resetPackage())
  }

  onSelectedBranch = (e) => {
    const value = e.target.value
    this.setState({selectedBranch: value});
  }


  render() {
    const {barcode, editPackage} = this.state
    let {items} = this.props
    const arr = items
    items = _.values(_.groupBy(arr, 'id')).map(_.last);
    items = items.reverse()

    let totalPacks = 0
    items &&
    items.forEach((element) => {
      let packs = JSON.parse(element.packs) || []
      if (packs.length && packs[0].quantity !== 0) {
        totalPacks = totalPacks + JSON.parse(element.packs).length
      }
    })
    let totalWeight = parseFloat(0)
    items &&
    items.forEach((element) => {
      let packs = JSON.parse(element.packs) || []
      packs.forEach((packInfo) => {
        if (packInfo.weight) {
          totalWeight =
            totalWeight + Rounding(packInfo.weight * packInfo.quantity)
        }
      })
    })

    let count = 0

    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem>Barcode</BreadcrumbItem>
          <BreadcrumbItem active>Nhập hàng</BreadcrumbItem>
        </Breadcrumb>
        <ToastContainer
          limit={1}
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />

        <Row>
          <Col sm={12} md={6} className="mb-3">
            <Card>
              <CardHeader tag="h5">Nhập hàng</CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.getItem()
                  }}
                >
                  <InputGroup>
                    <Input
                      id="kgInput"
                      autoFocus
                      name="code"
                      value={barcode}
                      onChange={this.handleInput}
                    />
                    <Button type="submit" className="ml-3">Enter</Button>
                  </InputGroup>
                </form>
                <label style={{marginTop: '20px'}}>Scan by Camera</label>
                {/*<QrCodeScanner*/}
                {/*  onSuccess={this.handleSuccess.bind(this)}*/}
                {/*  // onError={this.handleError.bind(this)}*/}
                {/*/>*/}

                <div>
                  <div>
                    <div className="header">
                      <div>
                        Camera:
                        <select onChange={this._scan}
                                value={this.state.deviceId}
                        >
                          <option value='test'>Select camera</option>
                          {this.state.devices.map((device) => {
                            return (
                              <option value={device.deviceId} key={device.deviceId}>
                                {device.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {this.state.scanning && this.state.standardId ? (
                      <BarcodeScanner
                        deviceId={this.state.deviceId}
                        onDetected={this._onDetected}
                        standardId={this.state.standardId}
                      />
                    ) : null}

                    {this.state.results.map((result) => {
                      return (
                        <div>
                          <div style={{fontSize: '20px', fontWeight: 'bold', marginTop: '10px'}}>{result.codeResult.code}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <Card>
              <CardHeader tag="h5">BRANCH</CardHeader>
              <CardBody>
                <select value={this.state.selectedBranch}
                        style={{maxWidth: '100%', display: "block"}}
                        className="custom-select mr-sm-2"
                        onChange={(e) => this.onSelectedBranch(e)}
                >
                  {this.state.BRANCH && this.state.BRANCH.map((option) => (
                    <option value={option}>{option}</option>
                  ))}
                </select>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12}>
            <div className="d-flex justify-content-between my-4 align-items-center">
              <h2 className='title-page'>Packages List</h2>
              {items.length > 0 && (
                <Button
                  type="button" className="butt btn-w-170 text-uppercase"
                  disabled={this.state.disableDownloadButton}
                  onClick={() => this.exportData()}
                >
                  <i className="mr-1 fa fa-download" aria-hidden="true"></i>
                  Tải về Excel
                </Button>
              )}
            </div>
            <Table hover responsive size="" striped>
              <thead className="text-center">
              <tr>
                <th>#</th>
                <th>TP Code</th>
                <th>HawbCode</th>
                <th>Date</th>
                <th>Service</th>
                <th>Receiver</th>
                <th>Address</th>
                <th>Owner</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody className="text-center">
              {
                items?.length ? items.map((pkg, i) => (
                    this.getHawbCodeArr(pkg).length > 0 && this.getHawbCodeArr(pkg).map((hawb, index) => (
                        count++,
                          <tr key={i}>
                            <td className="align-middle">{count}</td>
                            <td className=''>
                              <Link to={`/app/packet-detail/${pkg.id}`} target="_blank" rel="noopener noreferrer"> {pkg.kgCode || ''}</Link>
                            </td>
                            <td className="align-middle">
                              <div>{
                                hawb + ' - ' + moment(JSON.parse(pkg.importJSON)[hawb]).format('DD/MM/YYYY hh:mm')
                              }</div>
                            </td>
                            <td className="align-middle">
                              {new Date(pkg.createdAt).toLocaleDateString()}{' '}
                            </td>

                            <td className="align-middle">
                              {
                                pkg.logisticService
                              }
                            </td>
                            <td>
                              {pkg.receiverName}
                            </td>
                            <td className=''>
                              <a href={`https://tracking.gopost.vn?tpCode=${pkg.kgCode}`} target='_blank'
                                 rel="noopener noreferrer">
                                [&nbsp;<i className="fas fa-map-marker-alt text-blue mr-1"></i> {pkg.receiverCountry}]
                              </a>
                              &nbsp;- {pkg.receiverCity}
                            </td>
                            <td>
                              {pkg.owner}
                            </td>
                            <td>
                              {/*<Button style={{marginRight: '5px', marginBottom: '5px'}} type="button" color="info"*/}
                              {/*        onClick={(e) => this.openEditModal(pkg, hawb)}>Edit</Button>*/}
                            </td>
                          </tr>
                      )
                    )
                  )) :
                  <tr>
                    <td colSpan="100" className='text-center'>
                      Danh sách trống. Vui lòng quéc mã barcode trên kiện hàng.
                    </td>
                  </tr>}
              </tbody>
            </Table>
          </Col>
        </Row>

        {/* Toggle Form Edit Customer */}
        <Modal isOpen={this.state.modalEditToggle} toggle={this.openEditModal}>
          <Form>
            <ModalHeader toggle={this.openEditModal}>Cập nhật thông tin Kiện hàng</ModalHeader>
            <ModalBody>
              <FormGroup>
                <div for="name">MAIN CODE: <strong>{editPackage.kgCode}</strong></div>
                <div for="name">HAWB CODE: <strong>{editPackage.hawbCode}</strong></div>
                <div for="name">Parcel: <strong>{this.getParcel(editPackage)}</strong></div>

              </FormGroup>
              <div className="d-flex" style={{gap: '10px'}}>
                <FormGroup>
                  <Label for="content">Length</Label>
                  <Input type="number" name="length" value={editPackage.length} id="length" placeholder="length"
                         onChange={this.onEditPackageChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="content">Width</Label>
                  <Input type="number" name="width" value={editPackage.width} id="width" placeholder="width"
                         onChange={this.onEditPackageChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="content">Height</Label>
                  <Input type="number" name="height" value={editPackage.height} id="height" placeholder="height"
                         onChange={this.onEditPackageChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="content">Weight</Label>
                  <Input type="number" name="weight" value={editPackage.weight} id="weight" placeholder="weight"
                         onChange={this.onEditPackageChange}/>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <Label for="content">OPS Khai Hàng</Label>
                  {this.state.packList.map((x, i) => {
                    return (
                      <tr>
                        <td>
                          <select
                            name="type"
                            className={s.selectCustom}
                            value={x.type}
                            aria-label="Default select example"
                            onChange={(e) => this.onPackInfoChange(e, i)}
                          >
                            <option value="" defaultValue>
                              Chọn mặt hàng phụ thu
                            </option>
                            {this.state.extraCharge.map((item) => (
                              <option value={item.name}>{item.name}</option>
                            ))}
                          </select>
                        </td>
                        <td className="text-center">
                          <div className="input-group input-group-sm">
                            <Input
                              className="form-control quantity"
                              name="quantity"
                              value={x.quantity}
                              type="number"
                              id="quantity"
                              onChange={(e) => this.onPackInfoChange(e, i)}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-default"
                            aria-label="glyphicon-trash"
                            onClick={() => this.onRemoveRecord(i)}
                          >
                              <span
                                className="glyphicon glyphicon-trash"
                                aria-hidden="true"
                              ></span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </FormGroup>
                <div className="text-center d-flex align-content-center justify-content-center">
                  <Button
                    type="button"
                    id="plus"
                    className="btn btn-default btn-add"
                    aria-label="Left Align"
                    onClick={this.addPackage}
                  >
                    <i className="fa fa-plus-circle"></i>
                    Thêm
                  </Button>
                </div>
              </div>

            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.onEditCustomer(e)}>Update</Button>{' '}
              <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.barcode.isFetching,
    items: state.barcode.items,
    message: state.barcode.message,
  }
}

export default connect(mapStateToProps)(Import)
