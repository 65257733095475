import React from 'react'
import axios from 'axios'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  InputGroup,
  Input,
  Table,
} from 'reactstrap'

import { confirmAlert } from 'react-confirm-alert'
import jwt from 'jsonwebtoken'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { vi } from 'date-fns/locale'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import moment from "moment/moment";
import ReactHtmlParser from 'react-html-parser';
class MawbPackage extends React.Component {
  constructor(props) {
    super(props)
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - 3)
    this.state = {
      data: [],
      modalToggle: false,
      modalDebitInfo: {},
      isShowCalendar: false,
      currUser: {},
      startDate,
      endDate: new Date(),
      focus: START_DATE,
      createDebit: {
        companyName: '',
        name: '',
        content: '',
        price: '',
        note: '',
      },
    }
    this.onDeleteChild = this.onDeleteChild.bind(this)
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({
      currUser,
      createDebit: {
        ...this.state.createDebit,
        companyName: currUser.companyName,
      },
    })
    await this.getItem()
  }

  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({ focus })
  }
  setStartDate(startDate) {
    this.setState({ startDate })
  }
  setEndDate(endDate) {
    this.setState({ endDate })
  }
  getImportData = (pkg) => {
    let hawbCode = ''
    if (pkg.importJSON && pkg.hawbCode) {
      let exportJSON = JSON.parse(pkg.importJSON)
      let hawbArr = pkg.hawbCode.trim().split(',')
      hawbArr = hawbArr.map((hawb) => hawb.trim())
      hawbArr.forEach((hawb, index) => {
        if (exportJSON[hawb]) {
          hawbCode += hawb + ' <br/>'
        }
      })
    } else {
      if (pkg.importAt) {
        hawbCode = pkg.kgCode
      }
    }
    return hawbCode
  }

  getExportData = (pkg) => {
    let hawbCode = ''
    if (pkg.exportJSON && pkg.hawbCode) {
      let exportJSON = JSON.parse(pkg.exportJSON)
      let hawbArr = pkg.hawbCode.trim().split(',')
      hawbArr = hawbArr.map((hawb) => hawb.trim())
      hawbArr.forEach((hawb, index) => {
        if (exportJSON[hawb]) {
          hawbCode += hawb + ' <br/>'
        }
      })
    } else {
      if (pkg.exportAt) {
        hawbCode = pkg.kgCode
      }
    }
    return hawbCode
  }


  async getItem() {
    try {
      const { code } = this.props.match.params
      const { data } = await axios.get(`/mawbs/${code}/packages`, {})
      this.setState({ data })
    } catch (error) {}
  }
  onDeleteChild(obj) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this ?",
        buttons: [
          {
            label: "Yes",
            onClick: async() => {
              const url = `/delete-mawb-package/${obj.kgCode}`
              await axios.get(url)
              await this.getItem()
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { data } = this.state
    const { code } = this.props.match.params
    return (
      <div>
        <Card style={{ marginTop: 20 }}>
          <CardHeader className="d-flex justify-content-between">
            <p className="h5">MAWB Code: {code}</p>
          </CardHeader>
          <CardBody>
            <Table responsive className="mb-0 table-hover text-center">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Code</th>
                  <th>Service</th>
                  <th>Nhập Hàng</th>
                  <th>Xuất Hàng</th>
                  <th>TRACKING</th>
                  <th>Chi tiết</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((obj, i) => (
                    <tr key={i + 1}>
                      <td>{i + 1}</td>
                      <td>{obj.kgCode}</td>
                      <td>{obj.logisticService} </td>
                      <td>{ReactHtmlParser(this.getImportData(obj))}</td>
                      <td>{ReactHtmlParser(this.getExportData(obj))}</td>
                      <td className='align-middle'>
                        {obj.logisticCode && (
                          <a href={`https://tracking.gopost.vn?tpCode=${obj.kgCode}`} target='_blank' rel="noopener noreferrer">{obj.logisticCode}</a>
                        )}
                      </td>
                      <td>
                        <Link to={'/app/tracking/' + obj.kgCode + '/management'}>
                          Chi tiết
                        </Link>
                        <Button type="button" className="ml-3 p-0 ql-color-red" color="non" onClick={(e) => this.onDeleteChild(obj)}>Delete</Button>

                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    )
  }
}
export default MawbPackage
