import React from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  Input,
  Button,
  Table,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import fileDownload from 'js-file-download'
import Select from 'react-select'
import { format } from 'date-fns'
import { vi } from 'date-fns/locale'
import 'react-nice-dates/build/style.css'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
import {DOC_TYPE, PACK_TYPE, USER_ROLE} from '../../constants'
import CurrencyInput from 'react-currency-input-field'
import Rounding from '../../utils/rounding'
import moment from 'moment';
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import {confirmAlert} from "react-confirm-alert";
import {deletePackage} from "../../actions/package";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class Report extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      kgCode: '',
      date: '',
      filterDateType: '',
      filterOptionsType: '',
      searchValue: '',
      filterDateTypeValidation: false,
      filterOptionsTypeValidation: false,
      items: [],
      search: '',
      disableDownloadButton: false,
      isShowCalendar: false,
      /*startDate: moment().startOf('month').format('DD-MM-YYYY HH:mm'),
      endDate: moment().endOf('month').format('DD-MM-YYYY HH:mm'),*/
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // get last 30 days
      endDate: new Date(),
      focus: START_DATE,
      modalToggleUpdate: false,
      isShowStatusNone: true,
      pkg: {},
      index: null,
      loading: false,
      currUser: {},
      data: {
        total: 0,
        list: [],
        size: 1,
        from: 0,
        page: 1
      },
      totalPrice: {
        totalLoiNhuan: 0,
        chiPhiPhatSinh: 0,
        chiPhiThung: 0,
        chiPhiQuaDoiTac: 0,
        totalBh: 0,
        sttk: 0,
        stt: 0,
        tn: 0
      },
      searchBy: 'kgCode',
      priceService: {},
      opsPriceService: {},
      searchByChiPhi: null
    }
    this.handleInput = this.handleInput.bind(this)
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this)
    this.toggleUpdateItem = this.toggleUpdateItem.bind(this)
    this.onUpdateDashboard = this.onUpdateDashboard.bind(this)
    this.onRemovePackageDetails = this.onRemovePackageDetails.bind(this)
    this.onUpdateSearchValue = this.onUpdateSearchValue.bind(this)
    this.getAllReport = this.getAllReport.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.onDeletePackage = this.onDeletePackage.bind(this)
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleDatetoSelect2 = this.handleDatetoSelect2.bind(this);
    this.searchBy = this.searchBy.bind(this);
  }
  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllReport({page: pageNumber})
  }

  toggleUpdateItem() {
    this.setState({
      modalToggleUpdate: !this.state.modalToggleUpdate,
    })
  }
  searchBy(val) {
    this.setState({searchBy: val})
  }
  searchByChiPhi(val) {
    this.setState({searchByChiPhi: val})
  }
  onUpdateDashboard(item, i) {
    if (item && item.billup) {
      if (!item.totalPrice) {
        item.totalPrice = item.billup.totalPrice
      }
    }
    this.setState({
      pkg: item,
      index: i,
      modalToggleUpdate: !this.state.modalToggleUpdate,
    })

    this.getPriceServiceOfUser(item)
    this.getPriceOpsServiceOfUser(item)
  }

  getPriceServiceOfUser(item) {
    try {
      let owner = item.billup?.owner || item.owner
      axios.get(`/get-price-service/${owner}?service=${item.logisticService}`).then(res => {
        if (res && res.data) {
          this.setState({priceService: res.data})
        } else {
          this.setState({priceService: {}})
        }
      }).catch(e => {
        this.setState({priceService: {}})
      })
    } catch (e) {
      this.setState({priceService: {}})
    }
  }
  getPriceOpsServiceOfUser(item) {
    try {
      let owner = item.billup?.owner || item.owner
      axios.get(`/get-ops-price-service/${owner}?service=${item.logisticService}`).then(res => {
        if (res && res.data) {
          this.setState({opsPriceService: res.data})
        } else {
          this.setState({opsPriceService: {}})
        }
      }).catch(e => {
        this.setState({opsPriceService: {}})
      })
    } catch (e) {
      this.setState({opsPriceService: {}})
    }
  }

  onDeletePackage(item, i) {
    const { data } = this.state
    confirmAlert({
      title: 'Xác nhận Xoá',
      message: 'Bạn có chắc muốn xoá thông tin kiện hàng này?',
      buttons: [
        {
          label: 'Có',
          onClick: async () => {
            try {
              await axios.get(`/delete-package/${item.id}`)
              await this.getAllReport({page: data.page})
            } catch (e) {

            }
          },
        },
        {
          label: 'Không',
          onClick: () => {},
        },
      ],
    });
  }

  handleDatetoSelect(date) {
    this.setState({ date })
  }

  handleInput(e) {
    this.setState({ search: e.target.value })
  }

  onUpdateSearchValue (e) {
    this.setState({ searchValue: e.target.value })
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({ currUser, createBillup: {...this.state.createBillup, owner: currUser.displayName} })
    await this.getAllReport()
  }

  async getAllReport(params = {}) {
    try {
      const self = this
      const { data } = this.state
      params.page = params.page  || data?.page

      this.setState({loading: true})
      const {} = params
      const {startDate, endDate, search = '', searchBy = 'kgCode'} = this.state;
      let res;
      let url = `/get-all-report-new?page=${params.page||1}&search=${search}&startDate=${startDate}&endDate=${endDate}&searchBy=${searchBy}&searchByChiPhi=${this.state.searchByChiPhi}`;
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.MANAGER) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      this.getAllReports({})
      res = await axios.get(url)
      if (res && res.data) {
        let list = res.data.list || []
        // if each item.billup is not exist, billup === PackageInfo
        // list = list.map(item => {
        //   if (!item.billup) {
        //     //item.billup = item.PackageInfo
        //   }
        //   return item
        // })
        res.data.list = list
        this.setState({data: res.data})
      }
      this.setState({loading: false})
      // this.getAllReports({})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async getAllReports(params = {}) {
    try {
      const self = this
      this.setState({loading: true})
      const {} = params
      const { data } = this.state
      params.page = params.page !== 1 ? params.page : data?.page
      const {startDate, endDate, search = '', searchBy = 'kgCode'} = this.state;
      let res;
      let url = `/get-all-reports-new?page=${params.page||1}&search=${search}&startDate=${startDate}&endDate=${endDate}&searchBy=${searchBy}&searchByChiPhi=${this.state.searchByChiPhi}`;
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.MANAGER) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      res = await axios.get(url)

      if (res && res.data && res.data.length) {
        let list = res.data || []
        // if each item.billup is not exist, billup === PackageInfo
        // list = list.map(item => {
        //   if (!item.billup) {
        //     // item.billup = item.PackageInfo
        //   }
        //   return item
        // })
        res.data = list

        let totalLoiNhuan = 0,
          chiPhiPhatSinh = 0,
          chiPhiThung = 0,
          totalBh = 0,
          chiPhiQuaDoiTac = 0,
          sttk = 0,
          stt = 0,
          tn = 0;
        res.data.forEach(item => {
          totalLoiNhuan += self.calLoiNhuan(item) || 0
          totalBh += self.calBhPrice(item) || 0
          chiPhiPhatSinh += parseFloat(item.paymentBanking || 0)
          chiPhiThung += self.getChiPhiThung(item) || 0
          chiPhiQuaDoiTac += parseInt(item.reportName || 0)
          sttk += parseFloat(self.getTienThu(item)) || 0
          stt += parseFloat(item.paymentCash || 0)
          tn += parseFloat(self.getTongNo(item))
        })
        self.setState({totalPrice: {
            totalLoiNhuan,
            chiPhiThung,
            chiPhiPhatSinh,
            chiPhiQuaDoiTac,
            totalBh,
            sttk,
            stt,
            tn
          }})
      }
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async onSubmit() {
    const { items } = this.state
    let { name } = this.props.match.params
    const { data } = await axios.post(`/reports/${name}/managed-package`, {
      kgCode: this.state.kgCode,
      reportAt: new Date(),
    })
    this.setState({ items: [...items, data] })
  }

  async exportData() {
    await this.setState({ disableDownloadButton: true })
    //let { name } = this.props.match.params
    const { filterDateType, startDate, endDate, searchValue, filterOptionsType, isShowStatusNone} = this.state
    /*let qs = `name=${name}`
    if (filterDateType) {
      qs += `&datetype=${filterDateType}&startDate=${startDate}&endDate=${endDate}`
    }
    if (searchValue && filterOptionsType) {
      qs += `&${filterOptionsType}=${searchValue}`
    }
    if (isShowStatusNone) {
      qs += `&isShowStatusNone=${isShowStatusNone}`
    }*/
    let qs = `&datetype=createdAt&startDate=${startDate}&endDate=${endDate}&searchBy=${this.state.searchBy}&search=${this.state.search}`
    if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
      qs += `&owner=${this.state.currUser.displayName}`
    }
    axios
      .get(`/download-report?${qs}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({ disableDownloadButton: false })
      })
      .catch(() => {
        this.setState({ disableDownloadButton: false })
      })
    return
  }

  handleDatefromSelect(date) {
    this.setState({ startDate: date });
  }
  handleDatetoSelect2(date) {
    this.setState({ endDate: date });
  }

  async onChangeInput(e) {
    const { name, value } = e.target
    const item = { ...this.state.pkg }
    item[name] = value
    await this.setState({ pkg: item })
  }

  async onValueChange(value, name) {
    const item = { ...this.state.pkg }
    item[name] = value
    console.log(item, value)
    await this.setState({ pkg: item })


    if (name === "paymentNum") {
      let owner = this.state.pkg.billup?.owner || this.state.pkg.owner
      if (owner) {
        // call api to get user info

      }
      this.calculateTotalPrice(value)
      this.calculateOpsTotalPrice(value)
    }
  }

  async calculateTotalPrice(rwKg) {
    // delay 1s to wait for user input and clear timeout if user input again
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(async () => {
      if(this.state.priceService && this.state.priceService !== {}) {
        let priceJSON = this.state.priceService.priceJSON || ''
        if (priceJSON) {
          priceJSON = JSON.parse(priceJSON)
          let totalPrice = 0
          let rw = parseFloat(rwKg)
          let closestKey;
          let minDifference = Infinity;
          if (rw > 0) {
            for (const key in priceJSON) {
              const difference = rw - parseFloat(key)
              if (difference < minDifference && difference >= 0) {
                minDifference = difference;
                closestKey = key;
              }
            }

            if (closestKey) {
              // - Lấy giá từ 0.5 ->20.5 của hãng TP-US, RG-AU
              // - (Phần dịch vụ sẽ lấy từ phần kiện hàng để chuẩn xác nhất[cho chọn chứ không điền]), giá từ 21kg sẽ lấy cost x với số kg reweight. —> Chi phí qua đối tác.
              // - Số KGRW —> tính tiền , lưu luôn  ( trừ Bill Role đại lý, thì k tính)
              if (closestKey >= 21) {
                totalPrice = parseFloat(priceJSON[closestKey]) * rw
              } else {
                totalPrice = parseFloat(priceJSON[closestKey])
              }
              const item = { ...this.state.pkg }
              item['reportName'] = totalPrice
              await this.setState({ pkg: item })
            } else {
              const item = { ...this.state.pkg }
              item['reportName'] = 0
              await this.setState({ pkg: item })
            }
          } else {
            const item = { ...this.state.pkg }
            item['reportName'] = 0
            await this.setState({ pkg: item })
          }

        }
      }
    },300)

  }
  async calculateOpsTotalPrice(rwKg) {
    // delay 1s to wait for user input and clear timeout if user input again
    if (this.timeout2) {
      clearTimeout(this.timeout2)
    }

    // paymentCash
    this.timeout2 = setTimeout(async () => {
      if(this.state.opsPriceService && this.state.opsPriceService !== {}) {
        let priceJSON = this.state.opsPriceService.priceJSON || ''
        if (priceJSON) {
          priceJSON = JSON.parse(priceJSON)
          let totalPrice = 0
          let rw = parseFloat(rwKg)
          let closestKey;
          let minDifference = Infinity;
          if (rw > 0) {
            for (const key in priceJSON) {
              const difference = rw - parseFloat(key)
              if (difference < minDifference && difference >= 0) {
                minDifference = difference;
                closestKey = key;
              }
            }

            if (closestKey) {
              // - Lấy giá từ 0.5 ->20.5 của hãng TP-US, RG-AU
              // - (Phần dịch vụ sẽ lấy từ phần kiện hàng để chuẩn xác nhất[cho chọn chứ không điền]), giá từ 21kg sẽ lấy cost x với số kg reweight. —> Chi phí qua đối tác.
              // - Số KGRW —> tính tiền , lưu luôn  ( trừ Bill Role đại lý, thì k tính)
              if (closestKey >= 21) {
                totalPrice = parseFloat(priceJSON[closestKey]) * rw
              } else {
                totalPrice = parseFloat(priceJSON[closestKey])
              }
              const item = { ...this.state.pkg }
              item['totalPrice'] = totalPrice
              await this.setState({ pkg: item })
            } else {
              const item = { ...this.state.pkg }
              item['totalPrice'] = 0
              await this.setState({ pkg: item })
            }
          } else {
            const item = { ...this.state.pkg }
            item['totalPrice'] = 0
            await this.setState({ pkg: item })
          }

        }
      }
    },300)

  }

  async onRemovePackageDetails(i) {
    const { id } = this.state.items[i]
    axios
      .put(`/packages/${id}/managed-report`, {})
      .then(async (res) => {
        toast.success('Đã xoá ra khỏi báo cáo')
        const { items } = this.state
        const list = [...items]
        list.splice(i, 1)
        await this.setState({ items: list })
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Đã xảy ra lỗi vui lòng thử lại sau.')
        }
      })
    return
  }

  async onUpdatePackageDetails() {
    const {
      id,
      note,
      paymentCash,
      paymentBanking,
      paymentCod,
      logisticService,
      receiverCountry,
      senderCompanyName,
      logisticCode,
      receiverName,
      paymentNum,
      chargeableWeight,
      reportName,
      reportStatus,
      kgCode,
      totalPrice,
      firmPrice,
    } = this.state.pkg
    const {data} = this.state
    axios
      .put('/packages/' + id, {
        note,
        paymentCash,
        paymentBanking: paymentBanking || 0,
        paymentCod: paymentCod || 0,
        logisticService,
        receiverCountry,
        senderCompanyName,
        logisticCode,
        receiverName,
        firmPrice,
        paymentNum: paymentNum || 0,
        chargeableWeight: chargeableWeight || 0,
        reportName: reportName || 0,
        reportStatus,
        totalPrice,
      })
      .then(async (res) => {
        toast.success('Cập nhật đơn hàng thành công')
        const { pkg, items, index } = this.state
        const list = [...items]
        list[index] = pkg
        await this.setState({ items: list })
        this.toggleUpdateItem()
        await this.getAllReport({page: data.page})
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Đã xảy ra lỗi vui lòng thử lại sau.')
        }
      })

    console.log('call here')
    // update report status
    let code = kgCode.replace('TP', '')
    await axios.post(`/update-billup/${code}`, {
      statusPayment: reportStatus,
      totalPrice
    })

    return
  }

  onSelectChange = (e) => {
    const { value } = e
    this.setState({ filterDateType: value })
  }

  onFilterChange = (e) => {
    const { value } = e
    this.setState({ filterOptionsType: value })
  }

  setDate = (e) => {
    const { value } = e
    this.setState({ filterDateType: value })
  }

  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({ focus })
  }
  setStartDate(startDate) {
    this.setState({ startDate })
  }
  setEndDate(endDate) {
    this.setState({ endDate })
  }

  async handleShowResult(e) {
    const { checked } = e.target
    await this.setState({ isShowStatusNone: checked })
    this.filterData()
  }

  async filterData() {
    await this.getAllReport();
  }
  totalNum(packages) {
    const packs = JSON.parse(packages.packs || '{}')
    if (Object.keys(packs).length > 0) {
      return (
        packs.reduce(function (a, b) {
          return a + parseFloat(b['quantity'])
        }, 0) || 0
      )
    }
    return 0
  }
  totalWeight(packages) {
    if (packages.chargeableWeight) return packages.chargeableWeight
    const packs = JSON.parse(packages.packs || '{}')
    if (Object.keys(packs).length > 0) {
      return (
        Rounding(
          packs.reduce(function (a, b) {
            return a + parseFloat(b['chargedWeight'])
          }, 0),
        ) || ''
      )
      /*return (
        Math.round(
          packs.reduce(function (a, b) {
            return a + parseFloat(b['chargedWeight'])
          }, 0),
        ) || 0
      )*/
    }
    return 0
  }

  isExpired (item) {
    const updatedDate = new Date(item.createdAt)
    updatedDate.setDate(updatedDate.getDate() + 1);
    const now = new Date()
    return (updatedDate.getTime() <= now.getTime()) && !item.paymentBanking && !item.paymentCash
  }

  getBillKg (billup) {
    if (billup) {
      return billup.weight > billup.weight2 ? billup.weight : billup.weight2
    }
    return 0
  }
  getTienThu (item) {
    const { billup } = item
    let sttk = item.totalPrice || 0
    let bhPrice = 0
    if (item.PackageInfo) {
      bhPrice = item.PackageInfo.bhPrice || 0
    }
    if (billup) {
      bhPrice = billup.bhPrice || 0
      if (!sttk) {
        sttk = billup.totalPrice || 0
      }
    }
    return parseInt(sttk || 0) + parseInt(bhPrice || 0)
  }
  getChiPhiThung (item) {
    let totalPacks = 0
    if (item.packageType === PACK_TYPE) {
      var packs = JSON.parse(item.packs)
      totalPacks = packs.reduce(function (a, b) {
        return a + parseInt(b['quantity'])
      }, 0)
    } else {
      totalPacks = item.docCount || 0
    }
    return parseFloat(totalPacks) * 50000
  }
  getTongNo (item) {
    let tongthu = parseFloat(this.getTienThu(item) || 0)
    let dathu = parseFloat(item.paymentCash || 0)
    return tongthu - dathu
  }
  formatNumber (num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }
  totalChiPhi (item) {
    return parseFloat(item.paymentBanking || 0) + this.getChiPhiThung(item) + parseFloat(item.reportName || 0)
  }
  calLoiNhuan (item) {
    return parseFloat(this.getTienThu(item) - this.totalChiPhi(item))
  }
  calBhPrice (item) {
    let val = item.PackageInfo?.bhPrice || 0
    if (item.billup) {
      val = item.billup.bhPrice || 0
    }
    return val;
  }

  getPaymentStatus(pkg) {
    let text = pkg.reportStatus
    if (pkg.billup && pkg.billup.statusPayment) {
      text = pkg.billup.statusPayment
    }

    if (text === 'Chưa hoàn tất thu chi') {
      text = 'Chưa thanh toán'
    }
    if (text === "Đã hoàn tất thu chi") {
      text = 'Đã thanh toán'
    }
    return text
  }

  getDateInfo(pkg) {
    // if createdAt > 10/10/2021 => return createdAt
    // else return reportAt
    if (pkg.createdAt && new Date(pkg.createdAt) < new Date('2024-08-01')) {
      return moment(pkg.createdAt).format('DD/MM/YYYY HH:mm')
    }
    return moment(pkg.exportAt).format('DD/MM/YYYY HH:mm')
  }

  render() {
    const {
      kgCode,
      items,
      filterDateType,
      startDate,
      endDate,
      focus,
      isShowCalendar,
      pkg,
      searchValue,
      filterOptionsType,
      isShowStatusNone,
      data
    } = this.state
    let paymentCodTotal = 0,
      paymentCashTotal = 0,
      paymentBankingTotal = 0
    if (items && items.length > 0) {
      paymentCodTotal = items.reduce(function (a, b) {
        return b ? a + parseFloat(b.paymentCod || 0) : 0
      }, 0)
      paymentCashTotal = items.reduce(function (a, b) {
        return b ? a + parseFloat(b.paymentCash || 0) : 0
      }, 0)
      paymentBankingTotal = items.reduce(function (a, b) {
        return b ? a + parseFloat(b.paymentBanking || 0) : 0
      }, 0)
    }
    const options = [
      { value: 'createdAt', label: 'Ngày Tạo' },
      { value: 'reportAt', label: 'Ngày Tạo Báo Cáo' },
    ]
    const filterOptions = [
      { value: 'code', label: 'TP Bill' },
      { value: 'senderCompanyName', label: 'Người gửi' },
      { value: 'receiverCompanyName', label: 'Người nhận' },
    ]
    return (
      <>
        <Modal
          isOpen={this.state.modalToggleUpdate}
          toggle={this.toggleUpdateItem}
          backdrop="static"
          size="xl"
        >
          <ModalHeader>Chỉnh sửa Thông tin</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="title">SHIPPER ACCT:</Label>
                  <Input
                    type="text"
                    value={pkg.senderCompanyName}
                    onChange={(e) => this.onChangeInput(e)}
                    name="senderCompanyName"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">RECEIVER ( CONTACT NAME ):</Label>
                  <Input
                    type="text"
                    value={pkg.receiverName}
                    onChange={(e) => this.onChangeInput(e)}
                    name="receiverName"
                  />
                </FormGroup>
                {pkg.logisticService && (
                  <>
                    <FormGroup>
                      <Label for="title">SERVICE:</Label>
                      <Input
                        type="text"
                        value={pkg.logisticService}
                        onChange={(e) => this.onChangeInput(e)}
                        name="logisticService"
                      />
                    </FormGroup>
                  </>
                )}
                {pkg.logisticCode && (
                  <>
                    <FormGroup>
                      <Label for="title">TRACKING:</Label>
                      <Input
                        type="text"
                        value={pkg.senderCompanyName}
                        onChange={(e) => this.onChangeInput(e)}
                        name="senderCompanyName"
                      />
                    </FormGroup>
                  </>
                )}
                <FormGroup>
                  <Label for="title">ARRIVAL COUNTRY:</Label>
                  <Input
                    type="text"
                    value={pkg.receiverCountry}
                    onChange={(e) => this.onChangeInput(e)}
                    name="receiverCountry"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Chargeable Weight:</Label>
                  <Input
                    type="text"
                    value={this.totalWeight(pkg)}
                    onChange={(e) => this.onChangeInput(e)}
                    name="chargeableWeight"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="status">Trạng thái:</Label>
                  <Input
                    id="status"
                    name="reportStatus"
                    type="select"
                    value={pkg.reportStatus}
                    onChange={(e) => this.onChangeInput(e)}
                  >
                    <option value="Chưa thanh toán">
                      Chưa thanh toán
                    </option>
                    <option value="Đã thanh toán">
                      Đã thanh toán
                    </option>
                    <option value="Công nợ">
                      Công nợ
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="title">Số tiền thu khách:</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={true}
                    type="text"
                    value={pkg.totalPrice}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="totalPrice"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Phụ thu Xuất:</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={true}
                    type="text"
                    value={pkg.phuthuPrice}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="totalPrice"
                  />
                </FormGroup>
              </Col>

              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="title">Thanh toán hộ:</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={true}
                    type="text"
                    value={pkg.paymentCod}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="paymentCod"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Chi phí phát sinh</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={true}
                    type="text"
                    value={pkg.paymentBanking}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="paymentBanking"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Số KGRW</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={false}
                    decimalSeparator="."
                    groupSeparator=","
                    disableGroupSeparators={true}
                    type="text"
                    value={pkg.paymentNum}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="paymentNum"
                  />
                </FormGroup>{' '}
                <FormGroup>
                  <Label for="title">Số tiền thu:</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={true}
                    type="text"
                    value={pkg.paymentCash}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="paymentCash"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Chi phí qua đối tác</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={true}
                    type="text"
                    value={pkg.reportName}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="reportName"
                  />
                </FormGroup>
                {
                  (this.state.currUser.role === USER_ROLE.ACCOUNTANT || this.state.currUser.role === USER_ROLE.ADMIN) && (
                    <FormGroup>
                      <Label for="title">Giá hãng</Label>
                      <CurrencyInput
                        className="form-control"
                        thousandSeparator={true}
                        type="text"
                        value={pkg.firmPrice}
                        onValueChange={(value, name) =>
                          this.onValueChange(value, name)
                        }
                        name="firmPrice"
                      />
                    </FormGroup>
                  )
                }
                <FormGroup>
                  <Label for="title">Ghi chú:</Label>
                  <textarea
                    className="form-control"
                    rows="4"
                    value={pkg.note}
                    onChange={(e) => this.onChangeInput(e)}
                    name="note"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="success"
              onClick={(e) => this.onUpdatePackageDetails()}
            >
              Cập nhật
            </Button>
            <Button color="secondary" onClick={this.toggleUpdateItem}>
              Huỷ
            </Button>
          </ModalFooter>
        </Modal>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Row>
          <Col sm={12} md={12}>
            <Card>
              <CardHeader tag="h5">Thu chi - Lọc</CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.filterData()
                  }}
                >
                  <Row>
                    <Col sm={6}>
                      <div className="card-body">
                        <Row>
                          <Col sm={6}>
                            <div style={{ display: "flex", maxWidth: "200px" }}>
                              <InputGroup>
                                <label className='mr-sm-2' htmlFor='fromDate'>
                                  From Date
                                </label>
                                <DatePicker
                                    id='fromDate'
                                    selected={this.state.startDate}
                                    onSelect={this.handleDatefromSelect}
                                    className="form-control"
                                />
                              </InputGroup>
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div style={{ display: "flex", maxWidth: "200px" }}>
                              <InputGroup>
                                <label className='mr-sm-2' htmlFor='toDate'>
                                  To Date
                                </label>
                                <DatePicker
                                    id='toDate'
                                    selected={this.state.endDate}
                                    onSelect={this.handleDatetoSelect2}
                                    className="form-control"
                                />
                              </InputGroup>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div style={{marginBottom: '10px'}}>
                        <Label style={{marginRight: '8px'}}>Search By: </Label>
                        <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                                onChange={(e) => this.searchBy(e.target.value)}
                        >
                          <option value="kgCode">Mã TP</option>
                          <option value="logisticCode">Mã tracking</option>
                          <option value="owner">Người tạo</option>
                          <option value="receiverName">Tên người nhận</option>
                          <option value="khCode">Mã KH</option>
                          <option value="logisticService">Dịch Vụ Vận Chuyển (Services)</option>
                        </select>
                      </div>
                      <div>
                        <Label style={{marginRight: '8px'}}>Chí phí qua đối tác: </Label>
                        <select className="custom-select" style={{width: '200px', marginBottom: '10px'}}
                                onChange={(e) => this.searchByChiPhi(e.target.value)}
                        >
                          <option value="">All</option>
                          <option value="yes">Đã nhập</option>
                          <option value="no">Chưa nhập</option>
                        </select>
                      </div>
                      <InputGroup>
                        <Input
                            id="kgInput"
                            autoFocus
                            name="code"
                            value={this.state.search}
                            placeholder='Tìm kiếm ...'
                            onChange={this.handleInput}
                        />
                        <Button type="submit" className="btn-search ml-2">Search</Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </form>
                <div className="card-body report-info mt-2" style={{color: '#000'}}>
                  <Row>
                    <Col sm={6}>
                      <p>Số tiền thu khách: <strong>{this.formatNumber(this.state.totalPrice.sttk)}</strong> đ</p>
                      <p>Số tiền thu: <strong>{this.formatNumber(this.state.totalPrice.stt)} đ</strong></p>
                      <p>Tồn nợ: <strong>{this.formatNumber(this.state.totalPrice.tn)} đ</strong></p>
                      <p>Bảo hiểm: <strong>{this.formatNumber(this.state.totalPrice.totalBh)} đ</strong></p>
                    </Col>
                    <Col sm={6}>
                      <p>Chi phí phát sinh: <strong>{this.formatNumber(this.state.totalPrice.chiPhiPhatSinh)} đ</strong></p>
                      <p>Chi phí thùng: <strong>{this.formatNumber(this.state.totalPrice.chiPhiThung)} đ</strong></p>
                      {
                          this.state.currUser.role !== USER_ROLE.CONSULTANT && (
                              <div>
                                <p>Chi phí qua đối tác: <strong>{this.formatNumber(this.state.totalPrice.chiPhiQuaDoiTac)} đ</strong></p>
                                <p>Lợi nhuận: <strong>{this.formatNumber(this.state.totalPrice.totalLoiNhuan)} đ</strong></p>
                              </div>
                          )
                      }

                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} className="mt-3">
            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <p className="h5 font-weight-bold m-0">Danh sách kiện hàng </p>
                {data.list.length > 0 && (
                  <Button
                    disabled={this.state.disableDownloadButton}
                    onClick={() => this.exportData()}
                  >
                    Tải về Excel
                  </Button>
                )}
              </CardHeader>
              <CardBody>
                {data.list.length > 0 && (
                  <Table hover responsive size="" striped>
                    <thead className="text-center">
                      <tr>
                        <th>#</th>
                        <th>Mã TP</th>
                        <th>Thông tin</th>
                        <th>Số KG</th>
                        <th className="text-left">
                          Tiền thu về
                        </th>
                        <th className="text-left">Chi Phí</th>
                        {
                            this.state.currUser.role !== USER_ROLE.CONSULTANT && (
                                <th>Lợi nhuận</th>

                            )
                        }
                        <th>Bảo hiểm</th>
                        <th>Nhân viên</th>
                        <th>Ghi chú</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {data.list.map((pkg, i) => (
                        <tr >
                          <td className="align-middle">{i + 1}</td>
                          <td className={`align-middle ${this.getPaymentStatus(pkg) == 'Đã thanh toán' ? 'text-success' : 'text-danger'}`}><strong>{pkg.kgCode || ''}</strong></td>
                          <td className="align-middle text-left">
                            <p>
                              <span className="text-uppercase">
                              Người nhận: <strong>{pkg.receiverName}</strong>
                              </span>
                            </p>
                            <p>
                              {pkg.logisticService && (
                                <span className="text-uppercase">
                                  Dịch vụ: <strong>{pkg.logisticService}</strong>
                                </span>
                              )}
                            </p>
                           <p>
                              <span className="text-uppercase">
                                Nước đến: <strong>{pkg.receiverCountry}</strong>
                              </span>
                           </p>
                            <p>
                              <span className="text-uppercase">Ngày giờ: </span>
                              <strong>
                                {this.getDateInfo(pkg)}
                              </strong>
                            </p>
                            {pkg.logisticCode && (
                                <span className="text-uppercase">
                                  TRACKING: <strong>{pkg.logisticCode}</strong>
                                </span>
                            )}
                          </td>
                          <td className="align-middle text-left">
                            <p>Số KG thu khách: <strong>{this.getBillKg(pkg.billup)}</strong> </p>
                            <p>SỐ KG RW: <strong>
                              {new Intl.NumberFormat().format(pkg.paymentNum) ||
                              0}
                            </strong></p>
                            {pkg.packageType !== DOC_TYPE && (
                                <div>
                                  <p>
                                    <span className="text-uppercase">
                                      Số kiện: </span>
                                    {this.totalNum(pkg)}
                                  </p>
                                  {/*<span className="text-uppercase"> Kiện hàng weight:
                                  </span>{' '}
                                  {this.totalWeight(pkg)}*/}
                                </div>
                            )}
                          </td>
                          <td className="align-middle text-left">
                            <p>Số tiền thu khách: <strong>{this.formatNumber(this.getTienThu(pkg))}</strong></p>
                            <p>Số tiền thu: <strong>{new Intl.NumberFormat().format(pkg.paymentCash) || 0}</strong></p>
                            <p>Tồn nợ: <strong>{this.formatNumber(this.getTongNo(pkg))}</strong></p>
                            <p>Phụ thu xuất: <strong>{this.formatNumber(pkg.phuthuPrice || 0)}</strong></p>

                          </td>
                          <td className="align-middle text-left">
                            <p>Chi phí phát sinh: <strong>{new Intl.NumberFormat().format(pkg.paymentBanking) || 0}</strong></p>
                            <p>Chi phí thùng: <strong>{this.formatNumber(this.getChiPhiThung(pkg))}</strong></p>
                            {
                                this.state.currUser.role !== USER_ROLE.CONSULTANT && (
                                    <p>Chi phí qua đối tác: <strong>{new Intl.NumberFormat().format(pkg.reportName) || 0}</strong></p>
                                )
                            }
                          </td>
                          {
                              this.state.currUser.role !== USER_ROLE.CONSULTANT && (
                                  <td className="align-middle">
                                    <span>{this.formatNumber(this.calLoiNhuan(pkg))}</span>
                                  </td>
                              )
                          }

                          <td className="align-middle">
                            <span>{this.formatNumber(pkg.billup?.bhPrice || 0)}</span>
                          </td>
                          <td className="align-middle">
                            <span>{pkg.owner || pkg.billup?.owner }</span>
                          </td>
                          <td className="align-middle">
                            <span>{pkg.note}</span>
                          </td>
                          <td className="align-middle">
                            <strong>{pkg.billup?.statusPayment}</strong>
                          </td>
                          <td className="align-middle" style={{whiteSpace: 'nowrap'}}>
                            {this.state.currUser.role !== USER_ROLE.SALER && (
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-warning" style={{marginBottom: '5px', marginRight: '5px'}}
                                  onClick={() => this.onUpdateDashboard(pkg, i)}
                                >
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-warning" style={{marginBottom: '5px'}}
                                  onClick={() => this.onDeletePackage(pkg, i)}
                                >
                                  Xóa
                                </button>
                              </div>
                            )}

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </CardBody>
              {data.list.length == 0 && (
                <div className="text-center m-2 pb-3">
                  Danh sách trống. Vui lòng tạo hóa đơn.
                </div>
              )}
            </Card>
          </Col>
        </Row>

        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={data.page}
          itemsCountPerPage={data.size}
          totalItemsCount={data.total}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
          hideFirstLastPages={true}
        />
      </>
    )
  }
}
export default Report
