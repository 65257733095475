import React from 'react'
import axios from 'axios'
import {
  Button, Card,
  CardBody,
  CardFooter,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, Label,
  Pagination,
  PaginationItem, PaginationLink, Row,
  Table
} from "reactstrap";
import {E_PACKET, USPS_STATE_40, USPS_STATE_110, USPS_POSTCODE_40, USER_ROLE} from "../../constants/index"
import moment from "moment";
import fileDownload from "js-file-download";
import XLSX from "xlsx";
import {confirmAlert} from "react-confirm-alert";
import Select from "react-select";
import {format} from "date-fns";
import {vi} from "date-fns/locale";
import {DateRangePickerCalendar, START_DATE} from "react-nice-dates";
import jwt from "jsonwebtoken";

class USPS extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 50,
        from: 0
      },
      arrKey: Object.keys(E_PACKET),
      discountList: [],
      loading: false,
      kgCode: '',
      date: '',
      filterDateType: '',
      filterOptionsType: '',
      searchValue: '',
      filterDateTypeValidation: false,
      filterOptionsTypeValidation: false,
      items: [],
      disableDownloadButton: false,
      isShowCalendar: false,
      startDate: new Date(),
      endDate: new Date(),
      focus: START_DATE,
      modalToggleUpdate: false,
      isShowStatusNone: true,
      pkg: {},
      index: null,
      currUser: {},
    }
    this.deleteUSPS = this.deleteUSPS.bind(this)
    this.onUpdateSearchValue = this.onUpdateSearchValue.bind(this)
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({currUser})
    await this.getAllUsps()
    await this.getDiscountUsers()
  }

  async getDiscountUsers() {
    const {data} = await axios.get('/users-discount')
    console.log(data)
    if (data.length) {
      this.setState({discountList: data})
    }
  }

  poundToKiloGrams(lbs) {
    return Math.round(0.4536 * lbs * 100) / 100
  }

  getAWB(url_string) {
    var url = new URL(url_string);
    var c = url.searchParams.get("trackingNumber");
    return c
  }

  getAWBLink(url_string) {
    var url = new URL(url_string);
    var c = url.searchParams.get("trackingNumber");
    return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${c}`
  }

  getShiperCom(obj) {
    const json_data = JSON.parse(obj.json_data)
    return json_data.Sender.Company
  }

  getEmailSender(obj) {
    const json_data = JSON.parse(obj.json_data)
    return json_data.Sender.Email
  }

  getDiscount(obj) {
    const json_data = JSON.parse(obj.json_data)
    const {discountList = []} = this.state
    const email = json_data.Sender.Email
    if (email && discountList && discountList.length) {
      const val = discountList.find(e => email == e.email)
      return val ? val.discount || 0 : 0
    }
    return 0
  }

  generateMoney(obj) {
    try {
      const weight = parseFloat(obj.weight || 0)
      const kgGrams = this.poundToKiloGrams(weight)
      const objectKey = this.getClosest(this.state.arrKey, kgGrams)
      const moneyByKg = E_PACKET[objectKey]
      const json_data = JSON.parse(obj.json_data)
      const packageCount = json_data.PackageCount || 1
      // Phụ phí
      let chargeValue = 0
      const pNum = parseInt(obj.postcode.slice(0, 3))

      if ((USPS_STATE_40.indexOf(obj.state) !== -1) || (USPS_POSTCODE_40.indexOf(pNum) !== -1)) {
        chargeValue = 40 * 23500 * packageCount
      }

      if (USPS_STATE_110.indexOf(obj.state) !== -1) {
        chargeValue = 110 * 23500 * packageCount
      }
      const total = parseInt(moneyByKg) + parseInt(chargeValue)
      const discount = parseFloat(this.getDiscount(obj))
      if (!isNaN(discount) && discount && discount > 0 && discount < 100) {
        return new Intl.NumberFormat().format(Math.round(total * (100 - discount) / 100))
      }
      return new Intl.NumberFormat().format(Math.round(total))
    } catch (e) {
      return '__'
    }
  }

  async deleteUSPS(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this USPS?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const res = await axios.get(`/delete-usps/${id}`)
              console.log(res)
              await self.getAllUsps()
            },
          },
          {
            label: "No",
            onClick: () => {
            },
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  getClosest(arr, goal) {
    return arr.reduce(function (prev, curr) {
      return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
    });
  }

  async exportData() {
    const {list, currUser} = this.state
    let self = this;
    let data
    if (currUser.role !== USER_ROLE.CUSTOMER && currUser.role !== USER_ROLE.DOCUMENT) {
      data = list.map(i => {
        return {
          "Package Id": i.packageId,
          "Shipper Company": i.parse.Sender.Company || '',
          Email: i.parse.Sender.Email || '',
          Reef: i.customerOrderNumber,
          "USPS AWB": self.getAWB(i.url),
          Weight: i.weight,
          State: i.state,
          Postcode: i.postcode,
          Discount: self.getDiscount(i),
          "Money Auto": self.generateMoney(i),
          Date: moment(i.updatedAt).format('LLL')
        }
      })
    } else {
      data = list.map(i => {
        return {
          "Package Id": i.packageId,
          "Shipper Company": i.parse.Sender.Company || '',
          Email: i.parse.Sender.Email || '',
          Reef: i.customerOrderNumber,
          "USPS AWB": self.getAWB(i.url),
          Weight: i.weight,
          State: i.state,
          Postcode: i.postcode,
          Date: moment(i.updatedAt).format('LLL')
        }
      })
    }

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `DOWNLOAD_USPS_${new Date().toJSON().slice(0, 10)}.xlsx`);
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  onFilterChange = (e) => {
    const {value} = e
    this.setState({filterOptionsType: value})
  }

  setDate = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({focus})
  }

  setStartDate(startDate) {
    this.setState({startDate})
  }

  setEndDate(endDate) {
    this.setState({endDate})
  }

  onUpdateSearchValue(e) {
    this.setState({searchValue: e.target.value})
  }

  async filterData() {
    const {filterDateType, startDate, endDate, searchValue, filterOptionsType, isShowStatusNone} = this.state
    let params = {}
    params.startDate = startDate
    params.endDate = endDate
    if (isShowStatusNone) {
      params.isShowStatusNone = isShowStatusNone
    }
    let {name} = this.props.match.params
    if (searchValue && filterOptionsType) {
      params[filterOptionsType] = searchValue
    }
    console.log(params)
    await this.getAllUsps(params)
  }

  async getAllUsps(params = {}) {
    try {
      const {currUser} = this.state
      if (currUser && currUser.role === USER_ROLE.CUSTOMER) {
        params = {...params, userId: currUser.id}
      }
      const res = await axios.get('/get-all-usps', {params})
      if (res && res.data) {
        const parseData = res.data.list.map(i => {
          return {
            ...i,
            parse: JSON.parse(i.json_data)
          }
        })
        this.setState({data: res.data, list: parseData})
      }
    } catch (e) {
      console.error(e)
    }
  }


  render() {
    const {data, list} = this.state
    const filterOptions = [
      {value: 'code', label: 'TP Bill'},
      {value: 'senderCompanyName', label: 'Người gửi / nhận'},
    ]
    const {
      startDate,
      endDate,
      focus,
      isShowCalendar,
      searchValue,
      filterOptionsType,
      currUser
    } = this.state
    return (
      <div>
        <Card className="bg-transparent">
          <CardHeader className="d-flex justify-content-between align-items-center">
            <p className="h5 mb-0">USPS </p>
            {list && list.length > 0 && (
              <Button
                onClick={() => this.exportData()}
              >
                Tải về Excel
              </Button>
            )}
          </CardHeader>
          <CardBody className="p-0">
            <Table responsive bordered hover className="mb-0 table-hover">
              <thead>
              <tr>
                <th>NO.</th>
                <th>Package Id</th>
                <th>Shipper Company</th>
                <th>Email Sender</th>
                <th>Reef</th>
                <th>USPS AWB</th>
                <th>Weight (lbs)</th>
                <th>State</th>
                <th>PostCode</th>
                {
                  (currUser.role !== USER_ROLE.CUSTOMER && currUser.role !== USER_ROLE.DOCUMENT) && (
                    <th>Bảng giá RW</th>
                  )
                }
                {
                  (currUser.role !== USER_ROLE.CUSTOMER && currUser.role !== USER_ROLE.DOCUMENT) && (
                    <th>Money Auto</th>
                  )
                }

                <th>Created Date</th>
                {
                  currUser.role === USER_ROLE.ADMIN && (
                    <th>Action</th>
                  )
                }
              </tr>
              </thead>
              <tbody>
              {list?.length?
              list.map((obj, i) => (
                <tr key={i + 1}>
                  <td>{i + 1}</td>
                  <td>{obj.packageId}</td>
                  <td>{obj.parse.Sender.Company || ''}</td>
                  <td>{obj.parse.Sender.Email || ''}</td>
                  <td><a href={obj.url} target="_blank">{obj.customerOrderNumber}</a></td>
                  <td><a href={this.getAWBLink(obj.url)} target="_blank">{this.getAWB(obj.url)}</a></td>
                  <td>{obj.weight}</td>
                  <td>{obj.state}</td>
                  <td>{obj.postcode}</td>
                  {
                    (currUser.role !== USER_ROLE.CUSTOMER && currUser.role !== USER_ROLE.DOCUMENT) && (
                      <td>{this.getDiscount(obj)}</td>
                    )
                  }
                  {
                    (currUser.role !== USER_ROLE.CUSTOMER && currUser.role !== USER_ROLE.DOCUMENT) && (
                      <td>{this.generateMoney(obj)}</td>
                    )
                  }
                  <td>{moment(obj.updatedAt).format('LLL')}</td>
                  {
                    currUser.role === USER_ROLE.ADMIN && (
                      <td>
                        <button type="button" className="btn btn-default" aria-label="glyphicon-trash"
                                onClick={() => this.deleteUSPS(obj.id)}>
                          <span className="glyphicon glyphicon-trash" aria-hidden="true"></span>
                        </button>
                      </td>

                    )
                  }

                </tr>
              )): (
                <tr>
                  <td colSpan='100' className='text-center'>Danh sách trống.</td>
                </tr>
              )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default USPS
