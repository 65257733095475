import React, { useEffect } from 'react';
import { useQuill } from 'react-quilljs';

import 'quill/dist/quill.snow.css';

export default ({ value, onChange }) => {
  const { quill, quillRef } = useQuill();
  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  };
  const saveToServer = async (file) => {
    const body = new FormData();
    body.append('file', file);
    const res = await fetch('/upload', { method: 'POST', body });
    const jsonValue = await res.json();
    insertToEditor(`/kango_logos/${jsonValue.filename}`);
  };
  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };
  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(value);

      quill.on('text-change', () => {
        onChange && onChange(quill.root.innerHTML);
      });
      quill.getModule('toolbar').addHandler('image', selectLocalImage);
    }
  }, [quill]);

  return (
    <div style={{ width: '100%', height: 300 }}>
      <div ref={quillRef} />
    </div>
  );
};
