import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
  Table
} from "reactstrap";
import jwt from "jsonwebtoken";
import moment from 'moment';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {USER_ROLE} from "../../constants";
import ImageUploading from "react-images-uploading";

class PackingDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      packingDetail: {},
      modalEditStatusToggle: false,
      images: [],
      imagesEdit: [],
      imagesEdit2: [],
      statusArr: [ 'Chưa xử lý', 'Đang xử lý', 'Hoàn thành'],
      statusArrName: [ '', 'Nhận đơn', 'Hoàn thành'],
      currentStatus: 'Chưa nhận',
      imagesView: [],
      currUser: {}
    }
    this.openEditStatusModal = this.openEditStatusModal.bind(this)
    this.updatePackingStatus = this.updatePackingStatus.bind(this)
    this.onChange2 = this.onChange2.bind(this)

  }

  async componentDidMount() {
    let { packingId } = this.props.match.params;
    console.log(packingId)
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser });

    this.getPackingDetail(packingId)
  }
  getPackingDetail = async (packingId) => {
    try {
      const { data } = await axios.post(`/get-packing/${packingId}`)
      if (data) {
        this.setState({ packingDetail: data })
        let imagesEdit = data.images
        let imagesEdit2 = data.imagesPickup
        if (imagesEdit) {
          imagesEdit = imagesEdit.split(',')
          this.setState({ imagesEdit: imagesEdit })
        }
        if (imagesEdit2) {
          imagesEdit2 = imagesEdit2.split(',')
          this.setState({ imagesEdit2: imagesEdit2 })
        }
        // how to get index of statusArr by value

      }
    } catch (e) {
      console.log(e)
    }
  }
  openEditStatusModal(obj) {
    const { modalEditStatusToggle } = this.state
    this.setState({ modalEditStatusToggle: !modalEditStatusToggle } );
  }

  formatNumber(num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }

  isGiaoNhan() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  isAdmin() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  onChange2 = (imageList, addUpdateIndex) => {
    this.setImages(imageList);
  };
  setImages(imageList) {
    this.setState({images: imageList})
  }

  onImageEditRemove = (index) => {
    let { imagesEdit } = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }

  formatDate = (str) => {
    return moment(str).format('DD/MM/YYYY HH:mm')
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  getPackingText(status) {
    let { statusArr, statusArrName } = this.state
    let index = statusArr.indexOf(status)
    return statusArrName[index+1]
  }

  updatePackingStatus = async (e) => {
    e.preventDefault()
    if (this.state.currUser?.role !== USER_ROLE.SHIPPER && this.state.currUser?.role !== USER_ROLE.ADMIN) {
      toast.error('Bạn không có quyền cập nhật trạng thái')
      return
    }
    const { packingDetail, images, imagesEdit } = this.state
    if (packingDetail.nvgn && packingDetail.nvgn !== this.state.currUser.displayName) {
      toast.error('Đơn hàng đã có NV Giao nhận khác nhận rồi, bạn không thể cập nhật trạng thái')
      return
    }

    if (packingDetail.statusType !== 'Chưa xử lý') {
      if (images && !images.length) {
        toast.error('Vui lòng chọn hình ảnh')
        console.log(packingDetail.statusType)
        return
      }
    }

    let imageLists = []
    let obj = {}
    if (packingDetail.statusType !== 'Hoàn thành') {
      obj = {...obj, statusType: this.state.statusArr[this.state.statusArr.indexOf(packingDetail.statusType) + 1]}
    } else {
      obj = {...obj, statusType: 'Hoàn thành'}
    }
    if (images && images.length) {
      const formData = new FormData();
      images.forEach(i => {
        formData.append("photos", i.file);
      })

      const {data = []} = await axios({
        method: "post",
        url: "/upload-pickup",
        data: formData,
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
      });
      imageLists = data
      if (packingDetail.imagesObj) {
        obj.imagesObj = JSON.parse(packingDetail.imagesObj)
      }
      obj.imagesObj = obj.imagesObj ? {...obj.imagesObj, ...{ [obj.statusType]: {images: data, date: new Date()} } } :
        {[obj.statusType]: {images: data, date: new Date()}}
      obj.imagesObj = JSON.stringify(obj.imagesObj)
    }
    if (imagesEdit && imagesEdit.length) {
      imageLists = [...imageLists, ...imagesEdit]
      imageLists = [...new Set(imageLists)]
    }

    if (imageLists.length) {
      obj = {...obj, images: imageLists.join(',')}
    }
    if (this.state.currUser?.role === USER_ROLE.SHIPPER) {
      obj = {...obj, nvgn: this.state.currUser.displayName}
    }
    if ((obj.statusType !== 'Đang xử lý') &&  !imageLists.length) {
      toast.error('Vui lòng chọn hình ảnh')
      return
    }
    try {
      // eslint-disable-next-line no-use-before-define
      const { data } = await axios.post(`/update-packing/${packingDetail.id}`, obj)
      if (data) {
        await this.getPackingDetail(packingDetail.id)
        this.setState({ modalEditStatusToggle: false, images: [] })
        toast.success('Cập nhật trạng thái thành công')
      }
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    let { packingId } = this.props.match.params;
    const { packingDetail } = this.state

    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active>Packing Detail</BreadcrumbItem>
        </Breadcrumb>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Thông tin Packing: PK{packingId}</span>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <ListGroup>
                  <ListGroupItem><strong>Mã packing:</strong> PK{packingDetail.id} </ListGroupItem>
                  <ListGroupItem>
                    <strong>Khách hàng</strong>
                    <div>
                      <p><strong>Tên:</strong> {packingDetail.customerName}</p>
                      <p><strong>Địa chỉ:</strong> {packingDetail.customerAddress}</p>
                      <p><strong>Điện thoại:</strong> {packingDetail.customerPhone}</p>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem>
                    <div className="d-flex justify-content-start align-items-center">
                      <strong className='mr-3'>Trạng Thái Đơn Hàng:</strong> {packingDetail.statusType}
                      <strong>
                        {packingDetail.statusType !== 'Hoàn thành' && (this.isGiaoNhan() || this.isAdmin()) && (
                          <Button className='ml-3 btn btn-primary' onClick={(e) => this.openEditStatusModal(packingDetail)} >
                            {this.getPackingText(packingDetail.statusType)}</Button>
                        )}
                      </strong>

                    </div>
                  </ListGroupItem>
                  <ListGroupItem><strong>Mã KH:</strong> KHTP{packingDetail.customerId}</ListGroupItem>
                  <ListGroupItem><strong>Account Tạo:</strong> {packingDetail.owner} </ListGroupItem>
                  <ListGroupItem><strong>Ngày Tạo</strong>: {this.formatDate(packingDetail.createdAt)}</ListGroupItem>

                </ListGroup>
              </Col>
              <Col md="6">
                <ListGroupItem><strong className='text-uppercase mb-2'>Thông tin hàng:</strong>
                  <div>
                    <p><strong>Số kiện:</strong> {packingDetail.packs}</p>
                    <p><strong>Weight:</strong> {packingDetail.weight}</p>
                    <p><strong>Nhóm hàng:</strong> {packingDetail.group}</p>
                    <p><strong>Dịch vụ:</strong> {packingDetail.service}</p>
                    <p><strong>Thông tin hàng:</strong> {packingDetail.info}</p>
                    <p><strong>NVĐG:</strong> {packingDetail.nvgn}</p>
                  </div>
                </ListGroupItem>
                <ListGroupItem><strong>Nội dung hàng hóa</strong>: {packingDetail.info}</ListGroupItem>
                <ListGroupItem>
                  <Label for="price">Hình ảnh Pickup</Label>
                  {
                    (this.state.imagesEdit2 && this.state.imagesEdit2.length) ? (
                      <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                        {this.state.imagesEdit2.map((image, index) => (
                          <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                            <a href={`/pickup/${image}`} target="_blank"> <img src={`/pickup/${image}`} alt="" width="100"/></a>

                          </div>
                        ))}
                      </div>
                    ) : ''
                  }

                  <Label className='mt-4 d-block' for="price">Hình ảnh Đóng gói</Label>

                  {
                    (this.state.imagesEdit && this.state.imagesEdit.length) ? (
                      <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                        {this.state.imagesEdit.map((image, index) => (
                          <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                            <a href={`/pickup/${image}`} target="_blank"> <img src={`/pickup/${image}`} alt="" width="100"/></a>
                          </div>
                        ))}
                      </div>
                    ) : ''
                  }
                </ListGroupItem>
              </Col>
            </Row>

          </CardBody>


          <Modal isOpen={this.state.modalEditStatusToggle} toggle={this.openEditStatusModal} >
            <Form>
              <ModalHeader toggle={this.openEditStatusModal}>Cập nhật trạng thái đóng gói</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="statusType">Trạng thái: { packingDetail.statusType } -->
                    &nbsp;{  packingDetail.statusType !== 'Hoàn thành' ? this.state.statusArr[this.state.statusArr.indexOf(packingDetail.statusType) + 1] : '' }</Label>
                </FormGroup>
                {
                  (packingDetail.statusType !== 'Hoàn thành' &&  packingDetail.statusType !== 'Chưa xử lý') ? (
                    <FormGroup>
                      <Label for="price">Hình ảnh *</Label>
                      {
                        (this.state.imagesEdit && this.state.imagesEdit.length) ? (
                          <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                            {this.state.imagesEdit.map((image, index) => (
                              <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                                <img src={`/pickup/${image}`} alt="" width="100" />
                                <div className="image-item__btn-wrapper" style={{marginTop: '8px', position: 'absolute', right: 0,
                                  top: 0}}>
                                  <a onClick={() => this.onImageEditRemove(index)}>
                                    <img src="/close.png" alt="" width="10" />
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : ''
                      }

                      <ImageUploading
                        multiple
                        value={this.state.images}
                        onChange={this.onChange2}
                        maxNumber={12}
                        dataURLKey="data_url"
                      >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            <a className="btn btn-primary"
                               style={isDragging ? { color: 'red' } : undefined}
                               onClick={onImageUpload}
                               {...dragProps}
                            >
                              Chọn hình ảnh
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            {
                              (this.state.images && this.state.images.length) ? (
                                <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                              ) : ''
                            }
                            <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                              {this.state.images.map((image, index) => (
                                <div key={index} className="image-item" style={{marginTop: '10px'}}>
                                  <img src={image['data_url']} alt="" width="100" />
                                  <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                    <a onClick={() => onImageRemove(index)}>
                                      <img src="/close.png" alt="" width="10" />
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </FormGroup>
                  ) : ''
                }

              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.updatePackingStatus(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditStatusModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>


        </Card>
      </div>
    )
  }
}
export default PackingDetail
