import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody, CardFooter,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, PaginationItem, PaginationLink,
  Row,
  Table
} from "reactstrap";
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import {USER_ROLE} from "../../constants";
import ImageUploading from 'react-images-uploading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fileDownload from "js-file-download";
import Select from "react-select";
import { Country, City } from 'country-state-city'

class CustomerReceiver extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalToggle: false,
      modalEditToggle: false,
      modalToggleUpdateCustomerReceiver: false,
      modalCustomerReceiverInfo: {},
      currUser: {},
      createCustomerReceiver: {
        companyName: "",
        name: "",
        address: "",
        phone: "",
        note: "",
        customerType: "Khách lẻ"
      },
      editCustomerReceiver: {
        companyName: "",
        name: "",
        address: "",
        phone: "",
        note: "",
        customerType: ""
      },
      isEdit: false,
      loading: false,
      search: '',
      customerType: "",
      images: [],
      imagesEdit: [],
      searchType: "owner",
      disableDownloadButton: false,
      customerInfo: {}
    }
    this.onDeleteCustomerReceiver = this.onDeleteCustomerReceiver.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
    this.onEditCustomerReceiver = this.onEditCustomerReceiver.bind(this)
    this.onCreateCustomerReceiver = this.onCreateCustomerReceiver.bind(this);
    this.onEditCustomerReceiverChange = this.onEditCustomerReceiverChange.bind(this);
    this.toggleUpdateCustomerReceiver = this.toggleUpdateCustomerReceiver.bind(this);
    this.onCreateCustomerReceiverChange = this.onCreateCustomerReceiverChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.setImages = this.setImages.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onEditCountry = this.onEditCountry.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    console.log(currUser)
    await this.setState({currUser, createCustomerReceiver: {...this.state.createCustomerReceiver, owner: currUser.displayName}})
    // get customerId from route
    const customerId = this.props.match.params.customerId
    console.log(customerId)
    if (customerId) {
      await this.getCustomerById(customerId)
      await this.getAllCustomerReceiver()
    }
  }

  async getCustomerById(id) {
    try {
      const res = await axios.get(`/get-customer/${id}`)
      if (res && res.data) {
        this.setState({customerInfo: res.data})
      }
    } catch (e) {
      console.error(e)
    }
  }
  async onCreateCustomerReceiver(e) {
    try {
      const {createCustomerReceiver, images} = this.state
      let imageLists = []
      let inValid = '';
      if (!createCustomerReceiver.receiverName || !createCustomerReceiver.receiverPhone) {
        toast.error('Vui lòng nhập các trường bắt buộc')
        return
      }
      await axios.post('/create-customer-receiver', {
        ...createCustomerReceiver,
        customerId: this.props.match.params.customerId,
      })
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle, images: []}));
      await this.getAllCustomerReceiver()
      e.preventDefault();
    } catch (err) {
      let message = err.response.data.message || 'Đã có lỗi xảy ra!'
      toast.error(message);
    }
  }

  async onEditCustomerReceiver(e) {
    try {
      const {editCustomerReceiver} = this.state
      await axios.post(`/update-customer-receiver/${editCustomerReceiver.id}`, {
        ...editCustomerReceiver
      })
      this.setState((prevState) => ({modalEditToggle: !prevState.modalEditToggle}));
      await this.getAllCustomerReceiver()
      e.preventDefault();
    } catch (e) {

    }
  }

  toggle() {
    this.setState((prevState, props) => ({modalToggle: !prevState.modalToggle}));
  }

  openEditModal(obj) {
    let imagesEdit = obj.images
    if (imagesEdit) {
      imagesEdit = imagesEdit.split(',')
    }
    this.setState({editCustomerReceiver: obj, imagesEdit: imagesEdit})
    this.setState((prevState, props) => ({modalEditToggle: !prevState.modalEditToggle}));
  }

  toggleUpdateCustomerReceiver() {

  }

  onCreateCustomerReceiverChange(e) {
    console.log(e)
    const {name, value} = e.target;
    console.log(name, value)
    let createCustomerReceiverInfo = this.state.createCustomerReceiver

    createCustomerReceiverInfo[name] = value
    this.setState({createCustomerReceiver: createCustomerReceiverInfo})
  }
  onSelectCountry(value, action) {
    let createCustomerReceiverInfo = this.state.createCustomerReceiver
    createCustomerReceiverInfo.receiverCountry = value.value
    this.setState({createCustomerReceiver: createCustomerReceiverInfo})
  }
  onEditCountry(value, action) {
    let editCustomerReceiverInfo = this.state.editCustomerReceiver
    editCustomerReceiverInfo.receiverCountry = value.value
    this.setState({editCustomerReceiver: editCustomerReceiverInfo})
  }

  onEditCustomerReceiverChange(e) {
    const {name, value} = e.target;
    let editCustomerReceiverInfo = this.state.editCustomerReceiver
    editCustomerReceiverInfo[name] = value
    this.setState({editCustomerReceiver: editCustomerReceiverInfo})
  }

  formatNumber(num) {
    return num
  }

  async onDeleteCustomerReceiver(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this CustomerReceiver?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const res = await axios.get(`/delete-customer-receiver/${id}`)
              await self.getAllCustomerReceiver()
            },
          },
          {
            label: "No",
            onClick: () => {
            },
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  searchBy(val) {
    this.setState({customerType: val})
  }
  searchByCode(val) {
    this.setState({searchType: val})
  }

  async getAllCustomerReceiver(params = {}) {
    try {
      const customerId = this.props.match.params.customerId
      this.setState({loading: true})
      const {search = '', searchType = 'owner', data} = this.state
      params.page = params.page  || data?.page
      let url = `/get-all-customer-receiver?customerId=${customerId}&page=${params.page || 1}&search=${search}&searchType=${searchType}`
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      if (this.state.customerType) {
        url += `&customerType=${this.state.customerType}`
      }
      const res = await axios.get(url)
      if (res && res.data) {
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }


  async exportData(params = {}) {
    await this.setState({disableDownloadButton: true})
    const {search = '', searchType = 'owner', data} = this.state
    params.page = params.page  || data?.page
    let url = `/download-customer?page=${params.page || 1}&limit=1000&search=${search}&searchType=${searchType}`
    if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
      url += `&owner=${this.state.currUser.displayName}`
    }
    if (this.state.customerType) {
      url += `&customerType=${this.state.customerType}`
    }

    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `CUSTOMER_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({disableDownloadButton: false})
      })
      .catch(() => {
        this.setState({disableDownloadButton: false})
      })
    return
  }


  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllCustomerReceiver({page: pageNumber})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER | role === USER_ROLE.MANAGER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.MANAGER || role === USER_ROLE.CUSTOMER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  handleInput = (e) => {
    const {value} = e.target
    this.setState({search: value})
  }
  onSearch = (e) => {
    this.getAllCustomerReceiver({search: this.state.search})
  }

  onChange2 = (imageList, addUpdateIndex) => {
    // data for submit
    this.setImages(imageList);
  };

  setImages(imageList) {
    this.setState({images: imageList})
  }

  onImageEditRemove = (index) => {
    let { imagesEdit } = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }


  render() {
    const {data, editCustomerReceiver} = this.state
    const customerType = {
      DIV: "Khách lẻ",
      ODD: "Khách sĩ",
    }

    const CountriesOptions = Country.getAllCountries().map((country) => ({
      value: country.name + ' - ' + country.isoCode,
      label: country.name + ' - ' + country.isoCode,
    }))
    const CitiesOptions = this.state.createCustomerReceiver.receiverCountry
      ? City.getCitiesOfCountry(
        this.state.createCustomerReceiver.receiverCountry.split(' - ')[1],
      ).map((city) => ({ value: city.name, label: city.name }))
      : []

    const maxNumber = 12;
    return (
      <div>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active> Danh sách người nhận </BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col sm={6}>
            <div style={{marginBottom: '10px'}}>
              <Label style={{marginRight: '8px'}}>Tìm kiếm theo:</Label>
              <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                      onChange={(e) => this.searchByCode(e.target.value)}
              >
                <option value="owner">Tên người tạo</option>
                {/*<option value="khCode">Mã KH</option>*/}
                <option value="phone">Số điện thoại</option>
                <option value="khName">Tên Người nhận</option>
              </select>
            </div>
          </Col>
          <Col sm={6} className='ml-auto'>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                this.onSearch()
              }}
            >
              <div className="d-flex">
                <InputGroup>
                  <Input
                    id="kgInput"
                    name="code"
                    value={this.state.search}
                    placeholder='Search'
                    onChange={this.handleInput}
                  />
                  <Button type="submit" className="btn-search ml-2">Search</Button>
                </InputGroup>
              </div>
            </form>
          </Col>
        </Row>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Danh sách Người nhận - {this.state.customerInfo?.name} - KHTP{this.state.customerInfo.id}</span>
            {
              this.checkPermission2({}, true) && (
                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                  {/*{data.list.length > 0 && (*/}
                  {/*  <Button*/}
                  {/*    disabled={this.state.disableDownloadButton}*/}
                  {/*    onClick={() => this.exportData()}*/}
                  {/*  >*/}
                  {/*    Tải về Excel*/}
                  {/*  </Button>*/}
                  {/*)}*/}
                  {this.checkPermission() && (
                    <Button type="button" className="butt ml-auto" onClick={this.toggle}>+ Tạo mới</Button>
                  )}
                </div>
              )
            }
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>Mã Người nhận</th>
                <th>Tên</th>
                <th>CompanyName</th>
                <th>Số điện thoại</th>
                <th>Người tạo</th>
                <th>Note</th>
                {this.checkPermission() && (
                  <th>Action</th>
                )}

              </tr>
              </thead>
              <tbody>
              {data.list &&
              data.list.map((obj, i) => (
                <tr key={i + 1}>
                  <td>{obj.id}</td>
                  <td>{obj.receiverName}</td>
                  <td>{obj.receiverCompanyName}</td>
                  <td>{obj.receiverPhone}</td>
                  <td>{obj.owner}</td>
                  <td>{obj.note || ''}</td>
                  {this.checkPermission2() && (
                    <td>
                      <Button style={{marginRight: '5px', marginBottom: '5px'}} type="button" color="info"
                              onClick={(e) => this.openEditModal(obj)}>Edit</Button>
                      {this.state.currUser.role !== USER_ROLE.CUSTOMER && (
                        <Button style={{marginBottom: '5px'}} type="button" color="danger"
                                onClick={(e) => this.onDeleteCustomerReceiver(obj.id)}>Delete</Button>

                      )}
                    </td>
                  )}

                </tr>
              ))}
              </tbody>
            </Table>
          </CardBody>

          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={data.page}
            itemsCountPerPage={data.size}
            totalItemsCount={data.total}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            hideFirstLastPages={true}
          />

        </Card>
        <Row>
          {/* Toggle Form Create CustomerReceiver */}
          <Modal isOpen={this.state.modalToggle} toggle={this.toggle}>
            <Form>
              <ModalHeader toggle={this.toggle}>Thêm Người nhận</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="content">Company Name</Label>
                  <Input type="text" name="receiverCompanyName" id="receiverCompanyName" placeholder=""
                         onChange={this.onCreateCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="content">Người Liên Hệ (Contact Name)</Label>
                  <Input type="text" name="receiverName" id="receiverName" placeholder=""
                         onChange={this.onCreateCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Số Điện Thoại (Telephone)</Label>
                  <Input name="receiverPhone" id="receiverPhone" placeholder="phone" onChange={this.onCreateCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Quốc gia (Country)</Label>
                  <Select
                    id="receiverCountry"
                    name="receiverCountry"
                    options={CountriesOptions}
                    // onChange={this.onCreateCustomerReceiverChange}
                    onChange={(value, action) =>
                      this.onSelectCountry(value, action)
                    }
                  />
                  {/*<Input name="receiverCountry" id="receiverCountry" placeholder="" onChange={this.onCreateCustomerReceiverChange}/>*/}
                </FormGroup>
                <FormGroup>
                  <Label for="content">Thành phố (City)</Label>
                  {/*<Input type="text" name="receiverCity" id="receiverCity" placeholder=""*/}
                  {/*       onChange={this.onCreateCustomerReceiverChange}/>*/}
                  {this.state.createCustomerReceiver.receiverCountry?.split(
                    " - "
                  )[1]}
                  <Input
                    type="text"
                    className="form-control flex-fill"
                    name="receiverCity"
                    placeholder="Thành phố (City)"
                    value={this.state.createCustomerReceiver.receiverCity}
                    onChange={(e) => this.onCreateCustomerReceiverChange(e)}
                    list="cityList"
                  />
                  <datalist id="cityList">
                    {this.state.createCustomerReceiver.receiverCountry?.split(" - ") &&
                      City.getCitiesOfCountry(
                        this.state.createCustomerReceiver.receiverCountry?.split(
                          " - "
                        )[1]?.trim()
                      ).map((city) => <option>{city.name}</option>)}
                  </datalist>

                </FormGroup>
                <FormGroup>
                  <Label for="receiverProvince">
                    Tỉnh (State/Province)
                  </Label>
                  <Input
                    id="receiverProvince"
                    name="receiverProvince"
                    type="text"
                    onChange={(e) => this.onCreateCustomerReceiverChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="price">Mã Bưu chính (Postal code)</Label>
                  <Input name="receiverPostalCode" id="receiverPostalCode" placeholder="PostCode" onChange={this.onCreateCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Địa chỉ (address) 1 <small>KHÔNG NHẬP POSTCODE, STATE, CITY</small></Label>
                  <Input name="receiverAddr1" id="receiverAddr1" placeholder="Address" onChange={this.onCreateCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Note</Label>
                  <Input name="note" id="note" placeholder="note" onChange={this.onCreateCustomerReceiverChange}/>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onCreateCustomerReceiver(e)}>Create</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/* Toggle Form Edit CustomerReceiver */}
          <Modal isOpen={this.state.modalEditToggle} toggle={this.openEditModal}>
            <Form>
              <ModalHeader toggle={this.openEditModal}>Edit Người nhận</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="content">Company Name</Label>
                  <Input type="text" name="receiverCompanyName" value={editCustomerReceiver.receiverCompanyName} id="receiverCompanyName" placeholder=""
                         onChange={this.onEditCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="content">Người Liên Hệ (Contact Name)</Label>
                  <Input type="text" name="receiverName" id="receiverName" value={editCustomerReceiver.receiverName} placeholder=""
                         onChange={this.onEditCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Số Điện Thoại (Telephone)</Label>
                  <Input name="receiverPhone" id="receiverPhone" value={editCustomerReceiver.receiverPhone}  placeholder="phone" onChange={this.onEditCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Quốc gia (Country)</Label>
                  <Select
                    id="receiverCountry"
                    name="receiverCountry"
                    value={CountriesOptions.find(
                      (o) =>
                        o.value === editCustomerReceiver.receiverCountry,
                    )}
                    options={CountriesOptions}
                    onChange={(value, action) =>
                      this.onEditCountry(value, action)
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="content">Thành phố (City)</Label>
                  {/*<Input type="text" name="receiverCity" id="receiverCity" value={editCustomerReceiver.receiverCity}  placeholder=""*/}
                  {/*       onChange={this.onEditCustomerReceiverChange}/>*/}
                  <Input
                    type="text"
                    className="form-control flex-fill"
                    name="receiverCity"
                    placeholder="Thành phố (City)"
                    value={editCustomerReceiver.receiverCity}
                    onChange={(e) => this.onEditCustomerReceiverChange(e)}
                    list="cityList"
                  />
                  <datalist id="cityList">
                    {editCustomerReceiver.receiverCountry?.split(" - ") &&
                      City.getCitiesOfCountry(
                        editCustomerReceiver.receiverCountry?.split(
                          " - "
                        )[1]?.trim()
                      ).map((city) => <option>{city.name}</option>)}
                  </datalist>
                </FormGroup>
                <FormGroup>
                  <Label for="receiverProvince">
                    Tỉnh (State/Province)
                  </Label>
                  <Input
                    id="receiverProvince"
                    name="receiverProvince"
                    type="text"
                    value={editCustomerReceiver.receiverProvince}
                    onChange={(e) => this.onEditCustomerReceiverChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="price">Mã Bưu chính (Postal code)</Label>
                  <Input name="receiverPostalCode" id="receiverPostalCode" value={editCustomerReceiver.receiverPostalCode} placeholder="PostCode" onChange={this.onEditCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Địa chỉ (address) 1 <small>KHÔNG NHẬP POSTCODE, STATE, CITY</small></Label>
                  <Input name="receiverAddr1" id="receiverAddr1"  value={editCustomerReceiver.receiverAddr1} placeholder="Address" onChange={this.onEditCustomerReceiverChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Note</Label>
                  <Input name="note" id="note" placeholder="note"  value={editCustomerReceiver.note} onChange={this.onEditCustomerReceiverChange}/>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditCustomerReceiver(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

        </Row>
      </div>
    )
  }
}

export default CustomerReceiver
