import React from 'react'
import axios from 'axios'
import {
  Button, Card,
  CardBody,
  CardFooter,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Pagination,
  PaginationItem, PaginationLink, Row,
  Table
} from "reactstrap";
import {
  E_PACKET,
  Debit_STATE_40,
  Debit_STATE_110,
  Debit_POSTCODE_40,
  USER_ROLE,
  PAGINATION_DEFAULT, USER_STATUS
} from "../../constants/index"
import moment from "moment";
import fileDownload from "js-file-download";
import XLSX from "xlsx";
import {confirmAlert} from "react-confirm-alert";
import Select from "react-select";
import {format} from "date-fns";
import {vi} from "date-fns/locale";
import {DateRangePickerCalendar, START_DATE} from "react-nice-dates";
import jwt from "jsonwebtoken";
import {createUser} from "../../actions/user";

class Debit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 50,
        from: 0
      },
      modalToggle: false,
      modalToggleUpdateDebit: false,
      modalDebitInfo: {},
      currUser: {},
      createDebit: {
        companyName: "",
        name: "",
        content: "",
        price: "",
        note: ""
      },

    }
    this.onDeleteDebit = this.onDeleteDebit.bind(this)
    this.onCreateDebit = this.onCreateDebit.bind(this);
    this.toggleUpdateDebit = this.toggleUpdateDebit.bind(this);
    this.onCreateDebitChange = this.onCreateDebitChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({ currUser, createDebit: {...this.state.createDebit, companyName: currUser.companyName} })
    await this.getAllDebit()
  }

  async onCreateDebit(e) {
    const { createDebit } = this.state
    await axios.post('/create-debit', createDebit)
    this.setState((prevState) => ({ modalToggle: !prevState.modalToggle }));
    await this.getAllDebit()
    e.preventDefault();
  }
  toggle() {
    this.setState((prevState, props) => ({ modalToggle: !prevState.modalToggle }));
  }
  toggleUpdateDebit() {

  }
  onCreateDebitChange(e) {
    const { name, value } = e.target;
    let createDebitInfo = this.state.createDebit
    createDebitInfo[name] = value
    this.setState({ createDebit: createDebitInfo })
  }
  formatNumber (num) {
    return num
 }
  async onDeleteDebit(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this Debit?",
        buttons: [
          {
            label: "Yes",
            onClick: async() => {
              const res = await axios.get(`/delete-debit/${id}`)
              console.log(res)
              await self.getAllDebit()
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  onSelectChange = (e) => {
    const { value } = e
    this.setState({ filterDateType: value })
  }


  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({ focus })
  }


  async getAllDebit(params = {}) {
    try {
      const res = await axios.get('/get-all-debit', )
      if (res && res.data) {
        console.log(res.data)
        this.setState({data: res.data})
      }
    } catch (e) {
      console.error(e)
    }
  }


  render() {
    const { data } = this.state
    console.log(data)
    return (
      <div>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between">
            <p className="h5">Debit </p>
            <Button type="button" className="butt" onClick={this.toggle}>+ Tạo mới</Button>
          </CardHeader>
          <CardBody>
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Tên</th>
                <th>Nội dung</th>
                <th>Số Debit</th>
                <th>Company Name</th>
                <th>Giá tiền</th>
                <th>Ngày tạo</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {data.list &&
              data.list.map((obj, i) => (
                <tr key={i + 1}>
                  <td>{i + 1}</td>
                  <td>{obj.name}</td>
                  <td>{obj.content}</td>
                  <td>KG{obj.id}</td>
                  <td>{obj.companyName}</td>
                  <td>{this.formatNumber(obj.price)}</td>
                  <td>{obj.createdAt}</td>
                  <td><Button type="button" color="danger" onClick={(e) => this.onDeleteDebit(obj.id)}>Delete</Button></td>
                </tr>
              ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <Row>
          {/* Toggle Form Create User */}
          <Modal isOpen={this.state.modalToggle} toggle={this.toggle} >
            <Form>
              <ModalHeader toggle={this.toggle}>Thêm Debit</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Tên Debit</Label>
                  <Input type="text" name="name" id="name" placeholder="Tên debit" onChange={this.onCreateDebitChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="content">Nội dung</Label>
                  <Input type="textarea" name="content" id="content" placeholder="content" onChange={this.onCreateDebitChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="price">Giá tiền</Label>
                  <Input name="price" id="price" placeholder="price" onChange={this.onCreateDebitChange} />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onCreateDebit(e)} >Create</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

        </Row>
      </div>
    )
  }
}
export default Debit
