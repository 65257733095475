import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
  ListGroup, ListGroupItem,
  Table
} from "reactstrap";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import PrintPacking from "./PrintPacking";
import ReactToPrint from "react-to-print";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select'
import {USER_ROLE} from "../../constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import s from "../packet/Packet.module.scss";
import ImageUploading from "react-images-uploading";
import ImageViewer from 'react-simple-image-viewer';
import {PhotoProvider, PhotoView} from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import fileDownload from "js-file-download";

let timerId = null;

class Packing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalPrintPackagesToggle: false,
      modalToggle: false,
      modalEditToggle: false,
      modalViewToggle: false,
      modalToggleUpdatePacking: false,
      modalPackingInfo: {},
      currUser: {},
      listCustomer: [],
      selectedCustomer: {
        name: '',
        khCode: '',
        phone: '',
        address: ''
      },
      createPacking: {
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerPhone: "",
        note: "",
        paymentType: 'Tiền Mặt',
        statusType: "Chưa xử lý",
        group: 'Hàng thường',
        localtion: 'HCM'
      },
      editPacking: {
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerPhone: "",
        note: "",
        localtion: 'HCM'
      },
      isEdit: false,
      printId: '',
      printData: '',
      loading: false,
      date: new Date(),
      search: '',
      searchBy: 'packingId',
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // get last 30 days
      toDate: new Date(),
      images: [],
      images2: [],
      imagesEdit: [],
      imagesEdit2: [],
      imagesPickup: [],
      viewPacking: null,
      imagesView: [],
      err: '',
      localtion: '',
      isMyPickup: false,
      statusArr: [ 'Chưa xử lý', 'Đang xử lý', 'Hoàn thành'],
      statusArrName: [ '', 'Nhận đơn', 'Hoàn thành'],
      currentStatus: 'Chưa nhận',
    }
    this.onDeletePacking = this.onDeletePacking.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
    this.openViewModal = this.openViewModal.bind(this)
    this.onEditPacking = this.onEditPacking.bind(this)
    this.onCreatePacking = this.onCreatePacking.bind(this);
    this.onEditPackingChange = this.onEditPackingChange.bind(this);
    this.toggleUpdatePacking = this.toggleUpdatePacking.bind(this);
    this.onCreatePackingChange = this.onCreatePackingChange.bind(this);
    this.onSelectedCustomer = this.onSelectedCustomer.bind(this);
    this.onSelectedEditCustomer = this.onSelectedEditCustomer.bind(this);
    this.onClickPrintPacking = this.onClickPrintPacking.bind(this);
    this.printPackagesToggle = this.printPackagesToggle.bind(this);
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.toggle = this.toggle.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleDatetoSelect2 = this.handleDatetoSelect2.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.onChange3 = this.onChange3.bind(this);
    this.setImages = this.setImages.bind(this);
    this.onMyPackingChange = this.onMyPackingChange.bind(this);
    this.updatePackingStatus = this.updatePackingStatus.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    // get pickupId from query string
    const urlParams = new URLSearchParams(window.location.search);
    const pickupId = urlParams.get('pickupId')
    if (pickupId) {
      await this.getPickupInfo(pickupId)
      this.toggle()
    }
    await this.setState({currUser, createPacking: {...this.state.createPacking, owner: currUser.displayName}})
    await this.getAllPacking()
    await this.getListCustomer()
  }

  getPickupInfo = async (pickupId) => {
    try {
      const res = await axios.get(`/pickup-by-id/${pickupId}`)
      if (res && res.data) {
        const {data} = res
        console.log('pickup data', data.images.split(','))
        this.setState({
          createPacking: {
            ...this.state.createPacking,
            customerName: data.customerName,
            customerId: data.customerId,
            customerAddress: data.customerAddress,
            customerPhone: data.customerPhone,
            service: data.service,
            group: data.group,
            localtion: data.localtion,
            pickupId: data.id,
            info: '',
            imagesPickup: this.filterImages(data.images.split(','), data.imagesObj),
            note: data.note,
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  filterImages(images, imagesObj) {
    if (!imagesObj) return images
    imagesObj = JSON.parse(imagesObj)
    // filter images width status Hoàn thành from imagesObj and imagesObj[key].images.includes(image)
    const data = images.filter(image => {
      for (const key in imagesObj) {
        if (key === 'Hoàn thành') {
          if (imagesObj[key].images.includes(image)) {
            return true
          }
        }
      }
    })
    console.log('filterImages', data, imagesObj)
    return data
  }

  onMyPackingChange(e) {
    this.setState({isMyPickup: e.target.checked}, () => {
      this.getAllPacking()
    })
  }

  async onCreatePacking(e) {
    e.preventDefault();
    try {
      if (this.state.loading) return
      const {createPacking, images, images2} = this.state
      let {imagesPickup} = createPacking
      let message = '';
      if (!createPacking.customerId) {
        message = 'chọn khách hàng'
      } else if (!createPacking.service) {
        message = 'chọn dịch vụ'
      } else if (!createPacking.group) {
        message = 'chọn nhóm hàng'
      }
      if (message) {
        toast.error('Vui lòng ' + message)
        return
      }
      this.setState({loading: true})
      let imageLists = []
      let imageLists2 = []
      if (images && images.length) {
        const formData = new FormData();
        images.forEach(i => {
          formData.append("photos", i.file);
        })
        const {data = []} = await axios({
          method: "post",
          url: "/upload-pickup",
          data: formData,
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
        });
        imageLists = data
      }
      if (images2 && images2.length) {
        const formData = new FormData();
        images.forEach(i => {
          formData.append("photos", i.file);
        })
        const {data = []} = await axios({
          method: "post",
          url: "/upload-pickup",
          data: formData,
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
        });
        imageLists2 = data
      }
      if (imagesPickup) {
        imagesPickup = [...imagesPickup, ...imageLists2]
      } else {
        imagesPickup = imageLists2
      }
      createPacking.packs = createPacking.packs || 0

      let {data: packingdata} = await axios.post('/create-packing', {
        ...createPacking,
        date: this.state.date,
        images: imageLists.join(','),
        imagesPickup: imagesPickup.join(',')
      })
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle, images: [], images2: []}));
      await this.getAllPacking()

      this.setState({loading: false})
      // remove route query string
      window.history.pushState({}, document.title, window.location.pathname);
    } catch (err) {
      console.log(err)
      toast.error('Đã có lỗi xảy ra ', err.error);
      this.setState({loading: false})
      return
    }
  }

  async onEditPacking(e) {
    try {
      const {editPacking, images, images2, imagesEdit, imagesEdit2} = this.state

      if ( editPacking.nvgn && editPacking.nvgn !== this.state.currUser.displayName && this.state.currUser.role === USER_ROLE.SHIPPER ) {
        toast.error('Đơn hàng đã có NV Giao nhận khác nhận rồi, bạn không thể cập nhật trạng thái')
        return
      }

      if (this.state.currUser.role === USER_ROLE.SHIPPER) {
        editPacking.nvgn = this.state.currUser.displayName
      }


      let imageLists = []
      let imageLists2 = []
      let message = '';

      if (!editPacking.customerId) {
        message = 'chọn khách hàng'
      } else if (!editPacking.service) {
        message = 'chọn dịch vụ'
      } else if (!editPacking.group) {
        message = 'chọn nhóm hàng'
      }
      if (message) {
        toast.error('Vui lòng ' + message)
        return
      }
      if (images && images.length) {
        const formData = new FormData();
        images.forEach(i => {
          formData.append("photos", i.file);
        })

        const {data = []} = await axios({
          method: "post",
          url: "/upload-pickup",
          data: formData,
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
        });
        imageLists = data
        if (editPacking.imagesObj) {
          editPacking.imagesObj = JSON.parse(editPacking.imagesObj)
        }
        editPacking.imagesObj = editPacking.imagesObj ? {...editPacking.imagesObj, ...{ [editPacking.statusType]: {images: data, date: new Date()} } } :
          {[editPacking.statusType]: {images: data, date: new Date()}}
        editPacking.imagesObj = JSON.stringify(editPacking.imagesObj)

      }
      if (images2 && images2.length) {
        const formData = new FormData();
        images2.forEach(i => {
          formData.append("photos", i.file);
        })

        const {data = []} = await axios({
          method: "post",
          url: "/upload-pickup",
          data: formData,
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
        });
        imageLists2 = data
      }


      if (imagesEdit && imagesEdit.length) {
        imageLists = [...imageLists, ...imagesEdit]
      }
      if (imagesEdit2 && imagesEdit2.length) {
        imageLists2 = [...imageLists2, ...imagesEdit2]
      }

      await axios.post(`/update-packing/${editPacking.id}`, {
        ...editPacking,
        date: this.state.date,
        images: imageLists.join(','),
        imagesPickup: imageLists2.join(',')
      })
      this.setState((prevState) => ({
        modalEditToggle: !prevState.modalEditToggle,
        images: [],
        images2: [],
        imagesEdit: [],
        imagesEdit2: [],
        err: ''
      }));
      await this.getAllPacking()
      e.preventDefault();
    } catch (err) {
      this.setState({err: err})
      toast.error('Đã có lỗi xảy ra');
      return
    }

  }

  openViewModal(obj) {
    let imagesView = obj.images
    if (imagesView) {
      imagesView = imagesView.split(',')
    }
    this.setState({viewPacking: obj, imagesView: imagesView})
    this.setState((prevState, props) => ({modalViewToggle: !prevState.modalViewToggle}));
  }

  toggle() {
    this.setState((prevState, props) => ({modalToggle: !prevState.modalToggle}));
  }

  openEditModal(obj) {
    let imagesEdit = obj.images
    let imagesEdit2 = obj.imagesPickup
    if (imagesEdit) {
      imagesEdit = imagesEdit.split(',')
    }
    if (imagesEdit2) {
      imagesEdit2 = imagesEdit2.split(',')
    }
    this.setState({
      editPacking: {...obj, imagesPickupArr: obj.imagesPickup?.split(',')},
      imagesEdit: imagesEdit,
      imagesEdit2: imagesEdit2,
      date: moment(obj.date).subtract(7, 'h')
    })
    this.setState((prevState, props) => ({modalEditToggle: !prevState.modalEditToggle}));
  }

  toggleUpdatePacking() {

  }

  onCreatePackingChange(e) {
    const {name, value} = e.target;
    let createPackingInfo = this.state.createPacking
    createPackingInfo[name] = value
    this.setState({createPacking: createPackingInfo})
  }

  onEditPackingChange(e) {
    const {name, value} = e.target;
    let editPackingInfo = this.state.editPacking
    editPackingInfo[name] = value
    this.setState({editPacking: editPackingInfo})
  }

  formatNumber(num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }

  handleInput = (e) => {
    const {value} = e.target
    this.setState({search: value})
  }

  searchBy(val) {
    console.log(val)
    this.setState({searchBy: val})
  }

  searchByLocaltion(val) {
    console.log(val)
    this.setState({localtion: val})
  }

  onSearch = (e) => {
    this.getAllPacking({search: this.state.search})
  }

  async onDeletePacking(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this Packing?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const res = await axios.get(`/delete-packing/${id}`)
              console.log(res)
              await self.getAllPacking()
            },
          },
          {
            label: "No",
            onClick: () => {
            },
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }
  onSelectedCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const {id} = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let createPackingInfo = this.state.createPacking
        createPackingInfo['customerName'] = temp.name
        createPackingInfo['customerId'] = temp.id
        createPackingInfo['customerAddress'] = temp.address
        createPackingInfo['customerPhone'] = temp.phone
        this.setState({selectedCustomer: temp, createPacking: createPackingInfo})

      }
    }
  }
  onSelectedEditCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const {id} = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let editPackingInfo = this.state.editPacking
        editPackingInfo['customerName'] = temp.name
        editPackingInfo['customerId'] = temp.id
        editPackingInfo['customerAddress'] = temp.address
        editPackingInfo['customerPhone'] = temp.phone
        this.setState({selectedCustomer: temp, editPacking: editPackingInfo})

      }
    }
  }

  handleInputChange(e) {
    const inputValue = e;
    // Clear any existing timer
    clearTimeout(timerId);
    // Set a new timer to delay the API call
    timerId = setTimeout(() => {
      console.log(inputValue);
      this.getListCustomer({search: inputValue});
    }, 500);
  }

  async getAllPacking(params = {}) {
    try {
      this.setState({loading: true})
      const {searchBy = 'packingId', search = '', isMyPickup, data} = this.state
      let res
      params.page = params.page || data?.page
      let url = `/get-all-packing?page=${params.page || 1}&search=${search}&searchBy=${searchBy}`
      if (this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.MANAGER || this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.OPS) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      url += `&fromDate=${this.state.fromDate}&toDate=${this.state.toDate}`
      if (this.state.localtion) {
        url += `&localtion=${this.state.localtion}`
      }
      if (isMyPickup) {
        url += `&nvgn=${this.state.currUser.displayName}`
      }
      if (this.state.currUser.role === USER_ROLE.MANAGER) {
        url += `&manager=${this.state.currUser.id}`
        url += `&owner_email=${this.state.currUser.email}`
      }
      res = await axios.get(url)
      if (res && res.data) {
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async getListCustomer(params = {search: ''}) {
    try {
      let url = `/get-all-customer?page=${params.page || 1}&size=10&search=${params.search}`
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT
        || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.CUSTOMER) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      const res = await axios.get(url,)
      if (res && res.data) {
        const list = res.data.list.map(i => {
          return {
            ...i,
            value: i.id,
            label: `KHTP${i.id} (${i.packingCount || 0})`
          }
        })
        this.setState({listCustomer: list || []})
      }
    } catch (e) {
      console.error(e)
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllPacking({page: pageNumber})
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }));
  }

  onClickPrintPacking(obj) {
    this.setState({printData: obj});
    this.printPackagesToggle();
  }

  handleDatetoSelect(date) {
    console.log(date)
    this.setState({date})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      const email = this.state.currUser.email
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.MANAGER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.ACCOUNTANT || email === 'customerservicetinphat@gmail.com') {
        return true
      }
      return false
    } catch (e) {
    }
  }

  isSale() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.SALER) {
        return true
      }
      return false
    } catch (e) {
    }
  }


  isGiaoNhan() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  isAdmin() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  updatePackingStatus = async (e) => {
    if (this.state.currUser?.role !== USER_ROLE.SHIPPER && this.state.currUser?.role !== USER_ROLE.ADMIN) {
      toast.error('Bạn không có quyền cập nhật trạng thái')
      return
    }
    const { editPacking } = this.state
    if (editPacking.nvgn && editPacking.nvgn !== this.state.currUser.displayName && this.state.currUser.role !== USER_ROLE.ADMIN) {
      toast.error('Đơn hàng đã có NV Giao nhận khác nhận rồi, bạn không thể cập nhật trạng thái')
      return
    }

    let obj = {
      nvgn: this.state.currUser.displayName,
    }
    if (editPacking.statusType !== 'Hoàn thành') {
      obj = {...obj, statusType: this.state.statusArr[this.state.statusArr.indexOf(editPacking.statusType) + 1]}
    } else {
      obj = {...obj, statusType: 'Hoàn thành'}
    }
    try {
      // eslint-disable-next-line no-use-before-define
      const { data } = await axios.post(`/update-packing/${editPacking.id}`, obj)
      if (data) {
        // set new status
        editPacking.statusType = obj.statusType
        this.setState({editPacking: editPacking})
      }
    } catch (e) {
      console.log(e)
    }
  }

  getPackingText(status) {
    let { statusArr, statusArrName } = this.state
    let index = statusArr.indexOf(status)
    return statusArrName[index+1]
  }

  checkPermission2(obj, isCreate) {
    try {
      const role = this.state.currUser.role
      const email = this.state.currUser.email
      const name = this.state.currUser.displayName
      if (!isCreate && obj && role === USER_ROLE.MANAGER && name === obj.owner) {
        return true
      }
      return role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.CONSULTANT || role === USER_ROLE.ACCOUNTANT || role === USER_ROLE.DOCUMENT || email === 'customerservicetinphat@gmail.com';
    } catch (e) {
    }
  }
  checkPermission3(obj, isCreate) {
      try {
        const role = this.state.currUser.role
        const email = this.state.currUser.email
        const name = this.state.currUser.displayName
        if (obj.nvgn === this.state.currUser.displayName || obj.owner === this.state.currUser.displayName) {
          return true
        }
        if (role === USER_ROLE.MANAGER && name === obj.owner) {
          return true
        }
        if (!obj.nvgn && role === USER_ROLE.SHIPPER) return true
        return role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.CONSULTANT || role === USER_ROLE.ACCOUNTANT || role === USER_ROLE.DOCUMENT || email === 'customerservicetinphat@gmail.com';
      } catch (e) {
      }
    }

  checkPermissionSale(obj) {
    try {
      const role = this.state.currUser.role
      const email = this.state.currUser.email
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.MANAGER || role === USER_ROLE.ACCOUNTANT || role === USER_ROLE.SHIPPER || role === USER_ROLE.DOCUMENT || email === 'customerservicetinphat@gmail.com') {
        return true
      }

      if (role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        const createdAtDate = moment(obj.createdAt)
        const yesterday = moment().subtract(1, 'day')
        if (createdAtDate > yesterday) return true
      }
      return false
    } catch (e) {
    }
  }

  isAccountantOrAdmin() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ACCOUNTANT || role === USER_ROLE.ADMIN) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  isNotSale() {
    try {
      const role = this.state.currUser.role
      if (role !== USER_ROLE.SALER) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  isGiaoNhan() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  formatDate = (str) => {
    return moment(str).format('DD/MM/YY HH:mm')
  };

  handleDatefromSelect(date) {
    this.setState({fromDate: date});
  }

  handleDatetoSelect2(date) {
    console.log(date)
    this.setState({toDate: date});
  }


  onChange2 = (imageList, addUpdateIndex) => {
    // data for submit
    this.setImages(imageList);
  };
  onChange3 = (imageList, addUpdateIndex) => {
    this.setImages2(imageList)
  };

  setImages(imageList) {
    this.setState({images: imageList})
  }

  setImages2(imageList) {
    this.setState({images2: imageList})
  }

  onImageEditRemove = (index) => {
    let {imagesEdit} = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }
  onImageEditRemove2 = (index) => {
    let {imagesEdit2} = this.state
    const filteredItems = imagesEdit2.filter(item => item !== imagesEdit2[index])
    this.setState({imagesEdit2: filteredItems})
  }

  async exportData() {
    await this.setState({disableDownloadButton: true})
    const {fromDate, toDate} = this.state
    let qs = `&datetype=createdAt&startDate=${fromDate}&endDate=${toDate}&searchBy=${this.state.searchBy}&search=${this.state.search}`
    if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
      qs += `&owner=${this.state.currUser.displayName}`
    }
    if (this.state.localtion) {
      qs += `&localtion=${this.state.localtion}`
    }
    if (this.state.currUser.role === USER_ROLE.MANAGER) {
      qs += `&manager=${this.state.currUser.id}`
      qs += `&owner_email=${this.state.currUser.email}`
    }

    axios
      .get(`/download-packing?${qs}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `BILLUP_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({disableDownloadButton: false})
      })
      .catch(() => {
        this.setState({disableDownloadButton: false})
      })
    return
  }

  showTPCode = (code) => {
    code = code?.toString()
    return code?.startsWith('92') ? code : 'DG' + code
  }

  render() {
    function ObjectRow(props) {
      const link = '/customers/' + props.obj
      return (
        <PhotoProvider key={props.key}>
          <PhotoView src={link}>
            <img src={link} alt=""/>
          </PhotoView>
        </PhotoProvider>
      );
    }

    const {data, editPacking, createPacking, viewPacking} = this.state
    const paymentTypeOption = {
      CASH: "Tiền mặt",
      BANK: "Chuyển khoản", BANK2: "Tiền mặt và Chuyển khoản",
    }
    const groupTypeOption = {
      CASH: "Hàng thường",
      BANK: "Hàng khó",
    }
    const statusTypeOption = {
      PROGRESS: "Chưa xử lý",
      PROGRESS2: "Đang xử lý",
      DONE: 'Hoàn thành'
    }
    const statusPaymentOption = {
      NOTYET: "Chưa thanh toán",
      PAID: 'Đã thanh toán',
      DEBT: 'Công nợ'
    }
    const localtionOption = {
      HCM: "HCM",
      HN: 'HN',
      ĐN: 'ĐN'
    }

    const printPacking = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 5mm;
        }  }
        `;
    };
    const maxNumber = 12;


    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active> Packing </BreadcrumbItem>
        </Breadcrumb>
        <div>
          <Row>
            <Col sm={6}>
              <div className="card-body">
                <Row>
                  <Col sm={6}>
                    <div style={{display: "flex", maxWidth: "200px"}}>
                      <InputGroup>
                        <label className='mr-sm-2' htmlFor='fromDate'>
                          From Date
                        </label>
                        <DatePicker
                          id='fromDate'
                          selected={this.state.fromDate}
                          onSelect={this.handleDatefromSelect}
                          className="form-control"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div style={{display: "flex", maxWidth: "200px"}}>
                      <InputGroup>
                        <label className='mr-sm-2' htmlFor='toDate'>
                          To Date
                        </label>
                        <DatePicker
                          id='toDate'
                          selected={this.state.toDate}
                          onSelect={this.handleDatetoSelect2}
                          className="form-control"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={6} className='ml-auto'>
              <>
                <CardBody>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      this.onSearch()
                    }}
                  >
                    <div style={{marginBottom: '10px'}}>
                      <Label style={{marginRight: '8px'}}>Search By: </Label>
                      <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchBy(e.target.value)}
                      >
                        <option value="packingId">Mã Đóng Gói</option>
                        <option value="owner">Người tạo</option>
                        <option value="khId">Mã khách hàng</option>
                        <option value="receiveName">Người nhận</option>
                      </select>
                    </div>
                    <div style={{marginBottom: '10px'}}>
                      <Label style={{marginRight: '8px'}}>Khu Vực: </Label>
                      <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchByLocaltion(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="HCM">HCM</option>
                        <option value="HN">HN</option>
                        <option value="ĐN">ĐN</option>
                      </select>
                    </div>
                    <InputGroup>
                      <Input
                        id="kgInput"
                        name="code"
                        value={this.state.search}
                        placeholder='Tìm kiếm ...'
                        onChange={this.handleInput}
                      />
                      <Button type="submit" className="btn-search ml-2">Search</Button>
                    </InputGroup>
                  </form>
                </CardBody>
              </>
            </Col>
          </Row>
        </div>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Danh sách Đóng gói</span>
            {
              this.isGiaoNhan() && (
                <Label check>
                  <Input
                    type='checkbox'
                    name='isMyPickup'
                    value={this.state.isMyPickup}
                    onChange={(e) => this.onMyPackingChange(e)}
                  />{' '}
                  Đơn hàng của tôi
                </Label>
              )
            }
            {
              this.checkPermission2({}, true) && (
                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                  {/*{data?.list?.length > 0 && (*/}
                  {/*  <Button*/}
                  {/*    disabled={this.state.disableDownloadButton}*/}
                  {/*    onClick={() => this.exportData()}*/}
                  {/*  >*/}
                  {/*    Tải về Excel*/}
                  {/*  </Button>*/}
                  {/*)}*/}
                  {this.checkPermission() && (
                    <Button type="button" className="butt " onClick={this.toggle}>+ Tạo thông tin đóng gói</Button>
                  )}
                </div>
              )
            }
          </CardHeader>

          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Mã Đóng Gói</th>
                <th>Code</th>
                {/*<th>Thông tin người gửi</th>*/}
                <th>Account Tạo</th>
                <th>Thông tin hàng</th>
                <th>Nội dung hàng hóa</th>
                <th>NVĐG</th>
                <th>Trạng thái</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {data.list &&
                data.list.map((obj, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    {/*<td>*/}
                    {/*  <a href="#" onClick={() => this.openViewModal(obj)}>*/}
                    {/*    <strong>{this.showTPCode(obj.id)}</strong>*/}
                    {/*  </a>*/}
                    {/*</td>*/}
                    <td><Link onClick={() => this.openEditModal(obj)}>DG{obj.id}</Link></td>
                    <td>
                      <div>Mã PK: <strong>{obj.pickupId}</strong></div>
                      <br/>
                      <div>Mã KH: <strong>KHTP{obj.customerId}</strong></div>
                    </td>
                    <td>
                      <div><strong>{obj.owner}</strong></div>
                      <div>{this.formatDate(obj.createdAt)}</div>
                    </td>
                    <td>
                      <div>
                        <p><strong>Số kiện:</strong> {obj.packs}</p>
                        <p><strong>Cân nặng:</strong> {obj.weight}kg</p>
                        <p><strong>Cân nặng quy đổi:</strong> {obj.weight2}kg</p>
                        <p><strong>Nhóm hàng:</strong> {obj.group || 'Hàng thường'}</p>
                        <p><strong>Dịch vụ:</strong> {obj.service}</p>
                        {
                          obj.note && (
                            <p><strong>Note:</strong> {obj.note}</p>
                          )
                        }
                      </div>
                    </td>
                    <td className="text-center">{obj.info}</td>
                    <td className="text-center">{obj.nvgn}</td>
                    <td className="text-center">
                      <button className={`text-nowrap btn-status ${obj.statusType ==='Hoàn thành' ? "btn-success" : "btn-warning"} ${
                        obj.statusType ==='Chưa xử lý' ? "btn-danger" : ""
                      }`}>{obj.statusType}</button>

                    </td>
                    {this.checkPermissionSale(obj) ? (
                      <td>
                        <div style={{minWidth: '100px'}}>
                          {!this.isGiaoNhan() && (
                            <Button type="button" color="info"
                                    onClick={(e) => this.onClickPrintPacking(obj)}><i className="fas fa-print"></i></Button>
                          )}
                          {
                            this.checkPermission3(obj) && (
                              <span>
                                <Button type="button" color="info"
                                        onClick={(e) => this.openEditModal(obj)}><i className="fas fa-pen"></i></Button>
                                {
                                  this.checkPermission2(obj) && (
                                    <Button type="button" color="danger"
                                            onClick={(e) => this.onDeletePacking(obj.id)}><i className="fas fa-trash"></i></Button>
                                  )
                                }
                              </span>
                            )
                          }
                          {
                            !this.isGiaoNhan() && !obj.Billup && (
                              <Button type="button" color="warning"><Link
                                style={{color: 'black'}} to={`/app/billup?packingId=${obj.id}`}><i className="fas fa-box"></i></Link></Button>
                            )
                          }
                        </div>
                      </td>
                    ) : (

                      <td>
                        {!this.isGiaoNhan() && (
                          <div>
                            <button className='btn-status' onClick={(e) => this.onClickPrintPacking(obj)}><i className="fas fa-print"></i></button>
                            {/*{*/}
                            {/*  !obj.Billup && (*/}
                            {/*    <Button type="button" color="warning"><Link*/}
                            {/*      style={{color: 'black'}} to={`/app/billup?packingId=${obj.id}`}><i className="fas fa-box"></i></Link></Button>*/}
                            {/*  )*/}
                            {/*}*/}
                          </div>
                        )}

                      </td>
                    )}


                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>

          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={data.page}
            itemsCountPerPage={data.size}
            totalItemsCount={data.total}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            hideFirstLastPages={true}
          />

        </Card>
        <Row>
          {/* Toggle Form Create Packing */}

          <Modal backdrop="static"
                 size="xl"
                 isOpen={this.state.modalToggle}
                 toggle={this.toggle}>
            <Form>
              <ModalHeader toggle={this.toggle}>Tạo thông tin đóng gói</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={6}>
                    {
                      !this.isGiaoNhan() && (
                        <FormGroup>
                          <Label for="eventNumber">Khách hàng (*)</Label>
                          <Select
                            options={this.state.listCustomer}
                            value={this.state.selectedCustomer[0]?.id}
                            onChange={this.onSelectedCustomer}
                            onInputChange={this.handleInputChange}
                            isSearchable
                            autosize={false}/>
                        </FormGroup>
                      )
                    }
                    <FormGroup>
                      <Label for="name">Mã khách hàng</Label>
                      <Input type="text" name="customerId" value={createPacking.customerId} id="customerId"
                             placeholder="Mã khách hàng" onChange={this.onCreatePackingChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Tên người gửi (*)</Label>
                      <Input type="text" name="customerName" value={createPacking.customerName} id="customerName"
                             placeholder="Tên người gửi" onChange={this.onCreatePackingChange}/>
                    </FormGroup>
                    {/*<FormGroup>*/}
                    {/*  <Label for="content">Địa chỉ người gửi (*)</Label>*/}
                    {/*  <Input type="text" name="customerAddress" value={createPacking.customerAddress} id="customerAddress"*/}
                    {/*         placeholder="address" onChange={this.onCreatePackingChange}/>*/}
                    {/*</FormGroup>*/}
                    {/*<FormGroup>*/}
                    {/*  <Label for="price">Số điện thoại người gửi (*)</Label>*/}
                    {/*  <Input name="customerPhone" id="customerPhone" value={createPacking.customerPhone} placeholder="phone"*/}
                    {/*         onChange={this.onCreatePackingChange}/>*/}
                    {/*</FormGroup>*/}
                    <FormGroup>
                      <Label for="service">Dịch vụ (*)</Label>
                      <Input type="text" name="service" id="service" placeholder="Dịch vụ"
                             value={createPacking.service}
                             onChange={this.onCreatePackingChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="information">Nhập mã pickup</Label>
                      <Input type="text" name="pickupId" id="pickupId" placeholder="Mã pickup"
                             value={createPacking.pickupId}
                             onChange={this.onCreatePackingChange}/>
                      {/*<Button onClick={() => this.getPickupInfo()}>Lấy thông tin Pickup</Button>*/}
                    </FormGroup>
                    <FormGroup>
                      <Label for="localtion">Khu vực</Label>
                      <Input id="localtion" name="localtion" type="select" onChange={this.onCreatePackingChange}
                             value={createPacking.localtion}>
                        >
                        {Object.values(localtionOption).map((object, i) => <option className="text-capitalize"
                                                                                   value={object}
                                                                                   key={i}>{object}</option>)}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="note">Ghi chú</Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        style={{height: '200px'}}
                        id="note"
                        placeholder="Ghi chú"
                        onChange={this.onCreatePackingChange}
                        name="note"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="price">Hình ảnh</Label>
                      {
                        (this.state.createPacking.imagesPickup && this.state.createPacking.imagesPickup.length) ? (
                          <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                            {this.state.createPacking.imagesPickup.map((image, index) => (
                              <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                                <img src={`/pickup/${image}`} alt="" width="100"/>
                              </div>
                            ))}
                          </div>
                        ) : ''
                      }
                      <ImageUploading
                        multiple
                        value={this.state.images2}
                        onChange={this.onChange3}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            <a className="btn btn-primary"
                               style={isDragging ? {color: 'red'} : undefined}
                               onClick={onImageUpload}
                               {...dragProps}
                            >
                              Chọn hình ảnh
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            {
                              (this.state.images2 && this.state.images2.length) ? (
                                <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                              ) : ''
                            }
                            <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                              {this.state.images2.map((image, index) => (
                                <div key={index} className="image-item" style={{marginTop: '10px'}}>
                                  <img src={image['data_url']} alt="" width="100"/>
                                  <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                    <a onClick={() => onImageRemove(index)}>
                                      <img src="/close.png" alt="" width="10"/>
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="typeGood">Nhóm hàng</Label>
                      <Input id="group" name="group" type="select" onChange={this.onCreatePackingChange}
                             value={createPacking.group}>
                        >
                        {Object.values(groupTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                   value={object}
                                                                                   key={i}>{object}</option>)}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="eventNumber">Số kiện (*)</Label>
                      <Input type="number" name="packs" id="packs" placeholder="Số kiện"
                             value={createPacking.packs}
                             onChange={this.onCreatePackingChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="weight">Trọng lượng thực (kg)</Label>
                      <Input type="number" name="weight" id="weight" placeholder="Trọng lượng thực"
                             value={createPacking.weight}
                             onChange={this.onCreatePackingChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="weight">Trọng lượng quy đổi (kg)</Label>
                      <Input type="number" name="weight2" id="weight2" placeholder="Trọng lượng quy đổi"
                             value={createPacking.weight2}
                             onChange={this.onCreatePackingChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="information">Thông tin hàng</Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        id="info"
                        placeholder="Thông tin hàng"
                        value={createPacking.info}
                        onChange={this.onCreatePackingChange}
                        name="info"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="statusType">Trạng thái:</Label>
                      <Input id="statusType" name="statusType" type="select" value={createPacking.statusType}
                             onChange={this.onCreatePackingChange}>
                        {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                    value={object}
                                                                                    key={i}>{object}</option>)}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="price">Hình ảnh Hoàn thành</Label>
                      <ImageUploading
                        multiple
                        value={this.state.images}
                        onChange={this.onChange2}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            <a className="btn btn-primary"
                               style={isDragging ? {color: 'red'} : undefined}
                               onClick={onImageUpload}
                               {...dragProps}
                            >
                              Chọn hình ảnh
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            {
                              (this.state.images && this.state.images.length) ? (
                                <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                              ) : ''
                            }
                            <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                              {this.state.images.map((image, index) => (
                                <div key={index} className="image-item" style={{marginTop: '10px'}}>
                                  <a href={image['data_url']} target="_blank"> <img src={image['data_url']} alt="" /></a>
                                  <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                    <a onClick={() => onImageRemove(index)}>
                                      <img src="/close.png" alt="" width="10"/>
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </FormGroup>

                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                {this.state.loading && (
                  <div className="text-center">
                    <span className="loader"></span>
                  </div>
                )}
                <Button type="submit" color="primary" onClick={(e) => this.onCreatePacking(e)}>Tạo mới</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Hủy</Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/* Toggle Form Edit Packing */}
          <Modal
            backdrop="static"
            size="xl"
            isOpen={this.state.modalEditToggle} toggle={this.openEditModal}>
            <Form>
              <ModalHeader toggle={this.openEditModal}>Edit Đóng gói</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={6}>
                    <div>
                      {
                        !this.isGiaoNhan() && (
                          <FormGroup>
                            <Label for="eventNumber">Khách hàng</Label>

                            <Select
                              options={this.state.listCustomer}
                              value={this.state.selectedCustomer[0]?.id}
                              onChange={this.onSelectedEditCustomer}
                              autosize={false}/>
                          </FormGroup>
                        )
                      }

                      <FormGroup>
                        <Label for="name">Mã khách hàng</Label>
                        <Input type="text" name="customerId" value={editPacking.customerId} id="customerId"
                               disabled={this.isGiaoNhan()}
                               placeholder="Mã khách hàng" onChange={this.onEditPackingChange}/>
                      </FormGroup>
                      <FormGroup>
                        <Label for="name">Tên người gửi</Label>
                        <Input type="text" name="customerName" value={editPacking.customerName} id="customerName"
                                disabled={this.isGiaoNhan()}
                                placeholder="Tên người gửi" onChange={this.onEditPackingChange}/>
                      </FormGroup>

                      <FormGroup>
                        <Label for="service">Dịch vụ</Label>
                        <Input type="text" name="service" id="service" value={editPacking.service} placeholder="Dịch vụ"
                               disabled={this.isGiaoNhan()}
                               onChange={this.onEditPackingChange}/>
                      </FormGroup>
                      <FormGroup>
                        <Label for="typeGood">Mã pickup</Label>
                        <Input type="text" name="pickupId" id="pickupId" value={editPacking.pickupId}
                               disabled={this.isGiaoNhan()}
                               placeholder="Mã kiện hàng" onChange={this.onEditPackingChange}/>
                      </FormGroup>
                      <FormGroup>
                        <Label for="localtion">Khu vực</Label>
                        <Input id="localtion" name="localtion" value={editPacking.localtion}
                               disabled={this.isGiaoNhan()} type="select"
                               onChange={this.onEditPackingChange}>
                          {Object.values(localtionOption).map((object, i) => <option className="text-capitalize"
                                                                                     value={object}
                                                                                     key={i}>{object}</option>)}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="note">Ghi chú</Label>
                        <textarea
                          className="form-control"
                          rows="4"
                          id="note"
                          disabled={this.isGiaoNhan()}
                          value={editPacking.note}
                          placeholder="Ghi chú"
                          onChange={this.onEditPackingChange}
                          name="note"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="price">Hình ảnh</Label>
                        {
                          (this.state.imagesEdit2 && this.state.imagesEdit2.length) ? (
                            <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                              {this.state.imagesEdit2.map((image, index) => (
                                <div key={index} className="image-item"
                                     style={{marginTop: '10px', position: 'relative'}}>
                                  <a href={`/pickup/${image}`} target="_blank"> <img src={`/pickup/${image}`} alt="" width="100"/> </a>

                                </div>
                              ))}
                            </div>
                          ) : ''
                        }

                        {
                          !this.isGiaoNhan() && (
                            <ImageUploading
                              multiple
                              value={this.state.images2}
                              onChange={this.onChange3}
                              maxNumber={maxNumber}
                              dataURLKey="data_url"
                            >
                              {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                                }) => (
                                // write your building UI
                                <div className="upload__image-wrapper"
                                     style={{marginTop: '30px'}}>
                                  <a className="btn btn-primary"
                                     style={isDragging ? {color: 'red'} : undefined}
                                     onClick={onImageUpload}
                                     {...dragProps}
                                  >
                                    Chọn thêm hình ảnh
                                  </a>
                                  &nbsp;&nbsp;&nbsp;
                                  {
                                    (this.state.images2 && this.state.images2.length) ? (
                                      <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                                    ) : ''
                                  }
                                  <div className="image-list"
                                       style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                                    {this.state.images2.map((image, index) => (
                                      <div key={index} className="image-item" style={{marginTop: '10px'}}>
                                        <a href={image['data_url']} target="_blank"> <img src={image['data_url']} alt="" width="100"/> </a>
                                        <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                          <a onClick={() => onImageRemove(index)}>
                                            <img src="/close.png" alt="" width="10"/>
                                          </a>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </ImageUploading>
                          )
                        }

                      </FormGroup>
                    </div>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="typeGood">Nhóm hàng</Label>
                      <Input id="group" name="group" type="select" value={editPacking.group}
                             disabled={this.isSale()}
                             onChange={this.onEditPackingChange}>
                        {Object.values(groupTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                   value={object}
                                                                                   key={i}>{object}</option>)}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="eventNumber">Số kiện</Label>
                      <Input type="number" name="packs" id="packs" disabled={this.isSale()} value={editPacking.packs} placeholder="Số kiện"
                             onChange={this.onEditPackingChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="weight">Trọng lượng thực</Label>
                      <Input type="number" name="weight" id="weight" disabled={this.isSale()} value={editPacking.weight}
                             placeholder="Trọng lượng thực" onChange={this.onEditPackingChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="weight">Trọng lượng quy đổi</Label>
                      <Input type="number" name="weight2" id="weight2" disabled={this.isSale()} value={editPacking.weight2}
                             placeholder="Trọng lượng quy đổi" onChange={this.onEditPackingChange}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="information">Thông tin hàng</Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        disabled={this.isSale()}
                        id="info"
                        value={editPacking.info}
                        placeholder="Thông tin hàng"
                        onChange={this.onEditPackingChange}
                        name="info"
                      />
                    </FormGroup>
                    {
                      this.isAdmin() && (
                            <FormGroup>
                              <Label for="typeGood">NVĐG</Label>
                              <Input type="text" name="nvgn" id="nvgn" value={editPacking.nvgn}
                                     disabled={this.isGiaoNhan() || this.isSale()}
                                     onChange={this.onEditPackingChange}/>
                            </FormGroup>
                        )
                    }
                    <FormGroup>
                      <Label for="statusType">Trạng thái: {editPacking.statusType}</Label>
                      <strong>
                        {editPacking.statusType !== 'Hoàn thành' && (this.isGiaoNhan() || this.isAdmin()) && (
                          <Button className='btn btn-primary' onClick={(e) => this.updatePackingStatus(e)} >
                            {this.getPackingText(editPacking.statusType)}</Button>
                        )}
                      </strong>
                      <br/>
                      {
                        this.isAdmin() && (
                          <Input id="statusType" name="statusType" type="select" disabled={this.isSale()} value={editPacking.statusType}
                                 onChange={this.onEditPackingChange}>
                            {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                        value={object}
                                                                                        key={i}>{object}</option>)}
                          </Input>
                          )
                      }

                    </FormGroup>
                    <FormGroup>
                      <Label for="price">Hình ảnh Hoàn thành</Label>
                      {
                        (this.state.imagesEdit && this.state.imagesEdit.length) ? (
                          <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                            {this.state.imagesEdit.map((image, index) => (
                              <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                                <a href={`/pickup/${image}`} target="_blank"> <img src={`/pickup/${image}`} alt="" width="100"/> </a>
                                <div className="image-item__btn-wrapper" style={{
                                  marginTop: '8px', position: 'absolute', right: 0,
                                  top: 0
                                }}>
                                  <a onClick={() => this.onImageEditRemove(index)}>
                                    <img src="/close.png" alt="" width="10"/>
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : ''
                      }


                      {
                        !this.isSale() && (
                          <ImageUploading
                            multiple
                            value={this.state.images}
                            onChange={this.onChange2}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                          >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                              }) => (
                              // write your building UI
                              <div className="upload__image-wrapper"
                                   style={{marginTop: '30px'}}>
                                <a className="btn btn-primary"
                                   style={isDragging ? {color: 'red'} : undefined}
                                   onClick={onImageUpload}
                                   {...dragProps}
                                >
                                  Chọn thêm hình ảnh
                                </a>
                                &nbsp;&nbsp;&nbsp;
                                {
                                  (this.state.images && this.state.images.length) ? (
                                    <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                                  ) : ''
                                }
                                <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                                  {this.state.images.map((image, index) => (
                                    <div key={index} className="image-item" style={{marginTop: '10px'}}>
                                      <a href={image['data_url']} target="_blank"> <img src={image['data_url']} alt="" width="100"/> </a>
                                      <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                        <a onClick={() => onImageRemove(index)}>
                                          <img src="/close.png" alt="" width="10"/>
                                        </a>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </ImageUploading>
                          )
                      }
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditPacking(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/* Toggle Form View Packing */}
          <Modal isOpen={this.state.modalViewToggle} toggle={this.openViewModal}>
            {viewPacking && (
              <Form>
                <ModalHeader toggle={this.openViewModal}>Packing Info: {this.showTPCode(viewPacking.id)}</ModalHeader>
                <ModalBody>
                  <ListGroup>
                    <ListGroupItem>Mã Pickup: PK{viewPacking.pickupId} </ListGroupItem>
                    <ListGroupItem>Mã KH: KHTP{viewPacking.customerId}</ListGroupItem>
                    <ListGroupItem>Account Tạo: {viewPacking.owner} </ListGroupItem>
                    <ListGroupItem>Thông tin hàng:

                      <div>
                        <p><strong>Số kiện:</strong> {viewPacking.packs}</p>
                        <p><strong>Cân nặng:</strong> {viewPacking.weight}kg</p>
                        <p><strong>Cân nặng quy đổi:</strong> {viewPacking.weight2}kg</p>
                        <p><strong>Nhóm hàng:</strong> {viewPacking.group || 'Hàng thường'}</p>
                        <p><strong>Dịch vụ:</strong> {viewPacking.service}</p>
                        {
                          viewPacking.note && (
                            <p><strong>Note:</strong> {viewPacking.note}</p>
                          )
                        }
                      </div>
                    </ListGroupItem>
                    <ListGroupItem>Nội dung hàng hóa: {viewPacking.info}</ListGroupItem>
                    <ListGroupItem>Ngày Tạo: {this.formatDate(viewPacking.createdAt)}</ListGroupItem>

                    {(this.state.imagesView && this.state.imagesView.length) ? this.state.imagesView.map((object, i) =>
                      <ObjectRow obj={object} key={i}/>
                    ) : ''}

                    <ListGroupItem>
                      <Label for="price">Hình ảnh</Label>
                      {
                        (this.state.viewPacking.imagesPickup) ? (
                          <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                            {this.state.viewPacking.imagesPickup?.split(',').map((image, index) => (
                              <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                                <img src={`/pickup/${image}`} alt="" width="100"/>
                              </div>
                            ))}
                          </div>
                        ) : ''
                      }
                    </ListGroupItem>

                    <ListGroupItem>
                      <Label for="price">Hình ảnh Giao nhận</Label>
                      {
                        (this.state.viewPacking.images) ? (
                          <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                            {this.state.viewPacking.images?.split(',').map((image, index) => (
                              <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                                <img src={`/pickup/${image}`} alt="" width="100"/>
                              </div>
                            ))}
                          </div>
                        ) : ''
                      }
                    </ListGroupItem>


                  </ListGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.openViewModal}>Close</Button>
                </ModalFooter>
              </Form>
            )}

          </Modal>

          <Modal
            isOpen={this.state.modalPrintPackagesToggle}
            scrollable
            centered
            size='lg'
            fullscreen="true |xxl-down'"
            toggle={this.printPackagesToggle}
          >
            <ModalHeader toggle={this.printPackagesToggle}>
              Print Packing
            </ModalHeader>
            <ModalBody>
              <style> {printPacking()} </style>
              <div>
                <PrintPacking
                  billData={this.state.printData}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button href='#'> Print this out!</Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

        </Row>
      </div>
    )
  }
}

export default Packing
