import bcrypt from 'bcryptjs'
import { confirmAlert } from 'react-confirm-alert'; // Import
import axios from "axios";
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const LIST_USER_REQUEST = 'LIST_USER_REQUEST';
export const LIST_USER_SUCCESS = 'LIST_USER_SUCCESS';
export const LIST_USER_FAILURE = 'LIST_USER_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';


/**************************** Login ****************************/
function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
}

export function receiveLogin(user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: user.id_token,
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

export function loginUser(creds) {
  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    credentials: 'include',
    body: `login=${creds.login}&password=${creds.password}`,
  };

  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds));
    return fetch('/login', config)
      .then(response => response.json().then(user => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(loginError(user.message));
          return Promise.reject(user);
        }
        // in posts create new action and check http status, if malign logout
        // If login was successful, set the token in local storage
        localStorage.setItem('id_token', user.id_token);
        localStorage.setItem('openModalNotify', true);
        // Dispatch the success action
        dispatch(receiveLogin(user));
        return Promise.resolve(user);
      })
      .catch(err => console.error('Error: ', err));
  };
}


/**************************** Log out ****************************/
function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

export function logoutUser() {
  return dispatch => {
    dispatch(requestLogout());
    localStorage.removeItem('id_token');
    document.cookie = 'id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    dispatch(receiveLogout());
  };
}

/**************************** Get User By ID ****************************/
export async function fetchUserByID(id) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `{userByID(id:"${id}"){id,companyName,saler,country,city,address,email,displayName,location, role,status,discount}}`,
    }),
    credentials: 'include',
  };

  const resp = await fetch('/graphql', config)
  const respJSON = await resp.json()
  if (respJSON.errors) {
    console.log(respJSON.errors)
    return {}
  }

  return respJSON.data.userByID
}


/**************************** Fetch Users ****************************/
export function requestUsers() {
  return {
    type: LIST_USER_REQUEST,
    isFetching: true,
    isAuthenticated: false,
  };
}

export function fetchUsersSuccess(users) {
  return {
    type: LIST_USER_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
    users,
  };
}

export function fetchUsersError(message) {
  return {
    type: LIST_USER_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

export function fetchUsers(params) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `{users (limit: ${params.limit}, status: "${params.status || ''}",page: ${params.page}) {count,rows{id,displayName,companyName, saler, address,location, email,role,status,discount}}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestUsers());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          responseJson
        })),
      )
      .then(({ responseJson }) => {
        if (responseJson.errors) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(fetchUsersError(responseJson.errors[0].message));
          return Promise.reject(responseJson.errors);
        }
        // Dispatch the success action
        dispatch(fetchUsersSuccess(responseJson.data.users));
        return Promise.resolve(responseJson.data.users);
      })
      .catch(err => console.error('Error: ', err));
  };
}

/**************************** Create User ****************************/
export function requestCreateUser() {
  return {
    type: CREATE_USER_REQUEST,
    isCreating: true,
    isAuthenticated: false,
  };
}

export function createUserSuccess(user) {
  return {
    type: CREATE_USER_SUCCESS,
    isCreating: false,
    isAuthenticated: false,
    user,
  };
}

export function createUserError(message) {
  return {
    type: CREATE_USER_FAILURE,
    isCreating: false,
    isAuthenticated: false,
    message,
  };
}

export function createUser(postData) {
  var salt = bcrypt.genSaltSync(10);
  var hashedPwd = bcrypt.hashSync(postData.password, salt);
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        createUser(
          email:"${postData.email}",
          password:"${hashedPwd}",
          displayName:"${postData.displayName}",
          companyName:"${postData.companyName}",
          address:"${postData.address}",
          role:"${postData.role}",
          country:"${postData.country}",
          city:"${postData.city}",
          discount:"${postData.discount}",
          status: "${postData.status}",
          location: "${postData.location}",
          saler: "${postData.saler}",
          )
          
          {id,displayName,email,companyName,saler,address,role,location,status,discount}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestCreateUser());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          responseJson
        })),
      )
      .then(({ responseJson }) => {
        if (responseJson.errors) {
          dispatch(createUserError(responseJson.errors[0]));
          return Promise.reject(responseJson.errors);
        }

        dispatch(createUserSuccess(responseJson.data.createUser));
        return Promise.resolve(responseJson.data.createUser);
      })
      .catch(err => console.error('Error: ', err));
  };
}

/**************************** Update User ****************************/
export function requestUpdateUser() {
  return {
    type: UPDATE_USER_REQUEST,
    isCreating: true,
  };
}

export function updateUserSuccess(user) {
  confirmAlert({
    title: 'Thành công',
    message: 'Cập nhật thành công !',
    buttons: [{label: 'Đóng',onClick: () => {}}]});
  return {
    type: UPDATE_USER_SUCCESS,
    isCreating: false,
    user,
  };
}

export function updateUserError(message) {
  return {
    type: UPDATE_USER_FAILURE,
    isCreating: false,
    message,
  };
}

export function updateUser(postData) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        updateUser(
          id: "${postData.id}",
          email:"${postData.email}",
          displayName:"${postData.displayName}",
          companyName:"${postData.companyName}",
          address:"${postData.address}",
          status:"${postData.status}",
          discount:"${postData.discount}",
          location:"${postData.location}",
          role:"${postData.role}",
          saler:"${postData.saler}")
          
          {id,displayName,email,companyName,saler,status,location,address,role,discount}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestUpdateUser());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          updateUser: responseJson.data.updateUser,
          responseJson,
        })),
      )
      .then(({ updateUser, responseJson }) => {
        if (!responseJson.data.updateUser) {
          dispatch(updateUserError(updateUser.errors));
          return Promise.reject(updateUser);
        }
        dispatch(updateUserSuccess(updateUser));
        // call api update user name use axios
        axios.post('/add-user-name', {
          userId: updateUser.id,
          displayName: updateUser.displayName
        })

        return Promise.resolve(updateUser);
      })
      .catch(err => console.error('Error: ', err));
  };
}

/**************************** Delete User ****************************/
export function requestDeleteUser() {
  return {
    type: DELETE_USER_REQUEST,
    isCreating: true,
  };
}

export function deleteUserSuccess(id) {
  return {
    type: DELETE_USER_SUCCESS,
    isCreating: false,
    id,
  };
}

export function deleteUserError(message) {
  return {
    type: DELETE_USER_FAILURE,
    isCreating: false,
    message,
  };
}

export function deleteUser(id) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        deleteUser(id: "${id}")
          {id}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestDeleteUser());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          deleteUser: responseJson.data.deleteUser,
          responseJson,
        })),
      )
      .then(({ deleteUser, responseJson }) => {
        if (!responseJson.data.deleteUser) {
          dispatch(deleteUserError(deleteUser.errors));
          return Promise.reject(deleteUser);
        }

        dispatch(deleteUserSuccess(deleteUser));
        return Promise.resolve(deleteUser);
      })
      .catch(err => console.error('Error: ', err));
  };
}


/**************************** Update Password ****************************/
export function requestUpdatePassword() {
  return {
    type: UPDATE_PASSWORD_REQUEST,
    isCreating: true,
  };
}

export function updatePasswordSuccess(user) {
  confirmAlert({
    title: 'Thành công',
    message: 'Cập nhật thành công !',
    buttons: [{label: 'Đóng',onClick: () => {}}]});
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    isCreating: false,
    user,
  };
}

export function updatePasswordError(message) {
  return {
    type: UPDATE_PASSWORD_FAILURE,
    isCreating: false,
    message,
  };
}

export function updatePassword(postData) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        updatePassword(
          id: "${postData.id}",
          password:"${postData.password}",
          passwordNew:"${postData.passwordNew}",
          passwordAgain:"${postData.passwordAgain}")
          {id,displayName,email,companyName,status,address,role,discount}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestUpdatePassword());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          updatePassword: responseJson.data.updatePassword,
          responseJson,
        })),
      )
      .then(({ updatePassword, responseJson }) => {
        if (!responseJson.data.updatePassword) {
          dispatch(updatePasswordError(updatePassword.errors));
          return Promise.reject(updatePassword);
        }

        dispatch(updatePasswordSuccess(updatePassword));
        return Promise.resolve(updatePassword);
      })
      .catch(err => console.error('Error: ', err));
  };
}
