import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
  Table
} from "reactstrap";
import jwt from "jsonwebtoken";
import moment from 'moment';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {USER_ROLE} from "../../constants";
import ImageUploading from "react-images-uploading";

class PickupDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pickupDetail: {},
      modalEditStatusToggle: false,
      images: [],
      imagesEdit: [],
      statusArr: [ 'Chưa nhận', 'Đang nhận', 'Đã nhận', 'Hoàn thành'],
      statusArrName: [ '', 'Nhận đơn', 'Nhận đơn xong', 'Hoàn thành đơn hàng'],
      currentStatus: 'Chưa nhận',
      imagesView: [],
      currUser: {},
      failStatus: ''
    }
    this.openEditStatusModal = this.openEditStatusModal.bind(this)
    this.updatePickupStatus = this.updatePickupStatus.bind(this)
    this.onChange2 = this.onChange2.bind(this)
    this.onEditPickupChange = this.onEditPickupChange.bind(this)

  }

  async componentDidMount() {
    let { pickupId } = this.props.match.params;
    console.log(pickupId)
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser });

    this.getPickupDetail(pickupId)
  }

  onEditPickupChange(e) {
    const { name, value } = e.target;
    let pickupDetail = this.state.pickupDetail
    pickupDetail[name] = value
    this.setState({ pickupDetail: pickupDetail })
  }
  getPickupDetail = async (pickupId) => {
    try {
      const { data } = await axios.get(`/pickup-by-id/${pickupId}`)
      if (data) {
        this.setState({ pickupDetail: data })
        let imagesEdit = data.images
        if (imagesEdit) {
          imagesEdit = imagesEdit.split(',')
          this.setState({ imagesEdit: imagesEdit })
        }
        // how to get index of statusArr by value

      }
    } catch (e) {
      console.log(e)
    }
  }
  openEditStatusModal(obj, status = '') {
    const { modalEditStatusToggle } = this.state
    this.setState({ modalEditStatusToggle: !modalEditStatusToggle, failStatus: status } );
  }

  formatNumber(num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }

  isGiaoNhan() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  isAdmin() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  onChange2 = (imageList, addUpdateIndex) => {
    this.setImages(imageList);
  };
  setImages(imageList) {
    this.setState({images: imageList})
  }

  onImageEditRemove = (index) => {
    let { imagesEdit } = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }

  formatDate = (str) => {
    return moment(str).format('DD/MM/YYYY HH:mm')
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  getImageStatus(image, imagesObj) {
    if (imagesObj) {
      imagesObj = JSON.parse(imagesObj)
      let status = ''
      for (let key in imagesObj) {
        if (imagesObj[key].images.includes(image)) {
          status = key
          break
        }
      }
      return status
    }
  }
  getImageDate(image, imagesObj) {
    if (imagesObj) {
      imagesObj = JSON.parse(imagesObj)
      let status = ''
      for (let key in imagesObj) {
        if (imagesObj[key].images.includes(image)) {
          status = moment(imagesObj[key].date).format('DD/MM/YYYY HH:mm')
          break
        }
      }
      return status
    }
  }

  updatePickupStatus = async (e) => {
    e.preventDefault()
    if (this.state.currUser?.role !== USER_ROLE.SHIPPER && this.state.currUser?.role !== USER_ROLE.ADMIN) {
      toast.error('Bạn không có quyền cập nhật trạng thái')
      return
    }
    const { pickupDetail, images, imagesEdit } = this.state
    if (pickupDetail.nvgn && pickupDetail.nvgn !== this.state.currUser.displayName && this.state.currUser.role !== USER_ROLE.ADMIN) {
      toast.error('Đơn hàng đã có NV Giao nhận khác nhận rồi, bạn không thể cập nhật trạng thái')
      return
    }

    if ((pickupDetail.statusType !== 'Chưa nhận' &&  pickupDetail.statusType !== 'Đang nhận' &&  pickupDetail.statusType !== 'Thất bại') && this.state.failStatus == '') {
      if (images && !images.length) {
        toast.error('Vui lòng chọn hình ảnh')
        return
      }
    }

    let imageLists = []
    let obj = {}
    if (pickupDetail.statusType !== 'Hoàn thành') {
      obj = {...obj, statusType: this.state.statusArr[this.state.statusArr.indexOf(pickupDetail.statusType) + 1]}
    } else {
      obj = {...obj, statusType: 'Hoàn thành'}
    }
    if (this.state.failStatus === 'Thất bại') {
      obj = {...obj, statusType: 'Thất bại', note2: pickupDetail.note2}
    }
    if (pickupDetail.statusType === 'Chưa nhận' || pickupDetail.statusType === 'Thất bại') {
        obj = {...obj, nvgn: '' }
    }
    console.log(pickupDetail)
    if (pickupDetail.statusType === 'Thất bại') {
        obj = {...obj, statusType: 'Đang nhận' }
    }

    if (images && images.length) {
      const formData = new FormData();
      images.forEach(i => {
        formData.append("photos", i.file);
      })

      const {data = []} = await axios({
        method: "post",
        url: "/upload-pickup",
        data: formData,
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
      });
      imageLists = data
      if (pickupDetail.imagesObj) {
        obj.imagesObj = JSON.parse(pickupDetail.imagesObj)
      }
      let text = obj.imagesObj ? {...obj.imagesObj, ...{ [obj.statusType]: {images: data, date: new Date()} } } :
        {[obj.statusType]: {images: data, date: new Date()}}
      obj.imagesObj = JSON.stringify(text)
    }
    if (imagesEdit && imagesEdit.length) {
      imageLists = [...imageLists, ...imagesEdit]
      imageLists = [...new Set(imageLists)]
    }

    if (imageLists.length) {
      obj = {...obj, images: imageLists.join(',')}
    }
    if (this.state.currUser?.role === USER_ROLE.SHIPPER) {
      obj = {...obj, nvgn: this.state.currUser.displayName}
    }

    // if ((obj.statusType === 'Hoàn thành' || obj.statusType === 'Đã nhận') &&  !imageLists.length) {
    //   toast.error('Vui lòng chọn hình ảnh')
    //   return
    // }
    try {
      // eslint-disable-next-line no-use-before-define
      const { data } = await axios.post(`/update-pickup-status/${pickupDetail.id}`, obj)
      if (data) {
        await this.getPickupDetail(pickupDetail.id)
        this.setState({ modalEditStatusToggle: false, images: [], failStatus: '' })
        toast.success('Cập nhật trạng thái thành công')
      }
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    let { pickupId } = this.props.match.params;
    const { pickupDetail } = this.state

    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active>Pickup Detail</BreadcrumbItem>
        </Breadcrumb>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Thông tin Pickup: PK{pickupId}</span>
          </CardHeader>
          <CardBody>
            <ListGroup>
              <ListGroupItem><strong>Mã pickup:</strong> PK{pickupDetail.id} </ListGroupItem>
              <ListGroupItem>
                <div className="d-flex justify-content-start align-items-center">
                  <strong className='mr-3'>Trạng Thái Đơn Hàng:</strong> {pickupDetail.statusType}
                  <strong>
                    {pickupDetail.statusType !== 'Hoàn thành' && (this.isGiaoNhan() || this.isAdmin()) && (
                      <Button className='ml-3 btn btn-primary' onClick={(e) => this.openEditStatusModal(pickupDetail)} >
                        {this.state.statusArrName[this.state.statusArr.indexOf(pickupDetail.statusType) + 1] || 'Nhận lại'}</Button>
                    )}
                  </strong>
                  <strong>
                    {pickupDetail.statusType !== 'Hoàn thành' && pickupDetail.statusType !== 'Chưa nhận' && (this.isGiaoNhan() || this.isAdmin()) && (
                      <Button className='ml-3 text-danger bg-transparent' onClick={(e) => this.openEditStatusModal(pickupDetail, 'Thất bại')} >
                        Pickup Thất Bại
                      </Button>
                    )}
                  </strong>

                </div>
                {
                    pickupDetail.statusType === 'Thất bại' && (
                        <div className='text-danger'>Lý do: {pickupDetail.note2}</div>
                    )
                }
              </ListGroupItem>
              <ListGroupItem><strong>Mã KH:</strong> KHTP{pickupDetail.customerId}</ListGroupItem>
              <ListGroupItem><strong>Account Tạo:</strong> {pickupDetail.owner} </ListGroupItem>
              <ListGroupItem><strong className='text-uppercase'>Thông tin người gửi:</strong>
                <p><strong>Name:</strong> {pickupDetail.customerName}</p>
                <p><strong>Phone:</strong> {pickupDetail.customerPhone}</p>
                <p><strong>Address:</strong> {pickupDetail.customerAddress}</p>
              </ListGroupItem>
              <ListGroupItem><strong className='text-uppercase mb-2'>Thông tin hàng:</strong>
                <div>
                  <p><strong>Số kiện:</strong> {pickupDetail.packs}</p>
                  <p><strong>Weight:</strong> {pickupDetail.weight}</p>
                  <p><strong>Nhóm hàng:</strong> {pickupDetail.group}</p>
                  <p><strong>Dịch vụ:</strong> {pickupDetail.service}</p>
                  <p><strong>Hình thức:</strong> {pickupDetail.paymentType}</p>
                  <p><strong>Loại xe:</strong> {pickupDetail.verhicle || 'Xe máy'}</p>
                  <p><strong>Thông tin hàng:</strong> {pickupDetail.info}</p>
                  <p><strong>Nhân viên nhận hàng:</strong> {pickupDetail.nvgn}</p>
                </div>
              </ListGroupItem>
              <ListGroupItem>Nội dung hàng hóa: {pickupDetail.info}</ListGroupItem>
              <ListGroupItem>Ngày Tạo: {this.formatDate(pickupDetail.createdAt)}</ListGroupItem>
              <ListGroupItem>
                  <Label for="price">Hình ảnh</Label>
                  {
                    (this.state.imagesEdit && this.state.imagesEdit.length) ? (
                      <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                        {this.state.imagesEdit.map((image, index) => (
                          <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                            <a href={`/pickup/${image}`} target="_blank"> <img src={`/pickup/${image}`} alt="" width="100" /></a>
                            <div className='mt-2'>{this.getImageStatus(image, pickupDetail.imagesObj)} {this.getImageDate(image, pickupDetail.imagesObj)}</div>
                          </div>
                        ))}
                      </div>
                    ) : ''
                  }
              </ListGroupItem>
            </ListGroup>

          </CardBody>


          <Modal isOpen={this.state.modalEditStatusToggle} toggle={this.openEditStatusModal} >
            <Form>
              <ModalHeader toggle={this.openEditStatusModal}>Cập nhật trạng thái giao hàng</ModalHeader>
              <ModalBody>
                <FormGroup>
                  {this.state.failStatus ? (
                    <Label for="statusType">Trạng thái: Thất bại</Label>
                  ) : (
                    <Label for="statusType">Trạng thái: { pickupDetail.statusType } -->
                      &nbsp;{  pickupDetail.statusType === 'Thất bại' ? 'Đã nhận' : pickupDetail.statusType !== 'Hoàn thành' ? this.state.statusArr[this.state.statusArr.indexOf(pickupDetail.statusType) + 1] : '' }</Label>
                  )}

                </FormGroup>

                {
                  this.state.failStatus && (
                    <FormGroup>
                      <Label for="note">Ghi chú</Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        id="note2"
                        placeholder="Ghi chú"
                        value={pickupDetail.note2}
                        onChange={this.onEditPickupChange}
                        name="note2"
                      />
                    </FormGroup>
                  )
                }
                {
                  (pickupDetail.statusType !== 'Hoàn thành' &&  pickupDetail.statusType !== 'Chưa nhận' && this.state.failStatus === '') ? (
                    <FormGroup>
                      <Label for="price">Hình ảnh *</Label>
                      {
                        (this.state.imagesEdit && this.state.imagesEdit.length) ? (
                          <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                            {this.state.imagesEdit.map((image, index) => (
                              <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                                <a href={`/pickup/${image}`} target="_blank"> <img src={`/pickup/${image}`} alt="" width="100" /></a>
                                <div className="image-item__btn-wrapper" style={{marginTop: '8px', position: 'absolute', right: 0,
                                  top: 0}}>
                                  <a onClick={() => this.onImageEditRemove(index)}>
                                    <img src="/close.png" alt="" width="10" />
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : ''
                      }

                      <ImageUploading
                        multiple
                        value={this.state.images}
                        onChange={this.onChange2}
                        maxNumber={12}
                        dataURLKey="data_url"
                      >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            <a className="btn btn-primary"
                               style={isDragging ? { color: 'red' } : undefined}
                               onClick={onImageUpload}
                               {...dragProps}
                            >
                              Chọn hình ảnh
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            {
                              (this.state.images && this.state.images.length) ? (
                                <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                              ) : ''
                            }
                            <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                              {this.state.images.map((image, index) => (
                                <div key={index} className="image-item" style={{marginTop: '10px'}}>
                                  <img src={image['data_url']} alt="" width="100" />
                                  <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                    <a onClick={() => onImageRemove(index)}>
                                      <img src="/close.png" alt="" width="10" />
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </FormGroup>
                  ) : ''
                }


              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.updatePickupStatus(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditStatusModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>


        </Card>
      </div>
    )
  }
}
export default PickupDetail
