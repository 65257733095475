import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken';
import s from './Dashboard.module.scss';
import './main.css'

import { USER_ROLE } from '../../constants';
import moment from 'moment';
import EditorArea from './EditorArea';
import { Link } from 'react-router-dom';
import axios from "axios";

class Pricing extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currUser: {},
      modalToggleNewDashboard: false,
      newDashboard: { title: '', body: '', highlight: false, code: '', codeHN: '', codeDN: '', location: 'HCM' },
      isOpenToast: false,
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      selectedLocation: 'HCM'
    };
    this.onAddnewDashboard = this.onAddnewDashboard.bind(this);
    this.onChangeNewDashboard = this.onChangeNewDashboard.bind(this);
    this.toggleNewDashboard = this.toggleNewDashboard.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.setLocation = this.setLocation.bind(this);
  }

  toggleNewDashboard() {
    this.setState({
      modalToggleNewDashboard: !this.state.modalToggleNewDashboard,
    });
  }
  setLocation(val) {
    const { data } = this.state
    this.setState({selectedLocation: val})
    let list = data.list ? data.list.filter(i => i.location == val) : [];
    this.setState({list: list})
  }

  async onAddnewDashboard(e) {
    e.preventDefault();
    try {
      console.log(this.state.newDashboard)
      await axios.post('/create-pricing', this.state.newDashboard)
      await this.getAllDashboard()
      this.toggleNewDashboard();
      this.setState({
        isOpenToast: true,
        newDashboard: { title: '', body: '', highlight: false, code: '', codeDN: '', codeHN: '' },
      });
    } catch (err) {
      toast.error('Đã có lỗi xảy ra ', err.error);
      return
    }
  }

  onChangeNewDashboard(e, name) {
    if (name) {
      this.setState({
        newDashboard: {
          ...this.state.newDashboard,
          [name]: e,
        },
      });
      return;
    }
    this.setState({
      newDashboard: {
        ...this.state.newDashboard,
        [e.target.name]: e.target.value,
      },
    });
  }

  changeLocation(e) {
    const { name, value } = e.target;
    let newDashboard = this.state.newDashboard
    newDashboard[name] = value
    this.setState({ newDashboard: newDashboard })
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser });

    await this.getAllDashboard()
  }

  async getAllDashboard(params = {}) {
    try {
      const { searchBy, search = '' } = this.state
      this.setState({loading: true})
      let res
      let url = `/get-all-pricing?page=${params.page || 1}&search=${search}&status=${this.state.qStatus}`
      if (searchBy === 'owner') {
        url += `&searchBy=${searchBy}`
      }
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data, this.state.selectedLocation)
        let list = res.data && res.data.list ? res.data.list.filter(i => i.location == this.state.selectedLocation) : [];
        this.setState({data: res.data, list})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  render() {
    const { data, selectedLocation, list } = this.state;
    const locationOption = {
      HCM: "HCM",
      HN: 'HN',
      ĐN: 'ĐN',
      Custom: 'Custom'
    }

    return (
      <div className={s.root}>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Bảng giá</BreadcrumbItem>
        </Breadcrumb>

        {/* New content of dashboard */}
        <Modal
          isOpen={this.state.modalToggleNewDashboard}
          toggle={this.toggleNewDashboard}
        >
          <ModalHeader>Tạo bảng giá mới</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='title'>Tiêu đề</Label>
              <Input
                value={this.state.newDashboard.title}
                name='title'
                id='title'
                placeholder='title'
                onChange={(e) => this.onChangeNewDashboard(e)}
              />
            </FormGroup>
            <FormGroup style={{ minHeight: '400px' }}>
              <Label for='content'>Nội dung</Label>
              <EditorArea
                value={this.state.newDashboard.body}
                onChange={(e) => this.onChangeNewDashboard(e, 'body')}
              />
            </FormGroup>
            <FormGroup>
              <Label for="location">Khu vực</Label>
              <Input id="location" name="location" value={this.state.newDashboard.location} type="select"  onChange={this.changeLocation}>
                {Object.values(locationOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for='content'>Embed Code</Label>
              <textarea
                className="form-control"
                value={this.state.newDashboard.code}
                name='code'
                id='code'
                placeholder='code'
                onChange={(e) => this.onChangeNewDashboard(e)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type='submit'
              color='secondary'
              onClick={(e) => this.onAddnewDashboard(e)}
            >
              Thêm mới
            </Button>
            <Button color='success' onClick={this.toggleNewDashboard}>
              Huỷ bỏ
            </Button>
          </ModalFooter>
        </Modal>

        <Row>
          {this.state.currUser.role === USER_ROLE.ADMIN && (
            <Col sm={12} className='mb-2 justify-content-end d-flex'>
              <Button className={s.btnAddNotification} onClick={this.toggleNewDashboard}>
                <i className='fa fa-plus-circle' /> Thêm bảng giá
              </Button>
            </Col>
          )}
          <div style={{width: '100%'}}>
            <ul className="nav nav-tabs" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                    className={`nav-link ${this.state.selectedLocation === 'HCM' ? "active" : ""}`}
                    href="#HCM"
                    onClick={() => this.setLocation('HCM')}
                >Hồ Chí Minh</a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                    className={`nav-link ${this.state.selectedLocation === 'HN' ? "active" : ""}`}
                    href="#HN"
                    onClick={() => this.setLocation('HN')}
                >Hà Nội</a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                    className={`nav-link ${this.state.selectedLocation === 'ĐN' ? "active" : ""}`}
                    href="#ĐN"
                    onClick={() => this.setLocation('ĐN')}
                >Đà Nẵng</a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                    className={`nav-link ${this.state.selectedLocation === 'Custom' ? "active" : ""}`}
                    href="#Custom"
                    onClick={() => this.setLocation('Custom')}
                >GIÁ BÁN THAM KHẢO</a>
              </li>
            </ul>
          </div>

          <div style={{width: '100%'}}>
            <ListGroup className={s.listGroup}>
              {data && data.list.length > 0 &&
                  list.map((item) => (
                      <ListGroupItem key={item.id}>
                        <div className={s.listGroupItem}>
                          <Link to={`/app/pricing/${item.id}`}>
                            <h5 className={`mb-1 ${item.highlight ? 'text-danger' : ''}`}>
                              {item.title || 'UNKNOWN'}
                            </h5>
                          </Link>

                          <small>{moment(item.updatedAt).fromNow()}</small>
                        </div>
                      </ListGroupItem>
                  ))}
            </ListGroup>
          </div>
        </Row>
      </div>
    );
  }
}

export default Pricing
