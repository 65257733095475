import React from 'react'
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from 'reactstrap'
import EditorArea from '../dashboard/EditorArea'
import jwt from 'jsonwebtoken'
import ReactHtmlParser from 'react-html-parser'
import axios from 'axios'
import { USER_ROLE } from '../../constants'

class TermsOfUse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      termData: '',
      currUser: {},
      modalToggleUpdate: false,
    }
    this.onChangeUpdate = this.onChangeUpdate.bind(this)
    this.toggleUpdate = this.toggleUpdate.bind(this)
    this.savedFile = this.savedFile.bind(this)
    this.cancel = this.cancel.bind(this)
  }

  toggleUpdate() {
    this.setState({
      modalToggleUpdate: !this.state.modalToggleUpdate,
    })
  }

  async componentDidMount() {
    const termData = await fetch('/term.txt').then((r) => r.text())
    let token = localStorage.getItem('id_token')
    await this.setState({ termData, currUser: jwt.decode(token) })
  }
  async cancel() {
    const termData = await fetch('/term.txt').then((r) => r.text())
    await this.setState({ termData })
    this.setState({
      modalToggleUpdate: !this.state.modalToggleUpdate,
    })
  }

  updateDashboard() {
    this.setState({
      modalToggleUpdate: !this.state.modalToggleUpdate,
    })
  }

  async onChangeUpdate(termData) {
    await this.setState({ termData })
  }

  async savedFile(e) {
    const { data } = await axios.post('/update-term-and-condition', {
      content: this.state.termData,
    })
    this.setState({
      modalToggleUpdate: !this.state.modalToggleUpdate,
    })
    e.preventDefault()
  }

  render() {
    return (
      <div>
        {this.state.currUser.role === USER_ROLE.ADMIN && (
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <Button onClick={() => this.updateDashboard()}>
                <i className="fa fa-pencil" /> Chỉnh sửa
              </Button>
            </div>
          </div>
        )}

        {ReactHtmlParser(this.state.termData)}
        <Modal
          isOpen={this.state.modalToggleUpdate}
          toggle={this.toggleUpdate}
          backdrop="static"
          size="xl"
        >
          <ModalHeader>ĐIỀU KHOẢN SỬ DỤNG DỊCH VỤ</ModalHeader>
          <ModalBody>
            <FormGroup style={{ minHeight: '400px' }}>
              <Label for="content">Nội dung</Label>
              <EditorArea
                value={this.state.termData}
                onChange={(termData) => this.onChangeUpdate(termData)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="success"
              onClick={(e) => this.savedFile(e)}
            >
              Lưu
            </Button>
            <Button color="secondary" onClick={this.cancel}>
              Huỷ bỏ
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default TermsOfUse
