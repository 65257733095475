import React from 'react'
import axios from 'axios'
import fileDownload from 'js-file-download'
import Select from 'react-select'
import { USER_ROLE, PACKAGE_ACTION, SHIPPING_SERVICES } from '../../constants'

import {
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Rounding from '../../utils/rounding'
import { vi } from 'date-fns/locale'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import jwt from "jsonwebtoken";
import moment from "moment/moment";
import {Link} from "react-router-dom";
class Barcode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: PACKAGE_ACTION.IMPORT,
      logisticService: '',
      isShowCalendar: false,
      disableDownloadButton: false,
      startDate: new Date(),
      endDate: new Date(),
      focus: START_DATE,
      items: [],
      currUser: {}
    }

    this.setStartDate = this.setStartDate.bind(this)
    this.setEndDate = this.setEndDate.bind(this)
    this.handleFocusChange = this.handleFocusChange.bind(this)
    this.handleService = this.handleService.bind(this)
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleEndDateSelect = this.handleEndDateSelect.bind(this);
  }

  handleService = (e) => {
    const { value } = e.target
    this.setState({ logisticService: value })
  }

  onSelectChange = (e) => {
    const { value } = e
    this.setState({ type: value })
  }

  componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    this.setState({ currUser })
    this.getItem();
  }

  async exportData() {
    await this.setState({ disableDownloadButton: true })
    const { startDate, endDate, type, logisticService } = this.state
    axios
      .get('/download', {
        params: { fromDate: startDate, toDate: endDate, type, logisticService },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({ disableDownloadButton: false })
      })
      .catch(() => {
        this.setState({ disableDownloadButton: false })
      })
    return
  }

  getHawbCode = (pkg) => {
    let hawbCode = ''
    let key = this.state.type === 'EXPORT' ? 'exportJSON' : 'importJSON'
    if (pkg[key] && pkg.hawbCode) {
      let importJSON = JSON.parse(pkg[key])
      let hawbArr = pkg.hawbCode.trim().split(',')
      hawbArr = hawbArr.map((hawb) => hawb.trim())
      hawbArr.forEach((hawb, index) => {
        if (importJSON[hawb]) {
          hawbCode += hawb + ' <br/>'
        }
      })
    }
    return hawbCode
  }

  getHawbDate = (pkg) => {
    let hawbCode = ''
    let key = this.state.type === 'EXPORT' ? 'exportJSON' : 'importJSON'
    if (pkg[key] && pkg.hawbCode) {
      let importJSON = JSON.parse(pkg[key])
      let hawbArr = pkg.hawbCode.trim().split(',')
      hawbArr = hawbArr.map((hawb) => hawb.trim())
      hawbArr.forEach((hawb, index) => {
        if (importJSON[hawb]) {
          hawbCode += moment(importJSON[hawb]).format('DD/MM/YYYY hh:mm') + ' <br/>'
        }
      })
    }
    return hawbCode
  }

  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({ focus })
  }
  setStartDate(startDate) {
    this.setState({ startDate })
  }
  setEndDate(endDate) {
    this.setState({ endDate })
  }

  async getItem() {
    try {
      const { startDate, endDate, type, logisticService } = this.state
      const { data } = await axios.get('/packages', {
        params: { startDate, endDate, type, logisticService },
      })
      this.setState({ items: data })
    } catch (error) {}
  }
  handleDatefromSelect(date) {
    this.setState({startDate: date});
  }
  handleEndDateSelect (date){
    this.setState({endDate: date});
  }
  render() {
    const {
      items,
      startDate,
      endDate,
      focus,
      isShowCalendar,
      type,
      logisticService,
      currUser
    } = this.state

    const options = [
      { value: PACKAGE_ACTION.EXPORT, label: 'Xuất hàng' },
      { value: PACKAGE_ACTION.IMPORT, label: 'Nhập hàng' },
      { value: PACKAGE_ACTION.RETURN, label: 'Trả hàng' },
    ]
    const BRANCHES = ['HCM', 'HN', 'DAD']
    let totalPacks = 0
    items &&
      items.forEach((element) => {
        let packs = JSON.parse(element.packs) || []
        if (packs.length && packs[0].quantity !== 0) {
          totalPacks = totalPacks + JSON.parse(element.packs).length
        }
      })
    let totalWeight = parseFloat(0)
    items &&
      items.forEach((element) => {
        let packs = JSON.parse(element.packs) || []
        packs.forEach((packInfo) => {
          if (packInfo.weight) {
            totalWeight =
              totalWeight + Rounding(packInfo.weight * packInfo.quantity)
          }
        })
      })
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Barcode</BreadcrumbItem>
        </Breadcrumb>

        <Row>
          <Col sm={12} md={6}>
            <Card>
              <CardHeader tag="h5">
                <i className="fa fa-filter" aria-hidden="true" />
                <span> Filter</span>
              </CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.getItem()
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center mobile-dlock">
                    <div>
                      {startDate && (
                        <div className='wrap-date'>
                            <p>Ngày bắt đầu:{' '}</p>
                              <DatePicker
                                id='fromDate'
                                maxDate={endDate}
                                locale="vi"
                                selected={startDate}
                                onSelect={this.handleDatefromSelect}
                              />
                          </div>
                      )}
                      {endDate && (
                        <div className='wrap-date'>
                          <p>
                            Ngày kết thúc:{' '}
                          </p>
                          <DatePicker
                              id='endDate'
                              locale="vi"
                              minDate={startDate}
                              maxDate={new Date()}
                              selected={endDate}
                              onSelect={this.handleEndDateSelect}
                            />
                          </div>
                      )}
                    </div>
                  </div>
                  {isShowCalendar && (
                    <DateRangePickerCalendar
                      startDate={startDate}
                      endDate={endDate}
                      focus={focus}
                      onStartDateChange={(e) => this.setStartDate(e)}
                      onEndDateChange={(e) => this.setEndDate(e)}
                      onFocusChange={(e) => this.handleFocusChange(e)}
                      locale={vi}
                    />
                  )}

                  <div>
                    <Row>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <Label for="logisticService">
                            Dịch vụ vận chuyển (Services)
                          </Label>
                          <Input
                            placeholder="Chọn hoặc nhập"
                            name="logisticService"
                            type="text"
                            value={logisticService}
                            id="logisticService"
                            onChange={(e) => this.handleService(e)}
                            list="logisticServiceList"
                          />
                          <datalist id="logisticServiceList">
                            {SHIPPING_SERVICES.map((i,index) => (
                              <option value={i} key={index}> {i} </option>
                            ))}
                          </datalist>
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <Label for="logisticService">Hoạt động</Label>
                          <Select
                            options={options}
                            onChange={(value) => this.onSelectChange(value)}
                            id="logisticService"
                            name="logisticService"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={options.find((o) => o.value === type)}
                          />
                        </FormGroup>
                      </Col>
                      {/*<Col sm={12} md={6}>
                        <FormGroup>
                          <Label for="logisticService">Branch</Label>
                          <Select
                              options={BRANCHES}
                              onChange={(value) => this.onSelectChange(value)}
                              id="branch"
                              name="branch"
                              value={BRANCHES.find((o) => o === type)}
                          />
                        </FormGroup>
                      </Col>*/}
                    </Row>

                    <div className="d-flex justify-content-between  mt-3">
                      <div className="d-flex justify-content-between">
                        <span>
                          <strong>Total Pcs:</strong> {totalPacks}
                        </span>
                        <span className="mx-3">
                          <strong>Total Weight (kg):</strong> {totalWeight}
                        </span>
                        <span>
                          <strong>Total Result:</strong> {items.length}
                        </span>
                      </div>
                      <Button className="" type="submit">
                        Tìm kiếm
                      </Button>
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} className="mt-3">
          <div className="d-flex justify-content-between my-4 align-items-center">
            <h2 className='title-page'>Packages List</h2>
            {(items.length > 0 && currUser.role !== USER_ROLE.OPS) ? (
              <Button
                type="button" className="butt btn-w-170 text-uppercase"
                disabled={this.state.disableDownloadButton}
                onClick={() => this.exportData()}
              >
                <i className="mr-1 fa fa-download" aria-hidden="true"></i>
                Tải về
              </Button>
            ) : null}
          </div>
              <Table hover responsive size="" striped>
                <thead className="text-center">
                <tr>
                  <th>#</th>
                  <th>TP Code</th>
                  <th>HawbCode</th>
                  <th>Thời gian SCAN</th>
                  <th>Service</th>
                  <th>Receiver</th>
                  <th>Owner</th>
                  {/*<th>LastSCAN</th>*/}
                  {/*<th>BRANCH</th>*/}
                </tr>
                </thead>
                <tbody className="text-center">
                  {items.length > 0 ?
                    items.map((pkg, i) => (
                      <tr key={i}>
                        <td className="align-middle">{i + 1}</td>
                        <td className=''>
                          <Link to={`/app/packet-detail/${pkg.id}`} target="_blank"
                                rel="noopener noreferrer"> {pkg.kgCode || ''}</Link>
                        </td>
                        <td className="align-middle">
                          <div dangerouslySetInnerHTML={{__html: this.getHawbCode(pkg)}}>
                          </div>
                        </td>
                        <td className="align-middle">
                          <div dangerouslySetInnerHTML={{__html: this.getHawbDate(pkg)}}>
                          </div>
                        </td>

                        <td className="align-middle">
                          {
                            pkg.logisticService
                          }
                        </td>
                        <td>
                          {pkg.receiverName}
                        </td>
                        <td>
                          {pkg.owner}
                        </td>
                        {/*<td>*/}
                        {/*  {pkg.lastScan}*/}
                        {/*</td>*/}
                        {/*<td>*/}
                        {/*  {pkg.branch}*/}
                        {/*</td>*/}
                      </tr>
                    )) :
                    <tr>
                      <td colSpan="100" className='text-center'>
                    Danh sách trống. Vui lòng kiểm tra lại bộ lọc.
                    </td>
                  </tr>}
                </tbody>
              </Table>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Barcode
