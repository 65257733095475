import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Label,
  Button,
  FormGroup,
  Input,
  Col,
  Form,
  FormFeedback,
} from 'reactstrap';
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert';
import s from './forgot-password.module.scss';
import Widget from '../../components/Widget/Widget';
import Footer from "../../components/Footer/Footer";
import jwt from 'jsonwebtoken';
import config from '../../config'
import logo from '../../images/hopdong/logo-tp.png';
import Validator from '../../utils/validator'

class ForgotPassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isFetching: PropTypes.bool,
    location: PropTypes.any, // eslint-disable-line
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    isAuthenticated: false,
    isFetching: false,
    location: {},
    errorMessage: null,
  };

  static isAuthenticated(token) {
    // We check if app runs with backend mode
    if (!config.isBackend && token) return true;
    if (!token) return;
    const date = new Date().getTime() / 1000;
    const data = jwt.decode(token);
    return date < data.exp;
}

  constructor(props) {
    super(props);
    this.state = {
      isShow: {
        password: false,
        passwordAgain: false,
      },
      password: {
        password: '',
        passwordAgain: '',
      },
      isFetching: false,
      errors: {},
      token: ''
    };
    const requiredFields = {
      method: 'isEmpty',
      validWhen: false,
      message: 'The name field is required.'
    }
    const sameField = (value, field, state) => (field.password === value)
    const passwordRules = [
      { field: 'password', ...requiredFields},
      { field: 'passwordAgain', ...requiredFields},
      { field: 'passwordAgain',  method: sameField, validWhen: true, message: 'The password does not match the new password.'},
    ]
    this.validator = {password: new Validator(passwordRules)}
    this.handleUserInput = this.handleUserInput.bind(this);
  }

  handleUserInput = (e) => {
    const { name, value } = e.target;
    const { password } = this.state;
    password[name] = value;
    this.setState({...this.state, password});
  };

  changePasswordType = (type) => {
    const { isShow } = this.state
    switch (type) {
      case 'password':
        this.setState({isShow: { ...isShow, password: !isShow.password}});
        break
      case 'passwordAgain':
        this.setState({isShow: { ...isShow, passwordAgain: !isShow.passwordAgain}});
        break
      default:
        break
    }
  }
  async componentDidMount () {
    const token = new URLSearchParams(this.props.location.search).get("token")
    this.setState({ token })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validator.password.validate(this.state.password)
    this.setState({ errors });
    if (Object.keys(errors).length > 0) return
    const { password, token } = this.state
    this.setState({isFetching: true});
    try {
      const res = await axios.post('/update-password', { ...password, token })
        confirmAlert({
        title: 'Thành công',
        message: res.data.message,
        buttons: [{label: 'Đóng',onClick: () => {}}]});
    } catch (error) {
      console.log(error.response.data.message);
      confirmAlert({
        title: 'Thất bại',
        message: error.response.data.message,
        buttons: [{label: 'Đóng',onClick: () => {}}]});
    } finally {
      this.setState({isFetching: false, password: { password: '', passwordAgain: ''} });
      this.props.history.push('/login');
    }
  }

  render() {
    const { isShow, errors } = this.state;
    const { password, passwordAgain } = this.state.password;

    const {from} = this.props.location.state || {
      from: {pathname: '/app'},
    };

    if (this.props.isAuthenticated) {
      // cant access login page while logged in
      return <Redirect to={from} />;
    }

        return (
          <div className={s.root}>
          <div className={s.wrap + " container px-0 overflow-auto"}>
            <Col xs={12} sm={8} md={5} className={s.loginForm + " ml-auto login-form"}>
              <p className="text-center">
                <img src={logo} alt="LOGO" className={s.loginLogo}/>
              </p>
              <Widget className={s.widget}>
                <h4 className="mt-0">Reset Password</h4>
                <p className="fs-sm text-muted">
                  Nhập mật khẩu mới và xác thực mật khẩu mới của bạn
                </p>
                <Form className="mt" onSubmit={this.handleSubmit}>
                  <FormGroup className="position-relative">
                    <Label for="password">New Password</Label>
                    <Input
                      type={isShow.password ? "text" : "password"}
                      id="password" value={password} name="password" onChange={(e) => this.handleUserInput(e)} invalid={errors && errors.password} />
                      <span className={s.passwordIcon} onClick={() => this.changePasswordType('password')}>
                        <i className={"fa fa-eye" + (isShow.password ? "-slash" : '')} />
                      </span>
                    <FormFeedback>{errors.password}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label for="passwordAgain">Password Again</Label>
                    <Input
                      type={isShow.passwordAgain ? "text" : "password"}
                      id="passwordAgain" value={passwordAgain} name="passwordAgain" onChange={(e) => this.handleUserInput(e)} invalid={errors && errors.passwordAgain} />
                      <span className={s.passwordIcon} onClick={() => this.changePasswordType('passwordAgain')}>
                        <i className={"fa fa-eye" + (isShow.passwordAgain ? "-slash" : '')} />
                      </span>
                    <FormFeedback>{errors.passwordAgain}</FormFeedback>
                  </FormGroup>

                  <div className="d-flex justify-content-between align-items-center">
                  <Link to="/login" className="fs-sm">Login</Link>
                    <div>
                      <Button color="success" size="sm" type="submit" disabled={this.state.isFetching}>
                        {this.state.isFetching ? 'Loading...' : 'Submit'}
                      </Button>
                    </div>
                  </div>
                </Form>
              </Widget>
            </Col>
          </div>
          <Footer className="text-center" />
          </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(ForgotPassword));

