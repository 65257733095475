import {
  LIST_USER_REQUEST, LIST_USER_SUCCESS, LIST_USER_FAILURE,
  CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,DELETE_USER_SUCCESS,DELETE_USER_FAILURE,
  UPDATE_PASSWORD_REQUEST,UPDATE_PASSWORD_SUCCESS,UPDATE_PASSWORD_FAILURE,
} from '../actions/user';

export default function users(
  state = {
    isFetching: false,
    users: {}
  },
  action,
) {
  switch (action.type) {

    /******* Fetch Users */
    case LIST_USER_REQUEST:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        message: null,
      });
    case LIST_USER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        users: action.users,
      });
    case LIST_USER_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        message: action.message || 'get users failed',
      });

    /******* Create User */
    case CREATE_USER_REQUEST:
      return Object.assign({}, state, {
        isCreating: action.isCreating,
        message: null,
      });
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.user],
      }
    case CREATE_USER_FAILURE:
      return Object.assign({}, state, {
        isCreating: action.isCreating,
        message: action.message || 'create user failed',
      });

    /******* Update User */
    case UPDATE_USER_REQUEST:
      return Object.assign({}, state, {
        isCreating: action.isCreating,
        message: null,
      });
    case UPDATE_USER_SUCCESS:
      let updateList = state.users;
      for (var i = 0; i < updateList.rows.length; i++) {
        if (updateList.rows[i].id === action.user.id) {
          updateList.rows[i] = action.user
        }
      }

      return {
        ...state,
        type: UPDATE_USER_SUCCESS,
        isCreating: action.isCreating,
        users: updateList,
      }
    case UPDATE_USER_FAILURE:
      return Object.assign({}, state, {
        isCreating: action.isCreating,
        message: action.message || 'create user failed',
      });

    /******* Delete User */
    case DELETE_USER_REQUEST:
      return Object.assign({}, state, {
        isCreating: action.isCreating,
        message: null,
      });
    case DELETE_USER_SUCCESS:
      let userList = state.users;

      for (i = 0; i < userList.rows.length; i++) {
        if (userList.rows[i].id === action.id.id) {
          userList.rows.splice(i, 1);
        }
      }
      
      return {
        ...state,
        users: userList,
      }
    case DELETE_USER_FAILURE:
      return Object.assign({}, state, {
        isCreating: action.isCreating,
        message: action.message || 'create user failed',
      });
    case UPDATE_PASSWORD_REQUEST:
      return Object.assign({}, state, {
          isFetching: true
      });
    case UPDATE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
          isFetching: false
      });
    case UPDATE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
          isFetching: false
      });
    default:
      return state;
  }

}
