import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody, CardFooter,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, PaginationItem, PaginationLink,
  Row,
  Table
} from "reactstrap";
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import {DOC_TYPE, PACK_TYPE, USER_ROLE} from "../../constants";
import ImageUploading from 'react-images-uploading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import moment from "moment";

class ManageService extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalToggle: false,
      modalEditToggle: false,
      modalToggleUpdateService: false,
      modalServiceInfo: {},
      currUser: {},
      fromDate: '',
      toDate: '',
      createService: {
        name: "",
        note: "",
        type: "all"
      },
      editService: {
        name: "",
        note: "",
        type: "all"
      },
      isEdit: false,
      loading: false,
      search: '',
      serviceType: "",
      images: [],
      imagesEdit: [],
      searchType: "owner"
    }
    this.openEditModal = this.openEditModal.bind(this)
    this.onEditService = this.onEditService.bind(this)
    this.onCreateService = this.onCreateService.bind(this);
    this.onEditServiceChange = this.onEditServiceChange.bind(this);
    this.toggleUpdateService = this.toggleUpdateService.bind(this);
    this.onCreateServiceChange = this.onCreateServiceChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.setImages = this.setImages.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({currUser, createService: {...this.state.createService}})
    await this.getAllService()
  }

  async onCreateService(e) {
    try {
      let {createService} = this.state
      if (!createService.name) {
        toast.error('Tên dịch vụ không được để trống!')
        return
      }
      await axios.post('/create-service', {
        ...createService
      })
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle}));
      await this.getAllService()
      e.preventDefault();
    } catch (err) {
      let message = err.response.data.message || 'Đã có lỗi xảy ra!'
      toast.error(message);
    }
  }

  async onEditService(e) {
    try {
      const {editService} = this.state
      await axios.post(`/update-service/${editService.id}`, {
        ...editService
      })
      this.setState((prevState) => ({modalEditToggle: !prevState.modalEditToggle, images: [], imagesEdit: []}));
      await this.getAllService()
      e.preventDefault();
    } catch (e) {

    }
  }

  toggle() {
    this.setState((prevState, props) => ({modalToggle: !prevState.modalToggle}));
  }

  openEditModal(obj) {
    this.setState({editService: obj})
    this.setState((prevState, props) => ({modalEditToggle: !prevState.modalEditToggle}));
  }

  toggleUpdateService() {

  }

  onCreateServiceChange(e) {
    const {name, value} = e.target;
    let createServiceInfo = this.state.createService

    createServiceInfo[name] = value
    this.setState({createService: createServiceInfo})
  }

  onEditServiceChange(e) {
    const {name, value} = e.target;
    let editServiceInfo = this.state.editService
    editServiceInfo[name] = value
    this.setState({editService: editServiceInfo})
  }

  formatNumber(num) {
    return num
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  searchBy(val) {
    this.setState({serviceType: val})
  }
  searchByCode(val) {
    this.setState({searchType: val})
  }
  async onDeleteService(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this service?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const res = await axios.get(`/delete-service/${id}`)
              console.log(res)
              await self.getAllService()
            },
          },
          {
            label: "No",
            onClick: () => {
            },
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  async getAllService(params = {}) {
    try {
      this.setState({loading: true})
      let url = `/get-all-service`
      const res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllService({page: pageNumber})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER | role === USER_ROLE.MANAGER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.MANAGER || role === USER_ROLE.Service) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  handleInput = (e) => {
    const {value} = e.target
    this.setState({search: value})
  }
  onSearch = (e) => {
    this.getAllService({search: this.state.search})
  }

  onChange2 = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    this.setImages(imageList);
  };

  setImages(imageList) {
    this.setState({images: imageList})
  }

  onImageEditRemove = (index) => {
    let { imagesEdit } = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }

  showDateTime(obj) {
    if (!obj.fromDate || !obj.toDate) return ''
    return `${moment(obj.fromDate).format('DD/MM/YYYY')} - ${moment(obj.toDate).format('DD/MM/YYYY')}`
  }


  render() {
    const {data, editService} = this.state
    const serviceType = {
      DIV: "Khách lẻ",
      ODD: "Khách sĩ",
    }
    const maxNumber = 12;
    return (
      <div>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active>Quản lý Dịch Vụ Vận Chuyển (Services)</BreadcrumbItem>
        </Breadcrumb>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Danh sách Dịch vụ</span>
            <Button type="button" className="butt ml-auto" onClick={this.toggle}>+ Tạo mới</Button>
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Dịch vụ</th>
                <th>Country</th>
                <th>City</th>
                <th>PostCode</th>
                <th>Convert Number</th>
                <th>ROLE</th>
                <th>Note</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {data.list &&
                data.list.map((obj, i) => (
                  <tr key={i + 1}>
                    <td className={'text-center'}>{i + 1}</td>
                     <td className={'text-center'}>{obj.name || ''}</td>
                    <td>{obj.country || ''}</td>
                    <td>{obj.city || ''}</td>
                    <td>{obj.postcode || ''}</td>
                    <td>{obj.convertNumber || ''}</td>
                    <td>{obj.type || 'all'}</td>
                    <td>{obj.note || ''}</td>
                    <td style={{width: '200px'}}>
                      <Button type="button" color="info" style={{marginTop: 0}}
                              onClick={(e) => this.openEditModal(obj)}>Edit</Button>
                      <Button style={{marginLeft: '5px'}} type="button" color="danger"
                              onClick={(e) => this.onDeleteService(obj.id)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>

          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={data.page}
            itemsCountPerPage={data.size}
            totalItemsCount={data.total}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            hideFirstLastPages={true}
          />

        </Card>
        <Row>
          {/* Toggle Form Edit Service */}
          <Modal isOpen={this.state.modalEditToggle} toggle={this.openEditModal}>
            <Form>
              <ModalHeader toggle={this.openEditModal}>Cập nhật dịch vụ</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Tên dịch vụ *</Label>
                  <Input type="text" name="name" id="name" read-only value={editService.name} placeholder="Tên Service"
                         onChange={this.onEditServiceChange}/>
                </FormGroup>

                <FormGroup>
                  <Label for="country">Country</Label>
                  <Input type="text" name="country" id="country" value={editService.country} placeholder="country"
                         onChange={this.onEditServiceChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="city">City</Label>
                  <Input type="text" name="city" id="city" value={editService.city} placeholder="city"
                         onChange={this.onEditServiceChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="postcode">PostCode</Label>
                  <Input type="text" name="postcode" id="postcode" value={editService.postcode} placeholder="PostCode"
                         onChange={this.onEditServiceChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="postcode">Convert Number</Label>
                  <Input type="number" name="convertNumber" id="convertNumber" value={editService.convertNumber} placeholder="Convert Number"
                         onChange={this.onEditServiceChange}/>
                </FormGroup>
                <FormGroup row tag="fieldset" style={{padding: "0 15px"}}>
                  <Label>Hiển thị cho ROLE &nbsp; &nbsp;</Label>
                  <FormGroup check style={{marginRight: "30px"}}>
                    <Input
                      name="type"
                      type="radio"
                      value='all'
                      checked={this.state.editService.type === 'all' || !this.state.editService.type}
                      onChange={this.onEditServiceChange}
                      style={{marginRight: "15px"}}
                    />
                    <Label check>
                      ALL
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="type"
                      type="radio"
                      value='fwd'
                      checked={
                        this.state.editService.type === 'fwd'
                      }
                      onChange={this.onEditServiceChange}
                      style={{marginRight: "15px"}}
                    />
                    <Label check>
                      FWD
                    </Label>
                  </FormGroup>

                </FormGroup>
                <FormGroup>
                  <Label for="note">Note</Label>
                  <Input type="textarea" name="note" id="note" value={editService.note} placeholder="note"
                         onChange={this.onEditServiceChange}/>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditService(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>


          <Modal isOpen={this.state.modalToggle} toggle={this.toggle}>
            <Form>
              <ModalHeader toggle={this.toggle}>Thêm dịch vụ vận chuyển (Services)</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Tên dịch vụ</Label>
                  <Input type="text" name="name" id="name" placeholder="Tên Service"
                         onChange={this.onCreateServiceChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="country">Country</Label>
                  <Input type="text" name="country" id="country" placeholder="country"
                         onChange={this.onCreateServiceChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="city">City</Label>
                  <Input type="text" name="city" id="city" placeholder="city"
                         onChange={this.onCreateServiceChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="postcode">PostCode</Label>
                  <Input type="text" name="postcode" id="postcode" placeholder="PostCode"
                         onChange={this.onCreateServiceChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="postcode">Convert Number</Label>
                  <Input type="number" name="convertNumber" id="convertNumber" placeholder="Convert Number"
                         onChange={this.onCreateServiceChange}/>
                </FormGroup>
                <FormGroup row tag="fieldset" style={{padding: "0 15px"}}>
                  <Label>Hiển thị cho ROLE &nbsp; &nbsp;</Label>
                  <FormGroup check style={{marginRight: "30px"}}>
                    <Input
                      name="type"
                      type="radio"
                      value='all'
                      checked={this.state.createService.type === 'all'}
                      onChange={this.onCreateServiceChange}
                      style={{marginRight: "15px"}}
                    />
                    <Label check>
                      ALL
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="type"
                      type="radio"
                      value='fwd'
                      checked={
                        this.state.createService.type === 'fwd'
                      }
                      onChange={this.onCreateServiceChange}
                      style={{marginRight: "15px"}}
                    />
                    <Label check>
                      FWD
                    </Label>
                  </FormGroup>

                </FormGroup>


                <FormGroup>
                  <Label for="note">Note</Label>
                  <Input type="textarea" name="note" id="note" placeholder="note"
                         onChange={this.onCreateServiceChange}/>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onCreateService(e)}>Create</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

        </Row>
      </div>
    )
  }
}

export default ManageService
