import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  FormGroup,
  Input, Table,
  Row, Col, Modal, Form, ModalHeader, ModalBody, Label, ModalFooter
} from "reactstrap";
import jwt from "jsonwebtoken";
import {USER_ROLE} from "../../constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
const fileReader = new FileReader();
const urlParams = new URLSearchParams(window.location.search);
const keySelected = urlParams.get('role');
class ManagePricingDetailDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      modalToggle: false,
      modalEditToggle: false,
      modalToggleUpdatePricing: false,
      modalPricingInfo: {},
      currUser: {},
      fromDate: '',
      toDate: '',
      createPricing: {
        name: "",
        note: "",
      },
      editPricing: {
        name: "",
        note: "",
      },
      isEdit: false,
      loading: false,
      search: '',
      pricingType: "",
      images: [],
      imagesEdit: [],
      searchType: "owner",
      moneyPricingDetail: {},
      keySelected: keySelected || 'Sale',
      servicePricingItem: {},
      services: []
    }
    this.openEditModal = this.openEditModal.bind(this)
    this.onEditPricing = this.onEditPricing.bind(this)
    this.onCreatePricing = this.onCreatePricing.bind(this);
    this.onEditPricingChange = this.onEditPricingChange.bind(this);
    this.toggleUpdatePricing = this.toggleUpdatePricing.bind(this);
    this.onCreatePricingChange = this.onCreatePricingChange.bind(this);
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleDatetoSelect2 = this.handleDatetoSelect2.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.setImages = this.setImages.bind(this);
    this.onChooseFile = this.onChooseFile.bind(this);
    this.uploadMoneyPricing = this.uploadMoneyPricing.bind(this);
    this.setKey = this.setKey.bind(this);
    this.onUpdateServicePricingJSON = this.onUpdateServicePricingJSON.bind(this);
    this.onUpdateServicePricing = this.onUpdateServicePricing.bind(this);
    this.getJSONData = this.getJSONData.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)

    await this.setState({currUser, createPricing: {...this.state.createPricing, owner: currUser.displayName}})
    await this.getAllService()
    await this.getMoneyPricingById()
    await this.getAllServicePricing()
  }

  async getMoneyPricingById(params = {}) {
    try {
      let { moneyPricingId } = this.props.match.params;
      const res = await axios.get(`/get-money-pricing/${moneyPricingId}`, )
      if (res && res.data) {
        this.setState({moneyPricingDetail: res.data})
      }
    } catch (e) {
      console.error(e)
    }
  }

  async getAllServicePricing(params = {}) {
    try {
      let { moneyPricingId } = this.props.match.params;
      this.setState({loading: true})
      let url = `/get-all-service-pricing?moneyPricingId=${moneyPricingId}`
      const res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async onCreatePricing(e) {
    try {
      let {createPricing} = this.state
      await axios.post('/create-money-pricing', {
        ...createPricing,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      })
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle}));
      await this.getAllPricing()
      e.preventDefault();
    } catch (err) {
      let message = err.response.data.message || 'Đã có lỗi xảy ra!'
      toast.error(message);
    }
  }

  async onUpdateServicePricing(e) {
    try {
      let {servicePricingItem} = this.state
      await axios.post(`/update-service-pricing/${servicePricingItem.id}`, {
        ...servicePricingItem
      })
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle}));
      await this.getAllServicePricing()
      e.preventDefault();
    } catch (err) {
      let message = err.response.data.message || 'Đã có lỗi xảy ra!'
      toast.error(message);
    }
  }

  async onEditPricing(e) {
    try {
      const {editPricing} = this.state
      await axios.post(`/update-money-pricing/${editPricing.id}`, {
        ...editPricing,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      })
      this.setState((prevState) => ({modalEditToggle: !prevState.modalEditToggle, images: [], imagesEdit: []}));
      await this.getAllPricing()
      e.preventDefault();
    } catch (e) {

    }
  }
  handleDatefromSelect(date) {
    this.setState({fromDate: date});
  }
  handleDatetoSelect2(date) {
    this.setState({toDate: date});
  }


  toggle(item) {
    this.setState((prevState, props) => ({modalToggle: !prevState.modalToggle}));
    if (item) this.setState({servicePricingItem: item})
  }

  openEditModal(obj) {
    this.setState({editPricing: obj, fromDate: new Date(obj.fromDate), toDate: new Date(obj.toDate)})
    this.setState((prevState, props) => ({modalEditToggle: !prevState.modalEditToggle}));
  }

  toggleUpdatePricing() {

  }

  onCreatePricingChange(e) {
    const {name, value} = e.target;
    let createPricingInfo = this.state.createPricing

    createPricingInfo[name] = value
    this.setState({createPricing: createPricingInfo})
  }

  onEditPricingChange(e) {
    const {name, value} = e.target;
    let editPricingInfo = this.state.editPricing
    editPricingInfo[name] = value
    this.setState({editPricing: editPricingInfo})
  }

  formatNumber(num) {
    if (num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    return 0
  }

  formatKey(key) {
    return parseFloat(key).toFixed(1)
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  searchBy(val) {
    this.setState({pricingType: val})
  }
  searchByCode(val) {
    this.setState({searchType: val})
  }

  async getAllPricing(params = {}) {
    try {
      this.setState({loading: true})
      let url = `/get-all-money-pricing?page=${params.page || 1}`
      const res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllPricing({page: pageNumber})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER | role === USER_ROLE.MANAGER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.MANAGER || role === USER_ROLE.CUSTOMER) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  handleInput = (e) => {
    const {value} = e.target
    this.setState({search: value})
  }
  onSearch = (e) => {
    this.getAllPricing({search: this.state.search})
  }

  async getAllService(params = {}) {
    try {
      let url = `/get-all-service?limit=1000`
      const res = await axios.get(url)
      if (res && res.data) {
        this.setState({services: res.data?.list})
      }
    } catch (e) {
      console.error(e)
    }
  }

  onChange2 = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    this.setImages(imageList);
  };

  setImages(imageList) {
    this.setState({images: imageList})
  }

  onImageEditRemove = (index) => {
    let { imagesEdit } = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }

  showDateTime(obj) {
    if (!obj.fromDate || !obj.toDate) return ''
    return `${moment(obj.fromDate).format('DD/MM/YYYY')} - ${moment(obj.toDate).format('DD/MM/YYYY')}`
  }

  onChooseFile(e) {
    this.setState({file: e.target.files[0]})
  }

  setKey = (key) => () => {
    console.log(key)
    this.setState({keySelected: key})
    console.log(this.state.keySelected)
  }

  CSVstring_to_Array(fileContent, delimiter = ',') {
    const rows = fileContent.split('\n');
    const headers = rows[0].split(',');
    const headerArr = [];

    for (let i = 0; i < headers.length; i += 2) {
      const obj = {
        service: headers[i],
        type: headers[i + 1]
      };

      headerArr.push(obj);
    }
    console.log(headerArr)
    let data = []
    for (let k = 0; k < headerArr.length; k++) {
      let pricings = {}
      for (let i = 1; i < rows.length; i++) {
        const row = rows[i].split(',');
        if (row.length === 0) continue
        pricings = {...pricings, [row[k*2]]: row[k*2+1]?.toString()?.replace(/\./g, "")}
      }
      data.push({
        ...headerArr[k],
        pricings
      })
    }
    return data;
  };

  async uploadMoneyPricing() {
    const {file} = this.state
    let self = this
    if (!file) {
      toast.error('Vui lòng chọn file để upload')
      return
    }

    fileReader.readAsText(file);
    fileReader.onload = async function (event) {
      const csvOutput = event.target.result;
      let arr = self.CSVstring_to_Array(csvOutput)
      arr = arr.map(item => {
        return {
          priceType: item.type?.toUpperCase(),
          serviceName: item.service,
          moneyPricingId: self.state.moneyPricingDetail.id,
          priceJSON: JSON.stringify(item.pricings)
        }
      })

      try {
        await axios.post('/create-service-pricing', arr)
        toast.success('Upload bảng giá thành công')

        await self.getAllServicePricing()
      } catch (e) {
        toast.error('Upload bảng giá thất bại')
        console.error(e)
      }
    };
  }

  getPriceArr(data) {
    const { services } = this.state
    const arr = data.filter(item => item.priceType?.toLowerCase() === this.state.keySelected.toLowerCase())?.[0]
    const response = services.map(item => {
      const curData = arr?.arr?.filter(item2 => item2.serviceName === item.name)?.[0] || []
      let sortedArr = []
      if (curData?.priceJSON) {
        let obj = JSON.parse(curData.priceJSON)
        const sortedKeys = Object.keys(obj).sort((a, b) => parseFloat(a) - parseFloat(b));
        sortedKeys.forEach(key => {
          sortedArr.push({key, value: obj[key]})
        });
      }
      return {
        ...item,
        arr: {...curData, sortedArr},
        hasData: sortedArr?.length > 0 || false
      }
    })
    console.log(response)
    return response

  }

  getJSONData(item) {

  }

  onUpdateServicePricingJSON(e) {
    const {name, value} = e.target;
    let createPricingInfo = this.state.servicePricingItem
    let pricings = createPricingInfo.priceJSON
    if (value) {
      const cleanedData = value.replace(/[\t\n]/g, ' ');
      const values = cleanedData.split(' ');
      console.log(values)
      const parsedData = {};
      for (let i = 0; i < values.length; i += 2) {
        const key = values[i];
        const value = values[i + 1]?.toString()?.replace(/\./g, "");
        if (key && value && !isNaN(value)) {
          parsedData[key] = value;
        }
      }
      pricings = pricings? JSON.parse(pricings) : {}
      pricings = {...pricings, ...parsedData}
      this.setState({servicePricingItem: {...createPricingInfo, priceJSON: JSON.stringify(pricings)}})
    }
  }

  render() {
    const {data} = this.state
    return (
      <div>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active>Chi tiết bảng giá</BreadcrumbItem>
        </Breadcrumb>
        <div className="card card-primary">
          <div className="card-header">{this.state.moneyPricingDetail.name}</div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <p><strong>Ngày áp dụng:</strong> {this.showDateTime(this.state.moneyPricingDetail)}</p>
                <p><strong>Ghi chú:</strong> {this.state.moneyPricingDetail.note}</p>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Input onChange={e => this.onChooseFile(e)}
                         accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                         name="file" type="file" />
                </FormGroup>
                <Button type="button" color="primary" onClick={() => this.uploadMoneyPricing()}>Upload Bảng Giá</Button>
                <a href="/pricing-sample.csv" className="text text-primary pl-3">Tải bảng giá mẫu .csv</a>
              </div>
            </div>
          </div>

        </div>

        <div className="card card-primary">
          <div className="card-header d-flex align-items-center">
            <Button className={`transparent p-2 mr-3`} color={`${this.state.keySelected.toLowerCase() ===  'sale' ? 'primary' : ''}`}  type="button" onClick={() => {this.setState({keySelected: 'Sale'})}}>Giá Sale</Button>
            <Button className={`transparent p-2 mr-3`} color={`${this.state.keySelected.toLowerCase() ===  'f0'? 'primary' : ''}`}  type="button" onClick={() => {this.setState({keySelected: 'F0'})}}>Giá Đại lý</Button>
          </div>
          <div className="pt-2">
            <Row>
              {
                this.getPriceArr(data)?.map((item, index) => {
                  return (
                    <Col md={3} key={index}>
                      <div className="card">
                        <div className="card-header">
                          <h5 className="w-100 card-title text-uppercase d-flex justify-content-center align-items-center text-center"><strong>{item.name}</strong>
                            <Link to={`/app/edit-manage-pricing-detail/${this.props.match.params.moneyPricingId}/${item.name}/${this.state.keySelected}`} className={'p-2'}>
                              <i className="fa fa-pencil" />
                            </Link>
                          </h5>
                        </div>
                        <div className="card-body p-0">
                          <Table bordered>
                            <thead>
                            <tr>
                              <th className={'text-center'}>Số Kg</th>
                              <th className={'text-center'}>Giá tiền
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                              item.arr?.sortedArr ? item.arr.sortedArr.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className={'text-center'}>Giá {this.formatKey(item.key)} kg</td>
                                    <td className={'text-center'}>{this.formatNumber(item.value)}</td>
                                  </tr>
                                )
                              }) : <tr><td colSpan={2} className={'text-center'}>Chưa có dữ liệu</td></tr>
                            }
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }

            </Row>
          </div>
        </div>

        <Modal isOpen={this.state.modalToggle} toggle={this.toggle}>
          <Form>
            <ModalHeader toggle={this.toggle}>
              Cập nhật giá cho dịch vụ
              <div className={'mt-2'}>{this.state.servicePricingItem?.name || ''} - Giá {this.state.servicePricingItem?.priceType || ''}</div>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="note">Dán mã vào bên dưới</Label>
                <Input type="textarea" name="note" rows={8} id="note" placeholder="0.5 900.000"
                       onChange={this.onUpdateServicePricingJSON}/>
              </FormGroup>
              <note>
                <p>Chú ý: Cập nhật mã với định dạng <strong>[Số Kg] [Giá tiền]</strong></p>
              </note>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.onUpdateServicePricing(e)}>Create</Button>{' '}
              <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default ManagePricingDetailDetail
