import React from 'react'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  Input,
  Button,
  Table,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import fileDownload from 'js-file-download'
import Select from 'react-select'
import {format} from 'date-fns'
import {vi} from 'date-fns/locale'
import 'react-nice-dates/build/style.css'
import {DateRangePickerCalendar, START_DATE} from 'react-nice-dates'
import {DOC_TYPE, PACK_TYPE, SHIPPING_SERVICES, USER_ROLE} from '../../constants'
import CurrencyInput from 'react-currency-input-field'
import Rounding from '../../utils/rounding'
import moment from 'moment';
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import {confirmAlert} from "react-confirm-alert";
import {deletePackage} from "../../actions/package";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Sparklines, SparklinesBars} from "react-sparklines";

class DashboardMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      kgCode: '',
      date: '',
      filterDateType: '',
      filterOptionsType: '',
      searchValue: '',
      filterDateTypeValidation: false,
      filterOptionsTypeValidation: false,
      items: [],
      search: '',
      disableDownloadButton: false,
      isShowCalendar: false, /*startDate: moment().startOf('month').format('DD-MM-YYYY HH:mm'),
      endDate: moment().endOf('month').format('DD-MM-YYYY HH:mm'),*/
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // get last 30 days
      endDate: new Date(),
      focus: START_DATE,
      modalToggleUpdate: false,
      isShowStatusNone: true,
      pkg: {},
      index: null,
      loading: false,
      currUser: {},
      data: {
        total: 0, list: [], size: 1, from: 0, page: 1
      },
      totalPrice: {
        totalLoiNhuan: 0, chiPhiPhatSinh: 0, chiPhiThung: 0, chiPhiQuaDoiTac: 0, totalBh: 0, sttk: 0, stt: 0, tn: 0
      },
      customerData: [],
      searchBy: 'kgCode',
      searchBranch: '',
      searchStatus: '',
      listUsers: [],
      BRANCH: ['HCM', 'HN', 'DAD'],
    }
    this.handleInput = this.handleInput.bind(this)
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this)
    this.toggleUpdateItem = this.toggleUpdateItem.bind(this)
    this.onUpdateDashboard = this.onUpdateDashboard.bind(this)
    this.onRemovePackageDetails = this.onRemovePackageDetails.bind(this)
    this.onUpdateSearchValue = this.onUpdateSearchValue.bind(this)
    this.getAllReport = this.getAllReport.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.onDeletePackage = this.onDeletePackage.bind(this)
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleDatetoSelect2 = this.handleDatetoSelect2.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.changeBranch = this.changeBranch.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    console.log('pageNumber ', pageNumber)
    this.getAllReport({page: pageNumber})
  }

  toggleUpdateItem() {
    this.setState({
      modalToggleUpdate: !this.state.modalToggleUpdate,
    })
  }

  searchBy(val) {
    this.setState({search: val})
  }
  changeBranch(val) {
    this.setState({searchBranch: val})
  }
  changeStatus(val) {
    this.setState({searchStatus: val})
  }


  onUpdateDashboard(item, i) {
    this.setState({
      pkg: item, index: i, modalToggleUpdate: !this.state.modalToggleUpdate,
    })
  }

  onDeletePackage(item, i) {
    const {data} = this.state
    confirmAlert({
      title: 'Xác nhận Xoá', message: 'Bạn có chắc muốn xoá thông tin kiện hàng này?', buttons: [{
        label: 'Có', onClick: async () => {
          try {
            await axios.get(`/delete-package/${item.id}`)
            await this.getAllReport({page: data.page})
          } catch (e) {

          }
        },
      }, {
        label: 'Không', onClick: () => {
        },
      },],
    });
  }

  handleDatetoSelect(date) {
    this.setState({date})
  }

  handleInput(e) {
    this.setState({search: e.target.value})
  }

  onUpdateSearchValue(e) {
    this.setState({searchValue: e.target.value})
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({currUser, createBillup: {...this.state.createBillup, owner: currUser.displayName}})
    this.getAllUsers()
    await this.getAllReport()
    await this.getCustomerReport()
  }

  async getAllReport(params = {}) {
    try {
      const self = this
      const {data} = this.state
      params.page = params.page || data?.page

      console.log('params.page ', params.page, data.page)

      this.setState({loading: true})
      const {} = params
      const {startDate, endDate, search = '', searchBy = 'kgCode', searchBranch, searchStatus} = this.state;
      let res;
      let url = `/get-dashboard-report?startDate=${startDate}&endDate=${endDate}&owner=${this.state.search || ''}&searchBranch=${searchBranch}&searchStatus=${searchStatus}`;
      res = await axios.get(url)
      if (res && res.data) {
        this.setState({data: res.data})
      }
      this.setState({loading: false})
      //await this.getAllReports({})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async getCustomerReport(params = {}) {
    try {
      const {startDate, endDate, search = '', searchBy = 'kgCode', searchBranch, searchStatus} = this.state;
      let res;
      let url = `/get-customer-report?startDate=${startDate}&endDate=${endDate}&owner=${this.state.search || ''}&searchBranch=${searchBranch}&searchStatus=${searchStatus}`;
      res = await axios.get(url)
      if (res && res.data) {
        this.setState({customerData: res.data})
      }
    } catch (e) {
      console.error(e)
    }
  }
  async getAllUsers(params = {}) {
    try {
      let res;
      res = await axios.get('/all-users')
      if (res && res.data) {
        this.setState({listUsers: res.data})
      }
    } catch (e) {
    }
  }

  async getAllReports(params = {}) {
    try {
      const self = this
      this.setState({loading: true})
      const {} = params
      const {data} = this.state
      params.page = params.page !== 1 ? params.page : data?.page
      const {startDate, endDate, search = '', searchBy = 'kgCode', searchBranch = '', searchStatus = ''} = this.state;
      let res;
      let url = `/get-all-reports?page=${params.page || 1}&search=${search}&startDate=${startDate}&endDate=${endDate}&searchBy=${searchBy}&searchBranch=${searchBranch}&searchStatus=${searchStatus}`;
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.MANAGER) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      res = await axios.get(url)

      if (res && res.data && res.data.length) {
        let totalLoiNhuan = 0, chiPhiPhatSinh = 0, chiPhiThung = 0, totalBh = 0, chiPhiQuaDoiTac = 0, sttk = 0, stt = 0,
          tn = 0;
        res.data.forEach(item => {
          totalLoiNhuan += self.calLoiNhuan(item) || 0
          totalBh += self.calBhPrice(item) || 0
          chiPhiPhatSinh += parseFloat(item.paymentBanking || 0)
          chiPhiThung += self.getChiPhiThung(item) || 0
          chiPhiQuaDoiTac += parseInt(item.reportName || 0)
          sttk += parseFloat(self.getTienThu(item)) || 0
          stt += parseFloat(item.paymentCash || 0)
          tn += parseFloat(self.getTongNo(item))
        })
        self.setState({
          totalPrice: {
            totalLoiNhuan, chiPhiThung, chiPhiPhatSinh, chiPhiQuaDoiTac, totalBh, sttk, stt, tn
          }
        })
      }
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  async onSubmit() {
    const {items} = this.state
    let {name} = this.props.match.params
    const {data} = await axios.post(`/reports/${name}/managed-package`, {
      kgCode: this.state.kgCode, reportAt: new Date(),
    })
    this.setState({items: [...items, data]})
  }

  async exportData() {
    await this.setState({disableDownloadButton: true})
    //let { name } = this.props.match.params
    const {filterDateType, startDate, endDate, searchValue, filterOptionsType, isShowStatusNone, searchBranch, searchStatus} = this.state
    /*let qs = `name=${name}`
    if (filterDateType) {
      qs += `&datetype=${filterDateType}&startDate=${startDate}&endDate=${endDate}`
    }
    if (searchValue && filterOptionsType) {
      qs += `&${filterOptionsType}=${searchValue}`
    }
    if (isShowStatusNone) {
      qs += `&isShowStatusNone=${isShowStatusNone}`
    }*/
    let qs = `&datetype=createdAt&startDate=${startDate}&endDate=${endDate}&searchBy=${this.state.searchBy}&search=${this.state.search}&searchBranch=${searchBranch}&searchStatus=${searchStatus}`
    if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
      qs += `&owner=${this.state.currUser.displayName}`
    }
    axios
      .get(`/download-report?${qs}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,)
        this.setState({disableDownloadButton: false})
      })
      .catch(() => {
        this.setState({disableDownloadButton: false})
      })
    return
  }

  handleDatefromSelect(date) {
    this.setState({startDate: date});
  }

  handleDatetoSelect2(date) {
    this.setState({endDate: date});
  }

  async onChangeInput(e) {
    const {name, value} = e.target
    const item = {...this.state.pkg}
    item[name] = value
    await this.setState({pkg: item})
  }

  async onValueChange(value, name) {
    const item = {...this.state.pkg}
    item[name] = value
    await this.setState({pkg: item})
  }

  async onRemovePackageDetails(i) {
    const {id} = this.state.items[i]
    axios
      .put(`/packages/${id}/managed-report`, {})
      .then(async (res) => {
        toast.success('Đã xoá ra khỏi báo cáo')
        const {items} = this.state
        const list = [...items]
        list.splice(i, 1)
        await this.setState({items: list})
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Đã xảy ra lỗi vui lòng thử lại sau.')
        }
      })
    return
  }

  async onUpdatePackageDetails() {
    const {
      id,
      note,
      paymentCash,
      paymentBanking,
      paymentCod,
      logisticService,
      receiverCountry,
      senderCompanyName,
      logisticCode,
      receiverName,
      paymentNum,
      chargeableWeight,
      reportName,
      reportStatus,
      kgCode,
      totalPrice
    } = this.state.pkg
    const {data} = this.state
    axios
      .put('/packages/' + id, {
        note,
        paymentCash,
        paymentBanking,
        paymentCod,
        logisticService,
        receiverCountry,
        senderCompanyName,
        logisticCode,
        receiverName,
        paymentNum,
        chargeableWeight,
        reportName,
        reportStatus,
        totalPrice
      })
      .then(async (res) => {
        toast.success('Cập nhật đơn hàng thành công')
        const {pkg, items, index} = this.state
        const list = [...items]
        list[index] = pkg
        await this.setState({items: list})
        this.toggleUpdateItem()
        await this.getAllReport({page: data.page})
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Đã xảy ra lỗi vui lòng thử lại sau.')
        }
      })


    // update report status
    let code = kgCode.replace('TP', '')
    await axios.post(`/update-billup/${code}`, {
      statusPayment: reportStatus
    })

    return
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  onFilterChange = (e) => {
    const {value} = e
    this.setState({filterOptionsType: value})
  }

  setDate = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({focus})
  }

  setStartDate(startDate) {
    this.setState({startDate})
  }

  setEndDate(endDate) {
    this.setState({endDate})
  }

  async handleShowResult(e) {
    const {checked} = e.target
    await this.setState({isShowStatusNone: checked})
    this.filterData()
  }

  async filterData() {
    await this.getAllReport();
    await this.getCustomerReport()
  }

  totalNum(packages) {
    const packs = JSON.parse(packages.packs || '{}')
    if (Object.keys(packs).length > 0) {
      return (packs.reduce(function (a, b) {
          return a + parseFloat(b['quantity'])
        }, 0) || 0)
    }
    return 0
  }

  totalWeight(packages) {
    if (packages.chargeableWeight) return packages.chargeableWeight
    const packs = JSON.parse(packages.packs || '{}')
    if (Object.keys(packs).length > 0) {
      return (Rounding(packs.reduce(function (a, b) {
          return a + parseFloat(b['chargedWeight'])
        }, 0),) || '')
      /*return (
        Math.round(
          packs.reduce(function (a, b) {
            return a + parseFloat(b['chargedWeight'])
          }, 0),
        ) || 0
      )*/
    }
    return 0
  }

  isExpired(item) {
    const updatedDate = new Date(item.createdAt)
    updatedDate.setDate(updatedDate.getDate() + 1);
    const now = new Date()
    return (updatedDate.getTime() <= now.getTime()) && !item.paymentBanking && !item.paymentCash
  }

  getBillKg(billup) {
    return billup.weight > billup.weight2 ? billup.weight : billup.weight2
  }

  getTienThu(item) {
    const {billup} = item
    if (billup) {
      if (billup.bhPrice && billup.totalPrice) {
        return billup.bhPrice + billup.totalPrice
      }
      return billup.totalPrice || 0
    }
    return item.totalPrice || 0
  }

  getChiPhiThung(item) {
    let totalPacks = 0
    if (item.packageType === PACK_TYPE) {
      var packs = JSON.parse(item.packs)
      totalPacks = packs.reduce(function (a, b) {
        return a + parseInt(b['quantity'])
      }, 0)
    } else {
      totalPacks = item.docCount || 0
    }
    return parseFloat(totalPacks) * 50000
  }

  getTongNo(item) {
    let tongthu = parseFloat(this.getTienThu(item) || 0)
    let dathu = parseFloat(item.paymentCash || 0)
    return tongthu - dathu
  }

  formatNumber(num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }

  totalChiPhi(item) {
    return parseFloat(item.paymentBanking || 0) + this.getChiPhiThung(item) + parseFloat(item.reportName || 0)
  }

  calLoiNhuan(item) {
    let val = 0
    if (item.billup) {
      val = item.billup.bhPrice || 0
    }
    return parseFloat(this.getTienThu(item) - this.totalChiPhi(item))
  }

  calBhPrice(item) {
    let val = 0
    if (item.billup) {
      val = item.billup.bhPrice || 0
    }
    return val;
  }

  getPaymentStatus(pkg) {
    let text = pkg.reportStatus
    if (pkg.billup && pkg.billup.statusPayment) {
      text = pkg.billup.statusPayment
    }

    if (text === 'Chưa hoàn tất thu chi') {
      text = 'Chưa thanh toán'
    }
    if (text === "Đã hoàn tất thu chi") {
      text = 'Đã thanh toán'
    }
    return text
  }

  render() {
    const {
      kgCode,
      items,
      filterDateType,
      startDate,
      endDate,
      focus,
      isShowCalendar,
      pkg,
      searchValue,
      filterOptionsType,
      isShowStatusNone,
      data
    } = this.state
    let paymentCodTotal = 0, paymentCashTotal = 0, paymentBankingTotal = 0
    if (items && items.length > 0) {
      paymentCodTotal = items.reduce(function (a, b) {
        return b ? a + parseFloat(b.paymentCod || 0) : 0
      }, 0)
      paymentCashTotal = items.reduce(function (a, b) {
        return b ? a + parseFloat(b.paymentCash || 0) : 0
      }, 0)
      paymentBankingTotal = items.reduce(function (a, b) {
        return b ? a + parseFloat(b.paymentBanking || 0) : 0
      }, 0)
    }
    const options = [{value: 'createdAt', label: 'Ngày Tạo'}, {value: 'reportAt', label: 'Ngày Tạo Báo Cáo'},]
    const filterOptions = [{value: 'code', label: 'TP Bill'}, {
      value: 'senderCompanyName',
      label: 'Người gửi'
    }, {value: 'receiverCompanyName', label: 'Người nhận'},]

    return (<>
        <Row>
          <Col sm={12} md={12}>
            <Card>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.filterData()
                  }}
                >
                  <Row className={'align-items-center'}>
                    <Col sm={12}>
                      <div className={'d-flex justify-content-start align-items-center'}>
                        <Label style={{marginRight: '8px'}}>Search By: </Label>
                        {
                          (this.state.currUser.role === USER_ROLE.ADMIN || this.state.currUser.role === USER_ROLE.ACCOUNTANT) && (
                            <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                                    onChange={(e) => this.searchBy(e.target.value)}
                            >
                              <option value="">Danh sách User</option>
                              {this.state.listUsers.map((i,index) => (
                                <option value={i.displayName} key={index}> {i.displayName} </option>
                              ))}
                            </select>
                          )

                        }

                        <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                                onChange={(e) => this.changeBranch(e.target.value)}
                        >
                          <option value="">Tất cả Chi Nhánh</option>
                          {this.state.BRANCH.map((i,index) => (
                            <option value={i} key={index}> {i} </option>
                          ))}
                        </select>
                        <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                                onChange={(e) => this.changeStatus(e.target.value)}
                        >
                          <option value="">Tình trạng thanh toán</option>
                          <option value="">Tất cả</option>
                          <option value="Chưa thanh toán" key="1">Chưa thanh toán</option>
                          <option value="Đã thanh toán" key="2">Đã thanh toán</option>
                          <option value="Công nợ" key="3">Công nợ</option>
                        </select>
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mt-3 d-flex justify-content-start align-items-center">
                        <div className="mr-2" style={{display: "flex", maxWidth: "200px"}}>
                          <InputGroup>
                            <label className='mr-sm-2' htmlFor='fromDate'>
                              From Date
                            </label>
                            <DatePicker
                              id='fromDate'
                              selected={this.state.startDate}
                              onSelect={this.handleDatefromSelect}
                              className="form-control"
                            />
                          </InputGroup>
                        </div>
                        <div className="mr-2" style={{display: "flex", maxWidth: "200px"}}>
                          <InputGroup>
                            <label className='mr-sm-2' htmlFor='toDate'>
                              To Date
                            </label>
                            <DatePicker
                              id='toDate'
                              selected={this.state.endDate}
                              onSelect={this.handleDatetoSelect2}
                              className="form-control"
                            />
                          </InputGroup>
                        </div>
                        <Button type="submit" style={{height: '44px', marginTop: '16px'}} className=" btn-search ml-2">Search</Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} className="mt-3">
            <Row>
              <Col sm={3} md={3}>
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{data.total}</h3>
                    <p>Đơn hàng</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-shopping-cart"></i>
                  </div>
                  <a href="/app/packet" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </Col>
              <Col sm={3} md={3}>
                <div className="small-box bg-gradient-success">
                  <div className="inner">
                    <h3>{data.totalWeight}kg</h3>
                    <p>Khối lượng</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-weight-hanging"></i>
                  </div>
                  <a href="/app/packet" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </Col>
              {
                (this.state.currUser.role !== USER_ROLE.OPS && this.state.currUser.role !== USER_ROLE.SALER) && (
                  <Col sm={3} md={3}>
                    <div className="small-box bg-gradient-warning">
                      <div className="inner">
                        <h3>{data.opsUsers}</h3>
                        <p>Tổng User FWD</p>
                      </div>
                      <div className="icon">
                        <i className="fas fa-user"></i>
                      </div>
                      <a href="/app/user" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </Col>
                )
              }
              {
                this.state.currUser.role !== USER_ROLE.OPS && (
                  <Col sm={3} md={3}>
                    <div className="small-box bg-gradient-danger">
                      <div className="inner">
                        <h3>{data.salesUsers}</h3>
                        <p>Tổng User Công ty</p>
                      </div>
                      <div className="icon">
                        <i className="fas fa-user"></i>
                      </div>
                      <a href="/app/user" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </Col>
                )
              }

            </Row>
          </Col>
        </Row>

      {
        this.state.currUser.role !== USER_ROLE.DOCUMENT ? (
          <div className="row mb-3">
            <div className="col-sm-2 col-6">
              <div className="description-block border-right">
                <h5 className="description-header">{this.formatNumber(data.opsData?.sttk)}đ</h5>
                <span className="description-text">DOANH THU FWD</span>
              </div>

            </div>
            <div className="col-sm-2 col-6">
              <div className="description-block border-right">
                <h5 className="description-header">{this.formatNumber(parseInt(data.totalPrice?.sttk - data.opsData?.sttk))}đ</h5>
                <span className="description-text">DOANH THU CÔNG TY</span>
              </div>
            </div>

            <div className="col-sm-2 col-6">
              <div className="description-block border-right">
                <h5 className="description-header">{this.formatNumber(parseInt(data.totalPrice?.chiPhiQuaDoiTac))}đ</h5>
                <span className="description-text">CHI PHÍ QUA ĐỐI TÁC</span>
              </div>

            </div>

            <div className="col-sm-2 col-6">
              <div className="description-block border-right">
                <h5 className="description-header">{this.formatNumber(data.opsData?.totalLoiNhuan)}đ</h5>
                <span className="description-text">LỢI NHUẬN FWD</span>
              </div>

            </div>

            <div className="col-sm-2 col-6">
              <div className="description-block border-right">
                <h5 className="description-header">{this.formatNumber(parseInt(data.totalPrice?.totalLoiNhuan - data.opsData?.totalLoiNhuan))}đ</h5>
                <span className="description-text">LỢI NHUẬN CÔNG TY</span>
              </div>
            </div>

            <div className="col-sm-2 col-6">
              <div className="description-block">
                <h5 className="description-header">{this.formatNumber(parseInt(data.totalPrice?.totalLoiNhuan))}đ</h5>
                <span className="description-text">LỢI NHUẬN</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="row mb-3">
            <div className="col-sm-2 col-6">
              <div className="description-block border-right">
                <h5 className="description-header">{this.formatNumber(data.opsData?.sttk)}đ</h5>
                <span className="description-text">DOANH THU FWD</span>
              </div>

            </div>
            <div className="col-sm-2 col-6">
              <div className="description-block border-right">
                <h5 className="description-header">{this.formatNumber(parseInt(data.totalPrice?.sttk - data.opsData?.sttk))}đ</h5>
                <span className="description-text">DOANH THU CÔNG TY</span>
              </div>
            </div>

            <div className="col-sm-2 col-6">
              <div className="description-block border-right">
                <h5 className="description-header">{this.formatNumber(parseInt(data.totalPrice?.chiPhiQuaDoiTac))}đ</h5>
                <span className="description-text">CHI PHÍ QUA ĐỐI TÁC</span>
              </div>

            </div>
          </div>
        )

      }

        <Row>
          <Col sm={12} md={7}>

            <Card>
              <CardHeader tag="h5">Thống kê Users</CardHeader>
              <CardBody className="table-scroll">
                <Table className="table-bordered table-lg mt-0 mt-lg mb-0">
                  <thead className="text-uppercase">
                  <tr>
                    <th>Tên</th>
                    <th className="text-center">Bill</th>
                    <th className="text-center">Weight</th>
                    <th className="text-center">Doanh số</th>
                    <th className="text-center">Lợi nhuận</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.data?.groupedUser?.map((pkg, i) => {
                    return (
                      <tr key={i}>
                      <td className="text-center">{pkg.name}</td>
                      <td className="text-center">{pkg.total}</td>
                      <td className="text-center">{pkg.weight}</td>
                      <td className="text-center">{this.formatNumber(pkg.sttk)}</td>
                      <td className="text-center">{this.formatNumber(pkg.totalLoiNhuan)}</td>
                    </tr>)
                  })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={5}>
            <Card>
              <CardHeader tag="h5">Thống kê dịch vụ</CardHeader>
              <CardBody className="table-scroll">
                <Table className="table-bordered table-lg mt-lg mt-0 mb-0">
                  <thead className="text-uppercase">
                  <tr>
                    <th>Dịch vụ</th>
                    <th className="text-center">Bill</th>
                    <th className="text-center">Weight</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.data?.services?.map((pkg, i) => {
                    return (<tr key={i}>
                        <td className="text-center">{pkg.name}</td>
                        <td className="text-center">{pkg.total}</td>
                        <td className="text-center">{pkg.weight}</td>
                      </tr>)
                  })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>

        </Row>
      {
        this.state.currUser.role !== USER_ROLE.OPS && (
          <Row className='mt-3'>
            <Col sm={12} md={7}>

              <Card>
                <CardHeader tag="h5">Thống kê Customers</CardHeader>
                <CardBody className="table-scroll">
                  <Table className="table-bordered table-lg mt-0 mt-lg mb-0">
                    <thead className="text-uppercase">
                    <tr>
                      <th>Tên</th>
                      <th className="text-center">Bill</th>
                      <th className="text-center">Total Weight</th>
                      <th className="text-center">Lợi nhuận</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.customerData?.map((pkg, i) => {
                      return (
                        <tr key={i}>
                          <td className="text-center">{pkg.name}</td>
                          <td className="text-center">{pkg.billupCount}</td>
                          <td className="text-center">{pkg.totalWeight}</td>
                          <td className="text-center">{this.formatNumber(pkg.totalLoiNhuan)}</td>
                        </tr>)
                    })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )
      }

      </>)
  }
}

export default DashboardMain
