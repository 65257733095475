import React, { Component } from 'react'
import {
  Row,
  Col,
  InputGroup,
  Input,
  Button,
  Form,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import logo from '../../images/hopdong/logo-tp.png';
import s from './Tracking.module.scss';
import axios from "axios";

class Tracking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      TrackingNumber: '',
    }
  }


  async componentDidMount () {
    const code = new URLSearchParams(this.props.location.search).get("code")
    if (code && code != undefined) {
      await this.setState({ TrackingNumber: code })
    }
  }



  render() {
    const url = `https://tracking.gopost.vn?tpCode=${this.state.TrackingNumber || ''}`
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Tracking</BreadcrumbItem>
        </Breadcrumb>
        <div style={{textAlign: 'center'}}>
          <iframe src={url} frameBorder="0" style={{height:'100vh'}}
                  height="100%" width="100%"></iframe>

        </div>
      </div>
    )
  }
}

export default Tracking
