import React from 'react'
import axios from 'axios'
import {Button, Form, FormGroup, Input} from "reactstrap";
import s from './Wiseway.module.scss';

import readXlsxFile from 'read-excel-file'

class Wiseway extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: '',
      data: [],
      logo: null,
    }
    this.onUploadImage = this.onUploadImage.bind(this)
  }

  onChangeImage(e) {
    this.setState({ logo: e.target.files[0] })
  }

  async onUploadImage(e) {
    const arr = []
    await readXlsxFile(this.state.logo).then((rows) => {
      // `rows` is an array of rows
      // each row being an array of cells.
      if (rows && rows.length) {
        rows.forEach((e, i) => {
          if (i > 0) {
            arr.push({
              serviceType: e[24] || '',
              finalMileInjectionLocation: 'SYD',
              referenceNo: e[0] || '',
              recipientName: e[2] || '',
              phone: e[11] || '',
              email: 'wisecore.system.support@wiseway.com.au',
              addressLine1: e[5] || '',
              addressLine2: e[6] || '',
              addressLine3: null,
              city: e[7] || '',
              state: e[9] || '',
              postcode: e[8] || '',
              country: e[10] || '',
              instruction: e[12] || '',
              orderItems: [
                {
                  itemNo: i,
                  itemCount: e[15] || '',
                  description: e[13] || '',
                  unitValue: e[17] || '',
                  weight: 20 || '',
                  height: e[21] || '',
                  length: e[23] || '',
                  width: e[22] || '',
                },
              ],
              dimensionUnit: 'cm',
              weightUnit: 'kg',
            })
          }
        })
      }
    })

    const res = await axios.post('/create-label', {
      items: arr
    } )
    if (res && res.status === 200) {
      this.savePdf(res.data.data[0].labelContent)
    }
  }

  savePdf(labelContent) {
    const linkSource = `data:application/pdf;base64,${labelContent}`;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  render() {
    return (
      <>
        <div style={{textAlign: 'center'}}>
          <div className={s.formWrap}>
            <Form>
              <h3>Upload .xlxs file</h3>
              <div>
                <FormGroup>
                  <Input onChange={e => this.onChangeImage(e)} name="file" type="file" />
                </FormGroup>
                <Button color="primary" outline onClick={e => this.onUploadImage(e)}>Create Label</Button>
              </div>
            </Form>
          </div>
        </div>
      </>
    )
  }
}
export default Wiseway
