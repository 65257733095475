import React from 'react'
import axios from 'axios'
import {
  Button, Card,
  CardBody,
  CardHeader,
  Row, Col, InputGroup, Input,
  Modal,
  ModalFooter, Form,
  ModalHeader, ModalBody,
  FormGroup, Label,
  Table
} from "reactstrap";
import jwt from "jsonwebtoken";
import { Link } from 'react-router-dom';
import { vi } from 'date-fns/locale'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class Mawb extends React.Component {
  constructor(props) {
    super(props)
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 3);
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 50,
        from: 0
      },
      modalToggle: false,
      modalDebitInfo: {},
      isShowCalendar: false,
      currUser: {},
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      focus: START_DATE,
      mawbCodeUpdateItem: {},
      createDebit: {
        companyName: "",
        name: "",
        content: "",
        price: "",
        note: ""
      },
      kgCode: ''
    }

    this.handleInput = this.handleInput.bind(this)
    this.toggle = this.toggle.bind(this);
    this.onUpdateMawbCode = this.onUpdateMawbCode.bind(this);
    this.onUpdateMawbCodeChange = this.onUpdateMawbCodeChange.bind(this);
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleEndDateSelect = this.handleEndDateSelect.bind(this);
  }

  handleInput = (e) => {
    const { name, value } = e.target
    const newInfo = this.state;
    newInfo[name] = value;
    this.setState(newInfo)
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({ currUser, createDebit: { ...this.state.createDebit, companyName: currUser.companyName } })
    await this.getItem()
  }

  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({ focus })
  }
  setStartDate(startDate) {
    this.setState({ startDate })
  }
  setEndDate(endDate) {
    this.setState({ endDate })
  }

  async getItem() {
    try {
      const { startDate, endDate } = this.state
      const { data } = await axios.get('/mawbs', {
        params: { startDate, endDate },
      })
      this.setState({ data })
    } catch (error) { }
  }

  async onDeleteMawb(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this ?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              await axios.delete(`/mawb/${id}`)
              await self.getItem()
            },
          },
          {
            label: "No",
            onClick: () => { },
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  toggle() {
    this.setState((prevState, props) => ({ modalToggle: !prevState.modalToggle }));
  }
  async onUpdateMawbCode(e) {

    try {
      const { mawbCodeUpdateItem } = this.state
      if (!mawbCodeUpdateItem.newMawb) {
        toast.error('Vui lòng nhập mã mới.');
        return
      }
      await axios.put(`/mawbs/${mawbCodeUpdateItem.mawbCode}`, { newMawb: mawbCodeUpdateItem.newMawb })
      await this.getItem()
      toast.success('Cập nhật mã thành công');
      this.toggle()
      e.preventDefault();

    } catch (error) {
      toast.error('Đã xảy ra lỗi. Vui lòng thử lại sau.');
    }
  }
  onUpdateMawbCodeChange(e) {
    const { name, value } = e.target;
    let mawbCodeUpdateItem = this.state.mawbCodeUpdateItem
    mawbCodeUpdateItem[name] = value
    this.setState({ mawbCodeUpdateItem })
  }

  async setUpdateItem(item) {
    await this.setState({ mawbCodeUpdateItem: { ...item, newMawb: item.mawbCode } });
    this.toggle()
  }
  async searchCode() {
    const { kgCode } = this.state
    if (!kgCode) {
      toast.error('Vui lòng nhập mã cần tìm kiếm');
      return;
    }
    this.props.history.push(`/app/search/${kgCode}`)
  }
  handleDatefromSelect(date) {
    this.setState({ startDate: date });
  }
  handleEndDateSelect(date) {
    this.setState({ endDate: date });
  }
  render() {
    const { data, isShowCalendar, startDate, focus, endDate, kgCode, mawbCodeUpdateItem } = this.state
    const { releasedArr } = data
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Row>
          <Col sm={12} md={6}>
            <Card>
              <CardHeader tag="h5">Lọc theo ngày</CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.getItem()
                  }}
                >

                  {isShowCalendar && (
                    <DateRangePickerCalendar
                      startDate={startDate}
                      endDate={endDate}
                      focus={focus}
                      onStartDateChange={(e) => this.setStartDate(e)}
                      onEndDateChange={(e) => this.setEndDate(e)}
                      onFocusChange={(e) => this.handleFocusChange(e)}
                      locale={vi}
                    />
                  )}

                  <div className="d-flex justify-content-between align-items-center mobile-dlock">
                    <div>
                      {startDate && (
                        <div className='wrap-date'>
                          <p>Ngày bắt đầu:{' '}</p>
                          <DatePicker
                            id='fromDate'
                            maxDate={endDate}
                            locale="vi"
                            selected={startDate}
                            onSelect={this.handleDatefromSelect}
                          />
                        </div>
                      )}
                      {endDate && (
                        <div className='wrap-date'>
                          <p>
                            Ngày kết thúc:{' '}
                          </p>
                          <DatePicker
                            id='endDate'
                            locale="vi"
                            minDate={startDate}
                            maxDate={new Date()}
                            selected={endDate}
                            onSelect={this.handleEndDateSelect}
                          />
                        </div>
                      )}
                    </div>
                    <div className="text-right">
                      <Button type="submit" style={{ width: '100px' }}>
                        Tìm kiếm
                      </Button>
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="h-100">
              <CardHeader tag="h5">Tìm kiếm theo kiện</CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.searchCode()
                  }}
                >
                  <InputGroup>
                    <Input
                      name="kgCode"
                      value={kgCode}
                      onChange={this.handleInput}
                    />
                    <Button type="submit" className="ml-3">Tìm kiếm</Button>
                  </InputGroup>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="d-flex justify-content-between my-4 align-items-center">
          <h2 className='title-page'>MAWB Code</h2>
        </div>
        <Table responsive className="mb-0 table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>MAWB Code</th>
              <th>Ngày tạo</th>
              <th>Chi tiết</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.length ?
              data.list.map((obj, i) => (
                <tr key={i + 1}>
                  <td>{i + 1}</td>
                  <td>{obj.mawbCode}</td>
                  <td>{moment(obj.createdAt).subtract(7, 'hours').format('DD-MM-YYYY HH:mm')}</td>
                  <td><Link target="_blank" to={'/app/tracking/management/' + obj.mawbCode}>Chi tiết</Link> </td>
                  <td style={{ color: '#1fb141' }}>{releasedArr && (
                    releasedArr[obj.mawbCode.trimLeft()] ? 'Released' : ''
                  )}
                  </td>
                  <td><Link target="_blank" to={`/app/tracking/management/${obj.mawbCode}/packages`}>Kiện hàng con</Link> </td>
                  <td>
                    <Button style={{ marginRight: '5px' }} type="button" color="danger" onClick={(e) => this.onDeleteMawb(obj.id)}>Delete</Button>
                    <Button type="button" color="danger" onClick={(e) => this.setUpdateItem(obj)}>Edit</Button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="100" className='text-center'>
                    Dữ liệu đang cập nhật !
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        <Modal isOpen={this.state.modalToggle} toggle={this.toggle} >
          <Form>
            <ModalHeader toggle={this.toggle}>Chỉnh sửa</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="details">New Mawb Code</Label>
                <Input name="newMawb" value={mawbCodeUpdateItem?.newMawb} id="newMawb" onChange={this.onUpdateMawbCodeChange} />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.onUpdateMawbCode(e)} >Cập nhật</Button>{' '}
              <Button color="secondary" onClick={this.toggle}>Huỷ bỏ</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  }
}
export default Mawb
