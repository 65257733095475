import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import Icon from '../Icon'
import LinksGroup from './LinksGroup/LinksGroup'

import s from './Sidebar.module.scss'
import jwt from 'jsonwebtoken'
import {MENU, USER_ROLE} from '../../constants'

import logo from '../../images/logo.png'

const Sidebar = () => {
  let token = localStorage.getItem('id_token')
  let currUser = jwt.decode(token)
  const [showNav, setShowNav] = useState(false)

  return (
    <nav className={s.root}>
      <header className={s.logo}>
        <Link to="/app/main">
        <img src={logo} alt="bg-login" className={s.imgLogo}/>
        </Link>
      </header>
      <div className={s.navLgUp}>
        <ul className={s.nav}>
          {MENU.map(
            (links, i) =>
              (links.isVisible.includes(currUser.role) || (currUser.email == 'customerservicetinphat@gmail.com') && (links.headerLink == '/app/tracking/management' || links.headerLink == '/app/cskh-report')) && (
                <>
                  <LinksGroup
                    key={links.headerLink}
                    header={links.header}
                    headerLink={links.headerLink}
                    glyph={links.glyph}
                  />
                  {links.sub &&
                    links.sub.map((sub) => (
                      <LinksGroup
                        className="pl-5"
                        key={sub.header}
                        header={sub.header}
                        headerLink={sub.headerLink}
                        glyph={sub.glyph}
                      />
                    ))}
                </>
              ),
          )}
        </ul>
      </div>
      <div className={s.navLgDown}>
        <div className={s.navIcon} onClick={() => setShowNav(!showNav)}>
          <button className={s.btn}>
            <Icon glyph={showNav ? 'close' : 'menu'} />
          </button>
        </div>
        <ul className={showNav ? s.nav : s.hideNav}>
          {MENU.map(
            (links, i) =>
              links.isVisible.includes(currUser.role) && (
                <>
                  <LinksGroup
                    key={links.headerLink}
                    header={links.header}
                    headerLink={links.headerLink}
                    glyph={links.glyph}
                    onClick={() => setShowNav(false)}
                  />
                  {links.sub &&
                    links.sub.map((sub) => (
                      <LinksGroup
                        className="pl-5"
                        key={sub.header}
                        header={sub.header}
                        glyph={sub.glyph}
                        onClick={() => setShowNav(false)}
                        headerLink={sub.headerLink}
                      />
                    ))}
                </>
              ),
          )}
        </ul>
      </div>
    </nav>
  )
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  }
}

export default withRouter(connect(mapStateToProps)(Sidebar))
