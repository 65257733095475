import {
    FETCH_DASHBOARD_REQUEST, FETCH_DASHBOARD_SUCCESS, FETCH_DASHBOARD_FAILURE,
    UPDATE_DASHBOARD_REQUEST, UPDATE_DASHBOARD_SUCCESS, UPDATE_DASHBOARD_FAILURE,
    DELETE_DASHBOARD_REQUEST, DELETE_DASHBOARD_SUCCESS, DELETE_DASHBOARD_FAILURE,
    CREATE_DASHBOARD_REQUEST, CREATE_DASHBOARD_SUCCESS, CREATE_DASHBOARD_FAILURE, FETCH_DASHBOARD_DETAIL_REQUEST, FETCH_DASHBOARD_DETAIL_SUCCESS, FETCH_DASHBOARD_DETAIL_FAILURE,
  } from '../actions/dashboard';
  
  export default function dashboard(
    state = {
      isFetching: false,
      isUpdating: false,
      dashboard: [],
      dashboardById: null
    },
    action,
  ) {
    switch (action.type) {
  
      /******* Fetch Users */
      case FETCH_DASHBOARD_REQUEST:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          message: null,
        });
      case FETCH_DASHBOARD_SUCCESS:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          dashboard: action.dashboard,
        });
      case FETCH_DASHBOARD_FAILURE:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          message: action.message || 'update dashboard failed',
        });
  
      /******* Update User */
      case UPDATE_DASHBOARD_REQUEST:
        return Object.assign({}, state, {
          isUpdating: action.isUpdating,
          message: null,
        });
      case UPDATE_DASHBOARD_SUCCESS:
        const dashboardById = ((state.dashboardById.id == action.dashboard.id) ? action.dashboard : state.dashboardById)
        return {
          dashboard: [...state.dashboard],
          isUpdating: action.isUpdating,
          dashboardById,
        }
      case UPDATE_DASHBOARD_FAILURE:
        return Object.assign({}, state, {
          isUpdating: action.isUpdating,
          message: action.message || 'update dashboard failed',
        });

      
      /******* Delete User */
      case DELETE_DASHBOARD_REQUEST:
        return Object.assign({}, state, {
          isUpdating: action.isUpdating,
          message: null,
        });
      case DELETE_DASHBOARD_SUCCESS:
        const list = state.dashboard.filter(item => item.id !== action.dashboard.id)
        return {
          isUpdating: action.isUpdating,
          dashboard: list,
        }
      case DELETE_DASHBOARD_FAILURE:
        return Object.assign({}, state, {
          isUpdating: action.isUpdating,
          message: action.message || 'Delete dashboard failed',
        });

      /******* New User */
      case CREATE_DASHBOARD_REQUEST:
        return Object.assign({}, state, {
          isUpdating: action.isUpdating,
          message: null,
        });
      case CREATE_DASHBOARD_SUCCESS:
        return {
          isUpdating: action.isUpdating,
          dashboard: [...state.dashboard, action.dashboard],
        }
      case CREATE_DASHBOARD_FAILURE:
        return Object.assign({}, state, {
          isUpdating: action.isUpdating,
          message: action.message || 'New dashboard failed',
        });
          /******* Fetch Dashboard Detail */
      case FETCH_DASHBOARD_DETAIL_REQUEST:
        return Object.assign({}, state, {
          isUpdating: action.isUpdating,
          message: null,
        });
      case FETCH_DASHBOARD_DETAIL_SUCCESS:
        return {
          dashboard: [...state.dashboard],
          isUpdating: action.isUpdating,
          dashboardById: action.dashboardById,
        }
      case FETCH_DASHBOARD_DETAIL_FAILURE:
        return Object.assign({}, state, {
          isUpdating: action.isUpdating,
          message: action.message || 'Fetch dashboard detail failed',
        });
  
      default:
        return state;
    }
  
  }
  