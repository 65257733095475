import React, { Component } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

class QrCodeScanner extends Component {
  constructor(props) {
    super(props);
    this.qrCodeScannerRef = React.createRef();
  }

  componentDidMount() {
    const config = { fps: 10, qrbox: 100 };
    this.scanner = new Html5QrcodeScanner(
      this.qrCodeScannerRef.current.id,
      config,
      false
    );

    this.scanner.render(this.onScanSuccess.bind(this), this.onScanFailure.bind(this));
  }

  componentWillUnmount() {
    this.scanner.clear();
  }

  onScanSuccess(decodedText, decodedResult) {
    // console.log(`Scan result: ${decodedText}`);
    if (this.props.onSuccess) {
      this.props.onSuccess(decodedText, decodedResult);
    }
  }

  onScanFailure(error) {
    // console.warn(`Scan error: ${error}`);
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  render() {
    return <div id="qrCodeScanner" ref={this.qrCodeScannerRef}></div>;
  }
}

export default QrCodeScanner;
