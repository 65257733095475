import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { Redirect } from 'react-router';
import jwt from 'jsonwebtoken';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteDashboard,
  fetchDashboardById,
  updateDashboard,
} from '../../actions/dashboard';
import { USER_ROLE } from '../../constants';
import s from './Dashboard.module.scss';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import EditorArea from './EditorArea';
import axios from "axios";

class DashboardDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currUser: {},
      redirect: false,
      modalToggleUpdateDashboard: false,
      dashboardContent: { title: '', body: '', highlight: false },
      dashboardDetail: { title: '', body: '', highlight: false, code: '', codeDN: '', codeHN: '', location: 'HCM' },
      isOpenToast: false,

    };
    this.onUpdateDashboard = this.onUpdateDashboard.bind(this);
    this.onChangeUpdateDashboard = this.onChangeUpdateDashboard.bind(this);
    this.toggleUpdateDashboard = this.toggleUpdateDashboard.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
  }

  toggleUpdateDashboard() {
    this.setState({
      modalToggleUpdateDashboard: !this.state.modalToggleUpdateDashboard,
    });
  }

  async onUpdateDashboard(e) {
    e.preventDefault();
    try {
      let { pricingId } = this.props.match.params;
      await axios.post(`/update-pricing/${pricingId}`, this.state.dashboardContent)
      this.setState((prevState) => ({ modalEditToggle: !prevState.modalEditToggle }));
      e.preventDefault();
    } catch (err) {
      toast.error('Đã có lỗi xảy ra');
      return
    }
    this.toggleUpdateDashboard();
    this.setState({isOpenToast: true})
  }

  onChangeUpdateDashboard(e, name) {
    if (e.target.name === 'highlight') {
      this.setState({
        dashboardContent: {
          ...this.state.dashboardContent,
          [e.target.name]: e.target.checked,
        },
      });
      return;
    }
    this.setState({
      dashboardContent: {
        ...this.state.dashboardContent,
        [e.target.name]: e.target.value,
      },
    });
  }

  changeLocation(e) {
    const { name, value } = e.target;
    let newDashboard = this.state.dashboardContent
    newDashboard[name] = value
    this.setState({ dashboardContent: newDashboard })
  }

  updateDashboard(item) {
    this.setState({
      dashboardContent: item,
      modalToggleUpdateDashboard: !this.state.modalToggleUpdateDashboard,
    });
  }

  deleteNotification(id) {
    const self = this
    confirmAlert({
      title: 'Xác nhận Xoá',
      message: 'Bạn có chắc muốn xoá thông báo này?',
      buttons: [
        {
          label: 'Có',
          onClick: async () => {
            await axios.get(`/delete-pricing/${id}`)
            this.setState({ redirect: true });
          },
        },
        { label: 'Không', onClick: () => {} },
      ],
    });
  }

  async componentDidMount() {

    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser });
    await this.getPricingById()
  }

  async getPricingById(params = {}) {
    try {
      let { pricingId } = this.props.match.params;

      const res = await axios.post(`/get-pricing/${pricingId}`, )
      if (res && res.data) {
        console.log(res.data)
        this.setState({dashboardDetail: res.data})
      }
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { dashboardDetail } = this.state;
    const { redirect } = this.state;

    if (this.props.message && this.state.isOpenToast) {
      toast.error(this.props.message);
      this.setState({isOpenToast: false})
    }
    const locationOption = {
      HCM: "HCM",
      HN: 'HN',
      ĐN: 'ĐN',
      Custom: 'Custom'
    }
    if (redirect) {
      return <Redirect to='/app/pricing' />;
    }

    return (
      <div className={s.root}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Bảng giá</BreadcrumbItem>
        </Breadcrumb>

        {/* Update content of dashboard */}
        <Modal
          isOpen={this.state.modalToggleUpdateDashboard}
          toggle={this.toggleUpdateDashboard}
        >
          <ModalHeader>Chỉnh sửa bảng giá</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='title'>Tiêu đề</Label>
              <Input
                value={this.state.dashboardContent.title}
                name='title'
                id='title'
                placeholder='title'
                onChange={(e) => this.onChangeUpdateDashboard(e)}
              />
            </FormGroup>
            <FormGroup style={{ minHeight: '400px' }}>
              <Label for='content'>Nội dung</Label>
              <EditorArea
                value={this.state.dashboardContent.body}
                onChange={(e) => this.onChangeUpdateDashboard(e, 'body')}
              />
            </FormGroup>
            <FormGroup>
              <Label for="location">Khu vực</Label>
              <Input id="location" name="location" value={this.state.dashboardContent.location} type="select"  onChange={this.changeLocation}>
                {Object.values(locationOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for='content'>Embed Code</Label>
              <textarea
                className="form-control"
                name='code'
                value={this.state.dashboardContent.code}
                onChange={(e) => this.onChangeUpdateDashboard(e, 'code')}
              />
            </FormGroup>

          </ModalBody>
          <ModalFooter>
            <Button
              type='submit'
              color='success'
              onClick={(e) => this.onUpdateDashboard(e)}
            >
              Update
            </Button>
            <Button color='secondary' onClick={this.toggleUpdateDashboard}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Row>
          <Col sm={12} className='mb-2'>
            <Link to='/app/pricing/'>
              <Button>
                <i className='fa fa-chevron-left' /> Quay lại trang danh sách bảng giá
              </Button>
            </Link>
          </Col>
          <Col>
            {dashboardDetail && (
              <Card
                className={
                  dashboardDetail?.highlight ? 'border border-danger' : ''
                }
              >
                <CardHeader tag='h5' className='text-danger'>
                  {dashboardDetail?.title || ''}
                </CardHeader>
                <CardBody>
                  <div> { ReactHtmlParser (dashboardDetail?.body) } </div>
                  <iframe src={dashboardDetail?.code} frameBorder="0"
                          style={{overflow: "hidden", height: "100vh"}}
                          height="100vh" width="100%"></iframe>

                </CardBody>
                {(this.state.currUser.role === USER_ROLE.ADMIN || this.state.currUser.role === USER_ROLE.DOCUMENT) ? (
                  <CardFooter className='justify-content-end d-flex cardbody'>
                    <Button
                      onClick={() => this.updateDashboard(dashboardDetail)}
                    >
                      <i className='fa fa-pencil' /> Chỉnh sửa
                    </Button>
                    <Button
                      color='danger'
                      className='ml-2'
                      onClick={() =>
                        this.deleteNotification(dashboardDetail?.id)
                      }
                    >
                      <i className='fa fa-recycle' /> Xoá
                    </Button>
                  </CardFooter>
                ) : null }
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
export default DashboardDetail;
