import {
  deleteUserError,
  deleteUserSuccess,
  requestDeleteUser,
  requestUpdateUser,
  UPDATE_USER_REQUEST,
  updateUserError, updateUserSuccess
} from "./user";

export const CREATE_PICKUP_REQUEST = 'CREATE_PICKUP_REQUEST';
export const CREATE_PICKUP_SUCCESS = 'CREATE_PICKUP_SUCCESS';
export const CREATE_PICKUP_FAILURE = 'CREATE_PICKUP_FAILURE';
export const DELETE_PICKUP = 'DELETE_PICKUP';
export const RESET_PICKUP = 'RESET_PICKUP';
export const LIST_PICKUP_REQUEST = 'LIST_PICKUP_REQUEST';
export const LIST_PICKUP_SUCCESS = 'LIST_PICKUP_SUCCESS';
export const LIST_PICKUP_FAILURE = 'LIST_PICKUP_FAILURE';

export function createPickup(postData) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        createPickup(
          type:"${postData.type}",
          datetime:"${postData.datetime}",
          address:"${postData.address}",
          grossWeight:"${postData.grossWeight}",
          remark:"${postData.remark}",
          packageId:"${postData.packageId}",
          companyUserID:"${postData.companyUserID}",
          status: "${postData.status}"
          )
          
          {id,packageId,status,datetime,companyUserID,address,type,grossWeight,remark}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestCreatePickup());

    return fetch('/graphql', config)
        .then(response =>
            response.json().then(responseJson => ({
              responseJson
            })),
        )
        .then(({ responseJson }) => {
          if (responseJson.errors) {
            dispatch(createPickupError(responseJson.errors[0]));
            return Promise.reject(responseJson.errors);
          }

          dispatch(createPickupuccess(responseJson.data.createPickup));
          return Promise.resolve(responseJson.data.createPickup);
        })
        .catch(err => console.error('Error: ', err));
  };
}

/**************************** Create Pickup ****************************/
export function requestCreatePickup() {
  return {
    type: CREATE_PICKUP_REQUEST,
    isCreating: true,
    isAuthenticated: false,
  };
}

export function createPickupuccess(user) {
  return {
    type: CREATE_PICKUP_SUCCESS,
    isCreating: false,
    isAuthenticated: false,
    user,
  };
}

export function createPickupError(message) {
  return {
    type: CREATE_PICKUP_FAILURE,
    isCreating: false,
    isAuthenticated: false,
    message,
  };
}

export function removePickup(id) {
  return {
    type: DELETE_PICKUP,
    isFetching: false,
    id,
  };
}

export function deletePickup(id) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        deletePickup(id: "${id}")
          {id}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestDeleteUser());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          deleteUser: responseJson.data.deleteUser,
          responseJson,
        })),
      )
      .then(({ deleteUser, responseJson }) => {
        if (!responseJson.data.deleteUser) {
          dispatch(deleteUserError(deleteUser.errors));
          return Promise.reject(deleteUser);
        }

        dispatch(deleteUserSuccess(deleteUser));
        return Promise.resolve(deleteUser);
      })
      .catch(err => console.error('Error: ', err));
  };
}

export function resetPickup() {
  return {
    type: RESET_PICKUP
  };
}

/**************************** Fetch Pickup ****************************/
export function requestPickup() {
  return {
    type: LIST_PICKUP_REQUEST,
    isFetchingPickup: true,
    isAuthenticated: false,
  };
}

export function fetchPickupSuccess(pickup) {
  return {
    type: LIST_PICKUP_SUCCESS,
    isFetchingPickup: false,
    isAuthenticated: false,
    pickup,
  };
}

export function fetchPickupError(message) {
  return {
    type: LIST_PICKUP_FAILURE,
    isFetchingPickup: false,
    isAuthenticated: false,
    message,
  };
}

export function fetchPickup(isAdmin, userId) {
  let query;
  if (isAdmin) {
    query = '{pickup{id,packageId,status,datetime,companyUserID,address,type,grossWeight,remark}}'
  } else {
    query = `{pickup(where: {companyUserID: ${userId}}){id,packageId,status,datetime,companyUserID,address,type,grossWeight,remark}}`
  }
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: query
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestPickup());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          responseJson
        })),
      )
      .then(({ responseJson }) => {
        if (responseJson.errors) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(fetchPickupError(responseJson.errors[0].message));
          return Promise.reject(responseJson.errors);
        }
        // Dispatch the success action
        dispatch(fetchPickupSuccess(responseJson.data.pickup));
        return Promise.resolve(responseJson.data.pickup);
      })
      .catch(err => console.error('Error: ', err));
  };
}

export function updatePickup(postData) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        updatePickup(
          id: "${postData.id}",
          status:"${postData.status}"
          {id,status}}`,
    }),
    credentials: 'include',
  };


  return dispatch => {
    //dispatch(requestUpdateUser());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          updateUser: responseJson.data.updateUser,
          responseJson,
        })),
      )
      .then(({ updateUser, responseJson }) => {
        if (!responseJson.data.updateUser) {
          dispatch(updateUserError(updateUser.errors));
          return Promise.reject(updateUser);
        }

        dispatch(updateUserSuccess(updateUser));
        return Promise.resolve(updateUser);
      })
      .catch(err => console.error('Error: ', err));
  };
}
