export const FETCH_PACKAGE_REQUEST = 'FETCH_PACKAGE_REQUEST';
export const FETCH_PACKAGE_SUCCESS = 'FETCH_PACKAGE_SUCCESS';
export const FETCH_PACKAGE_FAILURE = 'FETCH_PACKAGE_FAILURE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const RESET_PACKAGE = 'RESET_PACKAGE';

function requestFetchPackage() {
  return {
    type: FETCH_PACKAGE_REQUEST,
    isFetching: true,
  };
}

function fetchPackageSuccess(packageByCode) {
  return {
    type: FETCH_PACKAGE_SUCCESS,
    isFetching: false,
    item: packageByCode,
  };
}

function fetchPackageError(message) {
  return {
    type: FETCH_PACKAGE_FAILURE,
    isFetching: false,
    message,
  };
}


export function removePackage(code) {
  return {
    type: DELETE_PACKAGE,
    isFetching: false,
    code,
  };
}

export function resetPackage() {
  return {
    type: RESET_PACKAGE
  };
}

export function fetchPackage(code) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        query: `{packageByCode(
            code: "${code}",
            ){id,
              referenceCode,
              receiverCompanyName,
              receiverName,
              receiverPhone,
              receiverEmail,
              receiverCity,
              receiverCountry,
              receiverPostalCode,
              receiverAddr1,
              receiverAddr2,
              receiverAddr3,
              createdAt,
              logisticCode,
              packs,
              kgCode}
        }`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestFetchPackage());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
            packageByCode: responseJson.data.packageByCode,
          responseJson,
        })),
      )
      .then(({ packageByCode, responseJson }) => {
        if (!responseJson.data.packageByCode) {
          dispatch(fetchPackageError(packageByCode.errors));
          return Promise.reject(packageByCode);
        }
        dispatch(fetchPackageSuccess(packageByCode));
        return Promise.resolve(packageByCode);
      })
      .catch(err => console.error('Error: ', err));
  };
}


export function scanPackage(code, type, mawbCode, branch, exportType) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        query: `{packageByCode(
            code: "${code}",
            type: "${type}",
            mawbCode: "${mawbCode}",
            branch: "${branch}",
            exportType: "${exportType}",
            currentTime: "${new Date()}",
        ){id,
          referenceCode,
          receiverCompanyName,
          receiverName,
          receiverPhone,
          receiverEmail,
          receiverCity,
          receiverCountry,
          receiverPostalCode,
          receiverAddr1,
          receiverAddr2,
          receiverAddr3,
          createdAt,
          logisticCode,
          logisticService,
          packs,
          kgCode,
          hawbCode,
          importJSON,
          exportJSON,
          exportType,
          logisticCode,
          logisticService,
          owner,
  }
        }`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestFetchPackage());
    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
            packageByCode: responseJson.data.packageByCode,
          responseJson,
        })),
      )
      .then(({ packageByCode, responseJson }) => {
        if (!responseJson.data.packageByCode) {
          dispatch(fetchPackageError(responseJson.errors[0].message));
          return Promise.reject(packageByCode);
        }
        dispatch(fetchPackageSuccess(packageByCode));
        return Promise.resolve(packageByCode);
      })
      .catch(err => console.error('Error: ', err));
  };
}
