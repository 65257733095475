import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
  Table
} from "reactstrap";
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import PrintMawb from "./PrintMawb";
import ReactToPrint from "react-to-print";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select'
import {USER_ROLE} from "../../constants";
import COUNTRIES from './country.json'
import { Link } from 'react-router-dom'
let timerId;

class Mawb extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalPrintPackagesToggle: false,
      modalToggle: false,
      modalEditToggle: false,
      modalToggleUpdateMawb: false,
      modalMawbInfo: {},
      currUser: {},
      listCustomer: [],
      selectedCustomer: {
        id: '',
        name: '',
        khCode: '',
        phone: '',
        address: ''
      },
      createMawb: {
        note: "",
        branch: "HCM",
        agency: "CRRIC",
      },
      editMawb: {
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerPhone: "",
        note: "",
        location: "HCM"
      },
      isEdit: false,
      printId: '',
      printData: '',
      loading: false,
      date: new Date(),
      search: '',
      qStatus: '',
      searchBy: 'mawbId',
      location: '',
      locationOption: {
        HCM: "HCM",
        HN: 'HN',
        ĐN: 'ĐN'
      },
      locationOption2: {
        HCM: "HCM",
        HN: 'HN',
        ĐN: 'ĐN'
      },
      templatePrint: [
        'Parcel',
        'Clearance',
        'CRRIC',
      ],
    }
    this.onDeleteMawb = this.onDeleteMawb.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
    this.onEditMawb = this.onEditMawb.bind(this)
    this.onCreateMawb = this.onCreateMawb.bind(this);
    this.onEditMawbChange = this.onEditMawbChange.bind(this);
    this.toggleUpdateMawb = this.toggleUpdateMawb.bind(this);
    this.onCreateMawbChange = this.onCreateMawbChange.bind(this);
    this.onSelectedCustomer = this.onSelectedCustomer.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSelectedEditCustomer = this.onSelectedEditCustomer.bind(this);
    this.onClickPrintMawb = this.onClickPrintMawb.bind(this);
    this.printPackagesToggle = this.printPackagesToggle.bind(this);
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggle = this.toggle.bind(this);
    this.searchStatus = this.searchStatus.bind(this);
    this.searchBy = this.searchBy.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({ currUser, createMawb: {...this.state.createMawb, owner: currUser.displayName} })
    await this.getAllMawb()
    // await this.getListCustomer()
  }

  async onCreateMawb(e) {
    e.preventDefault();
    try {
      const { createMawb } = this.state
      let message = ''
      if (!createMawb.mawbCode) {
        message = 'nhập mawbCode'
      }
      if (message) {
        toast.error('Vui lòng ' + message)
        return
      }
      await axios.post('/create-mawb', {...createMawb,date: this.state.date})
      this.setState((prevState) => ({ modalToggle: !prevState.modalToggle }));
      await this.getAllMawb()
    } catch (err) {
      toast.error('Đã có lỗi xảy ra ', err.error);
      return
    }
  }
  async onEditMawb(e) {
    const { editMawb } = this.state
    let message = '', createMawb = editMawb
    if (!createMawb.mawbCode) {
      message = 'nhập mawbCode'
    }
    if (message) {
      toast.error('Vui lòng ' + message)
      return
    }
    try {
      await axios.post(`/update-mawb/${editMawb.id}`, {...editMawb, date: this.state.date})
      this.setState((prevState) => ({ modalEditToggle: !prevState.modalEditToggle }));
      await this.getAllMawb()
      e.preventDefault();
    } catch (err) {
      toast.error('Đã có lỗi xảy ra');
      return
    }

  }
  toggle() {
    this.setState((prevState, props) => ({ modalToggle: !prevState.modalToggle }));
  }
  openEditModal(obj) {
    console.log(obj)
    this.setState({editMawb: obj, date: moment(obj.date).subtract(7,'h')})
    this.setState((prevState, props) => ({ modalEditToggle: !prevState.modalEditToggle }));

    //const currentCustomer = this.state.listCustomer.filter(i => i.id === obj.id)?[0] : ''
    //this.setState({selectedCustomer: currentCustomer})

  }
  toggleUpdateMawb() {

  }
  onCreateMawbChange(e) {
    const { name, value } = e.target;
    let createMawbInfo = this.state.createMawb
    createMawbInfo[name] = value
    this.setState({ createMawb: createMawbInfo })
  }
  onEditMawbChange(e) {
    const { name, value } = e.target;
    let editMawbInfo = this.state.editMawb
    editMawbInfo[name] = value
    this.setState({ editMawb: editMawbInfo })
  }
  formatNumber (num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }
  async onDeleteMawb(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this Mawb?",
        buttons: [
          {
            label: "Yes",
            onClick: async() => {
              const res = await axios.get(`/delete-mawb/${id}`)
              console.log(res)
              await self.getAllMawb()
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  onSelectChange = (e) => {
    const { value } = e
    this.setState({ filterDateType: value })
  }
  onSelectedCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const { id } = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let createMawbInfo = this.state.createMawb
        createMawbInfo['customerName'] = temp.name
        createMawbInfo['customerId'] = temp.id
        createMawbInfo['customerAddress'] = temp.address
        createMawbInfo['customerPhone'] = temp.phone
        this.setState({ selectedCustomer: temp, createMawb: createMawbInfo })

      }
    }

  }
  handleInputChange(e) {
    const inputValue = e;
    // Clear any existing timer
    clearTimeout(timerId);
    // Set a new timer to delay the API call
    timerId = setTimeout(() => {
      console.log(inputValue);
      this.getListCustomer({ search: inputValue });
    }, 500);
  }
  onSelectedEditCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const { id } = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let editMawbInfo = this.state.editMawb
        editMawbInfo['customerName'] = temp.name
        editMawbInfo['customerId'] = temp.id
        editMawbInfo['customerAddress'] = temp.address
        editMawbInfo['customerPhone'] = temp.phone
        this.setState({ selectedCustomer: temp, editMawb: editMawbInfo })

      }
    }
  }
  async getAllMawb(params = {}) {
    try {
      const { searchBy, search = '', data } = this.state
      this.setState({loading: true})
      let res
      params.page = params.page  || data?.page
      let url = `/get-all-mawb?page=${params.page || 1}&search=${search}&status=${this.state.qStatus}`
      if (searchBy === 'owner') {
        url += `&searchBy=${searchBy}`
      }


      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      if (this.state.location) {
        url +=`&location=${this.state.location}`
      }

      if (this.state.currUser.role === USER_ROLE.MANAGER && this.state.currUser.location) {
        url +=`&location=${this.state.currUser.location}`
        const options = {
          [this.state.currUser.location]: this.state.currUser.location
        }
        console.log(options)
        this.setState({locationOption: options})
        this.forceUpdate()
      }
      res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }
  async getListCustomer(params = {search: ''}) {
    try {
      let url = `/get-all-customer?page=${params.page || 1}&size=10&search=${params.search}`
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT
        || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.CUSTOMER) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      const res = await axios.get(url )
      if (res && res.data) {
        console.log(res.data)
        const list = res.data.list.map(i => {
          return {
            ...i,
            value: i.id,
            label: `KHTP${i.id} (${i.billupCount || 0})`
          }
        })
        this.setState({listCustomer: list || []})
      }
    } catch (e) {
      console.error(e)
    }
  }
  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllMawb({page: pageNumber})
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }));
  }

  onClickPrintMawb(obj) {
    this.setState({ printData: obj });
    this.printPackagesToggle();
  }
  handleDatetoSelect (date) {
    console.log(date)
    this.setState({date})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN  || role === USER_ROLE.DOCUMENT || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.DOCUMENT || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  formatDate = (str) => {
    return moment(str).format('DD/MM/YYYY HH:mm')
  }
  handleInput = (e) => {
    const { value } = e.target
    this.setState({ search: value })
  }
  onSearch = (e) => {
    this.getAllMawb({search: this.state.search})
  }
  searchStatus(val) {
    console.log(val)
    this.setState({qStatus: val})
  }
  searchBy(val) {
    this.setState({searchBy: val})
  }
  searchBylocation(val) {
    this.setState({location: val})
  }
  render() {
    const { data, editMawb, createMawb, locationOption } = this.state
    const paymentTypeOption = {
      CASH: "Tiền mặt",
      BANK: "Chuyển khoản",
      ALL: "Tiền mặt & Chuyển khoản",
    }
    const AGENCIES = ['CRRIC', 'FIHONG', 'VNLINK']
    const statusTypeOption = {
      PROGRESS: "Chưa nhận",
      PROCESSING: "Đang nhận",
      SUCCESS: "Đã nhận",
      //DONE: 'Hoàn thành chứng từ'
    }

    const printMawb = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 5mm;
        }  }
        `;
    };

    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active>Quản lý hàng xuất</BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col sm={12} className='ml-auto'>
            <>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.onSearch()
                  }}
                >
                  <div className="d-flex align-items-end flex-wrap" style={{gap: "10px"}}>
                    {/*<select className="custom-select" style={{width: '200px', marginRight: '10px'}}*/}
                    {/*        onChange={(e) => this.searchStatus(e.target.value)}*/}
                    {/*>*/}
                    {/*  <option value="">Tất cả trạng thái</option>*/}
                    {/*  <option value="Đã nhận">Đã nhận</option>*/}
                    {/*  <option value="Đang nhận">Đang nhận</option>*/}
                    {/*  <option value="Chưa nhận">Chưa nhận</option>*/}
                    {/*</select>*/}
                    <div>
                      <Label>Search By: </Label>
                      <select className="custom-select ml-2" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchBy(e.target.value)}
                      >
                        <option value="mawbCode">MawbCode</option>
                        <option value="owner">Người tạo</option>
                      </select>
                    </div>
                    <div>
                      <Label style={{marginRight: '8px'}}>Khu Vực: </Label>
                      <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchBylocation(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="HCM">HCM</option>
                        <option value="HN">HN</option>
                        <option value="ĐN">ĐN</option>
                      </select>
                    </div>
                    <InputGroup>
                      <Input
                        id="kgInput"
                        style={{minWidth: '150px'}}
                        name="code"
                        value={this.state.search}
                        placeholder='Tìm kiếm...'
                        onChange={this.handleInput}
                      />
                      <Button type="submit" className="btn-search ml-2">Search</Button>
                    </InputGroup>
                  </div>
                </form>
              </CardBody>
            </>
          </Col>
        </Row>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Danh sách Mawb Hàng Xuất</span>
            {this.checkPermission() && (
              <Button type="button" className="butt ml-auto" onClick={this.toggle}>+ Tạo Mawb</Button>
            )}
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Mawb Code</th>
                <th>Nước đến</th>
                <th>Sân bay đến</th>
                <th>Agency</th>
                <th>Chi nhánh</th>
                <th>Người tạo</th>
                <th>Ngày tạo</th>
                <th>Note</th>
                {this.checkPermission() && (
                  <th>Action</th>
                )}
              </tr>
              </thead>
              <tbody>
              {data.list &&
                data.list.map((obj, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td>
                      <Link to={`/app/mawb-export/${obj.mawbCode}`} >{obj.mawbCode}</Link>
                    </td>
                    <td>{obj.country}</td>
                    <td>{obj.city}</td>
                    <td>
                      {obj.agency}
                    </td>
                    <td className='text-center'>
                      {obj.branch}
                    </td>
                    <td>{obj.owner}</td>
                    <td>{this.formatDate(obj.createdAt)}</td>
                    <td>{obj.note}</td>
                    {this.checkPermission() && (
                      <td>
                        <Button style={{marginRight: '5px', marginBottom: '5px'}} type="button" color="info" onClick={(e) => this.openEditModal(obj)}>Edit</Button>

                        {
                          this.checkPermission2() && (
                            <Button style={{marginBottom: '5px'}} type="button" color="danger" onClick={(e) => this.onDeleteMawb(obj.id)}>Delete</Button>

                          )
                        }
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>

          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={data.page}
            itemsCountPerPage={data.size}
            totalItemsCount={data.total}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            hideFirstLastPages={true}
          />

        </Card>
        <Row>
          {/* Toggle Form Create Mawb */}

          <Modal isOpen={this.state.modalToggle} toggle={this.toggle} >
            <Form>
              <ModalHeader toggle={this.toggle}>Tạo Mawb</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Mawb Code (*)</Label>
                  <Input type="text" name="mawbCode" value={createMawb.mawbCode} id="mawbCode" placeholder="mawbCode" onChange={this.onCreateMawbChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Nước đến</Label>
                  <Input name="country" id="country" value={createMawb.country}  placeholder="Country" onChange={this.onCreateMawbChange}/>

                  {/*<Input id="country" name="country" type="select" onChange={this.onCreateMawbChange} >*/}
                  {/*  {COUNTRIES.map((country) => (*/}
                  {/*    <option defaultValue={country.isoCode === "VN"}>*/}
                  {/*      {country.name + " - " + country.isoCode}*/}
                  {/*    </option>*/}
                  {/*  ))}*/}
                  {/*</Input>*/}
                </FormGroup>
                <FormGroup>
                  <Label for="content">Sân bay đến</Label>
                  <Input type="text" name="city" value={createMawb.city} id="city" placeholder="City" onChange={this.onCreateMawbChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="content">Agency</Label>
                  <Input id="agency" name="agency" type="select" onChange={this.onCreateMawbChange} >
                    {AGENCIES.map((agency) => (
                      <option defaultValue="CRRIC">
                        {agency}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="location">Khu vực</Label>
                  <Input id="branch" name="branch" type="select" onChange={this.onCreateMawbChange} >
                    {Object.values(locationOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                {/*<FormGroup>*/}
                {/*  <Label for="location">Template Download</Label>*/}
                {/*  <Input id="branch" name="branch" type="select" onChange={this.onCreateMawbChange} >*/}
                {/*    {Object.values(this.state.templatePrint).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}*/}
                {/*  </Input>*/}
                {/*</FormGroup>*/}
                <FormGroup>
                  <Label for="note">Ghi chú</Label>
                  <textarea
                    className="form-control"
                    rows="4"
                    id="note"
                    placeholder="Ghi chú"
                    onChange={this.onCreateMawbChange}
                    name="note"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary" onClick={(e) => this.onCreateMawb(e)} >Tạo mới</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Hủy</Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/* Toggle Form Edit Mawb */}
          <Modal isOpen={this.state.modalEditToggle} toggle={this.openEditModal} >
            <Form>
              <ModalHeader toggle={this.openEditModal}>Edit Mawb</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Mawb Code (*)</Label>
                  <Input type="text" name="mawbCode" value={editMawb.mawbCode} id="mawbCode" placeholder="mawbCode" onChange={this.onEditMawbChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Nước đến</Label>
                  <Input name="country" id="country" value={editMawb.country}  placeholder="Country" onChange={this.onEditMawbChange}/>
                  {/*<Input id="country" name="country" value={editMawb.country} type="select" onChange={this.onEditMawbChange} >*/}
                  {/*  {COUNTRIES.map((country) => (*/}
                  {/*    <option defaultValue={country.isoCode === "VN"}>*/}
                  {/*      {country.name + " - " + country.isoCode}*/}
                  {/*    </option>*/}
                  {/*  ))}*/}
                  {/*</Input>*/}
                </FormGroup>
                <FormGroup>
                  <Label for="content">Sân bay đến</Label>
                  <Input type="text" name="city" value={editMawb.city} id="city" placeholder="city" onChange={this.onEditMawbChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="content">Agency</Label>
                  <Input id="agency" name="agency" type="select" value={editMawb.agency} onChange={this.onEditMawbChange} >
                    {AGENCIES.map((agency) => (
                      <option defaultValue="CRRIC">
                        {agency}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="location">Khu vực</Label>
                  <Input id="branch" name="branch" type="select" value={editMawb.branch} onChange={this.onEditMawbChange} >
                    {Object.values(locationOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                {/*<FormGroup>*/}
                {/*  <Label for="location">Template Download</Label>*/}
                {/*  <Input id="branch" name="branch" type="select" value={editMawb.templatePrint} onChange={this.onEditMawbChange} >*/}
                {/*    {Object.values(this.state.templatePrint).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}*/}
                {/*  </Input>*/}
                {/*</FormGroup>*/}
                <FormGroup>
                  <Label for="note">Ghi chú</Label>
                  <textarea
                    className="form-control"
                    rows="4"
                    id="note"
                    value={editMawb.note}
                    placeholder="Ghi chú"
                    onChange={this.onEditMawbChange}
                    name="note"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditMawb(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal
            isOpen={this.state.modalPrintPackagesToggle}
            scrollable
            centered
            size='lg'
            fullscreen="true |xxl-down'"
            toggle={this.printPackagesToggle}
          >
            <ModalHeader toggle={this.printPackagesToggle}>
              Print Mawb
            </ModalHeader>
            <ModalBody>
              <style> {printMawb()} </style>
              <div>
                <PrintMawb
                  billData={this.state.printData}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button href='#'> Print this out!</Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

        </Row>
      </div>
    )
  }
}
export default Mawb
