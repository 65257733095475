import React from 'react';
import { fetchUserByID } from '../../actions/user';
import { DOC_TYPE } from '../../constants';
import EscapeSpecialChars from '../../utils/escapeJSON'
import Rounding from '../../utils/rounding';

class InvoiceToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invCreator: {}
    }
  }

  async componentDidMount() {
    this.setState({ invCreator: await fetchUserByID(this.props.invData.companyUserID) })
  }

  render() {
    const escaped = EscapeSpecialChars(this.props.invData.invoices)
    const invoices = JSON.parse(escaped)
    const packs = JSON.parse(this.props.invData.packs)

    return (
      // <table height="99%" width="99%" bordered="true" style={{ "fontSize": "100%" }}>
      <div className="container">
      <div className="container border border-dark" >
        <div className="col-sm-12 text-center ">
          <h1>  <strong> INVOICE </strong> </h1>
        </div>

        <br /><br />
        <div className="row" width="100%">
          <div className="col-2"> </div>
          <div className="col-6 text-right" > </div>
          <div className="col-2 text-left"> <span> <strong> <i> Date:</i></strong> </span> <br></br>  </div>
          <div className="col-2">{new Date(this.props.invData.createdAt).toLocaleDateString()} </div>
        </div>
        <div className="row" width="100%" height="30px">  &nbsp; </div>

        <div className="row" width="100%">
          <div className="col-2">  <span> <strong> <u> SHIPPER </u> </strong> </span> <br></br> </div>
          <div className="col-6 text-left" > </div>
          <div className="col-2 text-left"> <span> <strong> Air waybill No. </strong> </span> <br></br> </div>
          <div className="col-2">  <span className="border-dark"> {this.props.invData.kgCode} &nbsp; </span> <br></br> </div>

        </div>
        <div className="row" width="100%">
          <div className="col-2">
            <span> Company Name:    </span> <br />
            <span> Address:    </span> <br />
            <span> City:  </span> <br />
            <span> Country: </span> <br />
            <span> Email:  </span> <br />
            <span> Contact Name: </span> <br />
            <span> Phone/Fax No:  </span>
          </div>

          <div className="col-6 text-left text-uppercase" >
            <span className="border-dark font-weight-bold  "> &nbsp; {this.props.invData.senderCompanyName}&nbsp;  &nbsp; </span> <br />
            <span className="border-dark font-weight-bold"> &nbsp; {this.state.invCreator?.address} &nbsp; &nbsp;  </span> <br />
            <span className="border-dark font-weight-bold"> &nbsp; {this.state.invCreator?.city}  &nbsp; &nbsp; </span> <br />
            <span className="border-dark font-weight-bold " > &nbsp;  {this.state.invCreator?.country} &nbsp; &nbsp; </span> <br />
            <span className="border-dark font-weight-bold " > &nbsp;  {this.state.invCreator?.email} &nbsp; &nbsp; </span> <br />
            <span className="border-dark font-weight-bold"> &nbsp;  {this.props.invData.senderName} / {this.props.invData.senderPhone}&nbsp; &nbsp; </span> <br />
            <span className="border-dark font-weight-bold"> &nbsp;  {this.props.invData.senderPhone} &nbsp; &nbsp; </span> <br />
          </div>

          <div className="col-2" >
            <br /> <br />
            <span> <strong> No. of pkgs: </strong></span> <br />
            <br />
            <span> <strong> Net Weight: </strong>  </span> <br />
          </div>
          <div className="col-2">
            <br /> <br />
            <span className="border-dark"> &nbsp; &nbsp; {(this.props.invData.packageType === DOC_TYPE) ? this.props.invData.docCount : packs.reduce(function (a, b) { return a + parseFloat(b["quantity"]) }, 0) || 0} &nbsp; &nbsp; </span> <br />
            <br />
            <span className="border-dark"> &nbsp; &nbsp; {(this.props.invData.packageType === DOC_TYPE) ? this.props.invData.docWeight : Rounding(packs.reduce(function (a, b) { return a + parseFloat(b["chargedWeight"]) }, 0)) || 0} KG &nbsp; &nbsp; </span> <br />
          </div>
        </div>
        <br />

        <div className="row" width="100%">
          <div className="col-3">  <span> <strong>  <u> CONSIGNEE </u>   </strong> </span> <br /> </div>
        </div>

        <div className="row" width="100%">
          <div className="col-2"  >
            <span> Company Name:    </span> <br />
            <span> Address:    </span> <br />
            {(this.props.invData.receiverAddr1 && this.props.invData.receiverAddr2) && (<><span>    </span> <br /></>)}
            <span>  Postal code:  </span> <br />
            <span>  City/Town:  </span> <br />
            <span> Country:  </span> <br />
            <span>  Contact Name: </span> <br />
            <span> Phone/Fax No.: </span> <br />

          </div>
          <div className="col-5 text-left text-uppercase" >
            <span className="border-dark font-weight-bold "> &nbsp; {this.props.invData.receiverCompanyName}  &nbsp; &nbsp; </span> <br />
            <span className="border-dark font-weight-bold"> &nbsp; {this.props.invData.receiverAddr1 || this.props.invData.receiverAddr2}  &nbsp; &nbsp; </span> <br />
            {(this.props.invData.receiverAddr1 && this.props.invData.receiverAddr2) && (<><span className="border-dark font-weight-bold"> &nbsp; {this.props.invData.receiverAddr2}  &nbsp; &nbsp; </span> <br /></>)}
            <span className="border-dark font-weight-bold"> &nbsp; {this.props.invData.receiverPostalCode} &nbsp;  &nbsp; </span> <br />
            <span className="font-weight-bold"> &nbsp;{this.props.invData.receiverCity} - {this.props.invData.receiverProvince} &nbsp;  &nbsp;</span> <br />
            <span className="border-dark font-weight-bold"> &nbsp; {this.props.invData.receiverCountry} &nbsp; &nbsp; </span> <br />
            <span className="border-dark font-weight-bold"> &nbsp;  {this.props.invData.receiverName} &nbsp; &nbsp; </span> <br />
            <span className="border-dark font-weight-bold"> &nbsp; {this.props.invData.receiverPhone} &nbsp; &nbsp; </span> <br />
          </div>
        </div>
        <div className="row" width="100%">
          <div className="col-3">  <span> <strong>  <u> </u>   </strong> </span> <br /> </div>
        </div>

        <div className="row" width="100%">
          <table bordered="true" className="border-dark text-center" width="100%" >
            <thead className="font-weight-bold"  >
              <tr>
                <th className="font-weight-bold" width="50%">Full Description of  Goods <br /> (Name of goods, composition of material, marks, etc.) </th>
                <th className="font-weight-bold" >Quantity </th>
                <th className="font-weight-bold" >Unit </th>
                <th className="font-weight-bold" > Unit Price <br />(In USD)</th>
                <th className="font-weight-bold" > Subtotal <br />(In USD) </th>
              </tr>
            </thead>

            <tbody >
              {
                invoices &&
                invoices.map((inv) => (
                  <tr height="150px">
                    <td> <TextWithNewLine text={inv.description} /> </td>
                    <td> {inv.quantity} </td>
                    <td> {inv.unit} </td>
                    <td> {inv.unitPrice} </td>
                    <td> {inv.subTotal} </td>
                  </tr>
                ))
              }

              <tr height="40px">
                <td colSpan="4">Total Value (in USD) </td>
                <td className="font-weight-bold">   &nbsp; {invoices.reduce(function (a, b) { return a + parseFloat(b["subTotal"]) }, 0) || 0} &nbsp;&nbsp;</td>
              </tr>

            </tbody>
          </table>

          <table bordered="true" className="border-dark text-center" width="100%" >
            <thead className="font-weight-bold"  >
              <tr>
                <th className="font-weight-bold" width="30%"></th>
                <th className="font-weight-bold" >Q'ty </th>
                <th className="font-weight-bold" >G.W </th>
                <th className="font-weight-bold" >L</th>
                <th className="font-weight-bold" >W</th>
                <th className="font-weight-bold" >H</th>
                <th className="font-weight-bold" >D.W</th>
              </tr>
            </thead>
            <tbody>
              {
                packs &&
                packs.map((pkg, i) => (
                  <tr className="font-weight-bold text-center" height="40px">
                    <td> Dimensions {i + 1} (cm) </td>
                    <td> {pkg.quantity} </td>
                    <td> {pkg.weight} </td>
                    <td> {pkg.length} </td>
                    <td> {pkg.width} </td>
                    <td> {pkg.height} </td>
                    <td> {pkg.convertRatio} </td>
                  </tr>
                ))
              }

              <tr className="text-left" height="40px">
                <td colSpan="100%">
                  Reason for Export:  &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; <span className=" font-weight-bold text-uppercase"> {this.props.invData.invoiceExportFormat}</span>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <br />  <br />

        <div className="row" width="100%" >

          <div className="col-12" >  <p> I declare that the information is true and correct to the best of my knowledge, certify that the particulars and </p>
            <p>I (name) <span className="text-uppercase"> <u>  &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; {this.props.invData.senderName} &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; &nbsp;&nbsp;</u>  </span> certify that the particulars and quantity of goods specified in this document are goods which are submitted for clearance for export out of Vietnam.  </p>

            <p height="100px" className="text-right"> Signature/Title/Stamp &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp;</p>
            <br /> <br /> <br /><br />
          </div>
        </div>
      </div>
              </div>
    );
  }
}

function TextWithNewLine(props) {
  const text = props.text;
  const newText = text.split('\n').map(str => <p>{str}</p>);

  return newText;
}

export default InvoiceToPrint;
